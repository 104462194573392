import React, { useState } from "react";
import { Button, Form } from 'react-bootstrap';
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { resetPass } from "../../reducers/userReducer";
import logo from "../../assets/images/boxilogof.png";
import { queryString } from "../../utils/useFunc";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { updatePassWordValidation } from "../../utils/validation";
import { useTranslation } from "react-i18next";
// import { parseClassName } from "react-toastify/dist/utils";


const ResetPassword = () => {
const { t } = useTranslation();  
const history=useHistory()
const [error,setError]=useState({})
    const {forgethash:token} = queryString();

    const dispatch = useDispatch();
    
    const [pass,setPass] = useState({
        password:'',
        confirmPassword:'',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        // console.log('name---',name,value)
        setPass({...pass,[name]:value});
    } 

    const handleBlurChange =(e) =>{
    const {name,value} = e.target;
    let valueName = name;
    const validData =  updatePassWordValidation(valueName,value,t);
    const validateError =  validData?.errors
    // console.log('validateError===>>>>',validateError)
    const copy  = {...error};
    if(!validData?.isValid){
    copy[name] = validateError[name];
    }else{
      delete copy[name];
    }
    setError(copy) 
    }

    const handleResetPass =async (e) => {
        const passRegex =/\d/;
        e.preventDefault();
       if(pass?.password==='' && pass?.confirmPassword === ''){
           return setError({password:"This field is required",confirmPassword:'This field is required'})
            
        }else if(pass?.password===''){
           return setError({password:'This field is required'})
        }
        
        else if(pass?.password.length < 6 || !passRegex.test(pass?.password)){
            return setError({password:'Must be 6 or more characters and contain atleast 1 number.'})
        }else if(pass?.confirmPassword === ''){
           return setError({confirmPassword:"This field is required"})
        }
        else if(pass.password===pass?.confirmPassword ){
        const payload = {
            password:pass?.password,
            token
        }
       const  res = await dispatch(resetPass(payload))
        const{code}=res?.payload?.resultData?.data
         if(code===200){
           
            history.push("/?modal=Login")
         }
         setError({confirmPassword:""}) 
        }
        else if(pass.password!==pass?.confirmPassword){
       return  setError({confirmPassword:"Password do not match"})
        }
  
    
    }
    // console.log("password==>", pass?.password)
    return (
        <>
            <div className="reset-password-section bg-new-all">
                <div className="container">
                    <div className="reset-password-form">
                        <div className="reset-password-box">
                            <div className="boxi-logo">
                                <Link className="site-logo site-title" to="/">
                                    <img src={logo} alt="site-logo" />
                                </Link>
                            </div>
                            <h1>Reset Your Password</h1>
                            <Form>
                                <Form.Group controlId="formBasicloginone">
                                    <Form.Label>Enter New Password</Form.Label>
                                    <Form.Control type="password" placeholder="Enter New Password" name="password" value={pass?.password}
                                        onChange={ handleChange}
                                        onBlur ={handleBlurChange}
                                    />
                                    {error?.password &&( <p className="text-danger">{error?.password}</p> )}
                                </Form.Group>
                                <Form.Group controlId="formBasicloginone">
                                    <Form.Label>Confirm Password</Form.Label>
                                    <Form.Control type="password" placeholder="Confirm Password" name="confirmPassword" value={pass?.confirmPassword}
                                        onChange={handleChange}
                                        onBlur ={handleBlurChange}
                                         />
                                       
                                         {(error?.confirmPassword &&( <p className="text-danger">{error?.confirmPassword}</p>))}
                                </Form.Group>
                                <div className="login-button">
                                    <Button type="submit" className="l-btn btn-style" onClick={handleResetPass}>
                                        <span>   Change Password</span>
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ResetPassword;