import { io } from "socket.io-client";
import { serverPath } from './keys';
const socket = io.connect(serverPath, {
  transports: ["websocket", "polling"],
  rejectUnauthorized: false,
  reconnection: false,
});
socket.on('connect', () => {
  const userid = localStorage.getItem('inboxi#@user');
  if (userid) {
    socket.emit('join', userid);
  }
});
socket.on("disconnect", () => {
  // console.log("Disconnected");
});

const GetNotification = (cb) => {
  socket.on("GetNotification", (data) => {
    // console.log('data==>', data)
    cb(data);
  });
};

socket.on("connect_error", (err) => {
  // console.log(`connect_error due to ${err.message}`);
});
const tryReconnect = () => {
  setTimeout(() => {
    // console.log("reconnect here..");
    socket.io.open((err) => {
      if (err || socket.disconnected) {
        // console.log("reconnect err => ", err);
        tryReconnect();
      }
    });
  }, 2000);
};
socket.io.on("close", tryReconnect);

export { socket, GetNotification };
