/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, Suspense } from "react";
import Layout from "../layout/layout";
import { useTranslation } from "react-i18next";
// import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import newbox from "../../assets/images/newhome/BannerImg.png";
import bgwelcome from "../../assets/images/newhome/wallpaper-banner.png";
// import bgwelcome from "../../assets/images/newhome/bg-welcome.png";
// import users from "../../assets/images/newhome/users.png";
// import totalbox from "../../assets/images/newhome/total_box.png";
// import totalunbox from "../../assets/images/newhome/total_unbox.png";
// import gift from "../../assets/images/newhome/gift.png";
// import party from "../../assets/images/newhome/party.gif";
// import partyProper from "../../assets/images/newhome/party-popper.gif";
// import { socket } from "../../config/socket";
import "./new_home.css";
// import { getLiveDrops } from "../../reducers/userReducer";
import Loader from "../Loader/loader";
import OurRecomendedBox from "./OurRecomendedBox";
import RecentBoxSlider from "./recentBoxSlider";
// import RecentBoxSlider from "./recentBoxSlider";
const Home = () => {
  // const dispatch = useDispatch();
  // const [checkNewOne, setNewOne] = useState(false);
  const [isloading, setLoading] = useState(true);
  // const result = useSelector((state) => state.user);
  const { t } = useTranslation();
  // const { liveDrops, alluserCount, boxCount, unboxCount } = result || {};

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  // useEffect(() => {
  //   dispatch(getLiveDrops());
  // }, [dispatch]);

  // useEffect(() => {
  //   socket.on("getLiveDrops", (data) => {
  //     setNewOne(true);
  //     dispatch(getLiveDrops());
  //     setTimeout(() => {
  //       setNewOne(false);
  //     }, 800);
  //   });
  // }, [dispatch]);

  // const RecentBoxSlider = React.lazy(() => import("./recentBoxSlider"));
  const HomeBoxes = React.lazy(() => import("./homeBoxes"));
  // const Features = React.lazy(() => import("./features"));
  // const HowToPlay = React.lazy(() => import("./howToPlay"));

  // const Support = React.lazy(() => import("./support"));

  return (
    <>
      <Helmet>
        <title>
          Cilycase | Exciting Case Openings | Rare Items & Epic Loots
        </title>
        <meta
          property="og:title"
          content="Cilycase | Exciting Case Openings | Rare Items & Epic Loots"
        />
      </Helmet>
      {isloading ? (
        <Loader />
      ) : (
        <Layout>
          <div className="new-home-page update-home-page ">
            <div className="home-live-drops ">
              <div className="container px-3 mt-3">
                <Suspense fallback={<div>Loading...</div>}>
                  {/* <RecentBoxSlider
                    liveDropState={liveDrops}
                    checkNewOne={checkNewOne}
                    t={t}
                  /> */}
                  <RecentBoxSlider />
                </Suspense>
              </div>
            </div>
            <HeroBanner t={t} />
            {/* <CounterSection
              t={t}
              alluserCount={alluserCount}
              boxCount={boxCount}
              unboxCount={unboxCount}
            /> */}
            {/* <div className="home-live-drops">
              <div className="container">
                <Suspense fallback={<div>Loading...</div>}>
                  <RecentBoxSlider
                    liveDropState={liveDrops}
                    checkNewOne={checkNewOne}
                    t={t}
                  />
                </Suspense>
              </div>
            </div> */}
            {/* <Suspense fallback={<div>Loading...</div>}>
              <HowToPlay t={t} />
            </Suspense> */}
            <Suspense fallback={<div>Loading...</div>}>
              <HomeBoxes t={t} />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <OurRecomendedBox t={t} />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              {/* <Features t={t} /> */}
              {/* <OurRecomendedBox t={t} /> */}
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              {/* <Support t={t} /> */}
              {/* <OurRecomendedBox t={t} /> */}
            </Suspense>
          </div>
        </Layout>
      )}
    </>
  );
};

export default Home;

const HeroBanner = ({ t }) => {
  return (
    <section class="hero style--two bg_img">

      <div class="bgwelcome" data-wow-duration="0.5s" data-wow-delay="0.5s">
        <img src={bgwelcome} alt="bg" />
      </div>

      <div class="container">
        <div className=" home-page-light">
          <div class="row justify-content-center justify-content-lg-start">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="hero__content">
                <h2
                  class="hero__title wow fadeInUp"
                  data-wow-duration="0.5s"
                  data-wow-delay="0.7s"
                >
                  Welcome to 
                  {/* <img src={party} alt="icon" />{" "} */}
                  <span className="highlightedText">
                    Cilycase 
                    {/* <img src={gift} alt="icon" /> */}
                  </span>
                </h2>
                <p>simply dummy text of the printing and typesetting industry.
Lorem Ipsum has been the industry's standard dummy text
ever since the 1500s when an unknown printer</p>
                {/* <p class='wow fadeInUp' data-wow-duration='0.5s' data-wow-delay='0.5s'>
                  {t("home.homeTitle1")}
                </p> */}
                <div
                  class="hero__btn wow fadeInUp"
                  data-wow-duration="0.5s"
                  data-wow-delay="0.3s"
                >
                  <a href="/boxes" class="cmn-btn btn-style">
                    <span>
                     OPEN BOx
                      {/* <img src={partyProper} alt="icon" /> */}
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 position-relative ">
              <div
                class="hero-box-main new-main-box wow bounceIn"
                data-wow-duration="0.5s"
                data-wow-delay="0.5s"
              >
                <img src={newbox} alt="bg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

// const CounterSection = ({ t, alluserCount, boxCount, unboxCount }) => {
//   const [userCount, setUserCount] = useState(0);
//   const [boxcount, setBoxcount] = useState(0);
//   const [unboxcount, setunboxcount] = useState(0);
//   let num = 0;
//   let num2 = 0;
//   let num3 = 0;
//   useEffect(() => {
//     const timerVariable = setInterval(countUpTimer, 1);
//     const timerVariable2 = setInterval(countUpTimer2, 1);
//     const timerVariable3 = setInterval(countUpTimer3, 1);
//     function countUpTimer() {
//       if (num < alluserCount * 100) {
//         ++num;
//         setUserCount(num);
//       } else {
//         clearInterval(timerVariable);
//       }
//     }
//     function countUpTimer2() {
//       if (num2 < boxCount) {
//         ++num2;
//         setBoxcount(num2);
//       } else {
//         clearInterval(timerVariable2);
//       }
//     }
//     function countUpTimer3() {
//       if (num3 < unboxCount * 100) {
//         ++num3;
//         setunboxcount(num3);
//       } else {
//         clearInterval(timerVariable3);
//       }
//     }
//   }, [alluserCount, boxCount, num, num2, num3, unboxCount]);
//   return (
//     <div class="counter-section update-counter-section">
//       <div class="container">
//         <div class="counter-grid">
//           <div class="counter-box">
//             <div class="counter-item">
//               <div class="counter-item__icon">
//                 <img src={users} alt="counter" />
//               </div>
//               <div class="counter-item__content">
//                 <span>{alluserCount}</span>
//                 <p>{t("home.totalUsers")}</p>
//               </div>
//             </div>
//           </div>
//           <div class="counter-box">
//             <div class="counter-item">
//               <div class="counter-item__icon">
//                 <img src={totalbox} alt="counter" />
//               </div>
//               <div class="counter-item__content">
//                 <span>{boxCount || 0}</span>
//                 <p>{t("home.totalBoxes")}</p>
//               </div>
//             </div>
//           </div>
//           <div class="counter-box">
//             <div class="counter-item">
//               <div class="counter-item__icon">
//                 <img src={totalunbox} alt="counter" />
//               </div>
//               <div class="counter-item__content">
//                 <span>{unboxCount || 0}</span>
//                 <p>{t("home.totalUnboxed")}</p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
