import React, { useState, useEffect } from "react";
// import Header from "../header/header";
// import Footer from "../footer/footer";
import { Helmet } from "react-helmet";
import "../../../src/assets/css/arabic.css";
import { useSelector } from "react-redux";
import NewHeader from "../header/newHeader";
import NewFooter from "../footer/NewFooter";


const Layout = ({ children }) => {
  const result = useSelector((state) => state.user);
  const { lan } = result || {};
  const [isRTL, setISRTL] = useState(lan);
  const [showScrollBtn, setShowScrollBtn] = useState(false);

  useEffect(() => {
    setISRTL(lan);
  }, [lan]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowScrollBtn(true);
      } else {
        setShowScrollBtn(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavoiur: "smooth" });
  };

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          rel="icon"
          type="image/x-icon"
          href={"/assets/images/favicon/FAVICON.png"}
        />

        <html lang="en" />
      </Helmet>
      <div className={isRTL === "ar" ? " wrapper rtl" : "wrapper bg-new-all new-bg-all"}>
        {/* <Header /> */}
        <NewHeader />
        <div className="content">{children}</div>
    
        {/* <Footer /> */}
        <NewFooter />
      </div>
      {showScrollBtn && (
        <div className="scroll-btn" onClick={scrollToTop} role="presentation">
          <i class="las la-arrow-up"></i>
        </div>
      )}
    </>
  );
};

export default Layout;
