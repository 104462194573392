import moment from "moment";
export const calcTime = (offset, dd)=> {
    // create Date object for current location
    const d = new Date(dd);
    // convert to msec
    // subtract local time zone offset
    // get UTC time in msec
    const utc = d.getTime() + (d.getTimezoneOffset() * 60000);

    // create new Date object for different city
    // using supplied offset
    const nd = new Date(utc + (3600000*offset));
    const dates = moment(nd).format('DD/MM/YYYY HH:mm')
    //console.log('ndnd==>', nd)

    // return time as a string
   // return  nd.toLocaleString();
   return  dates;
}