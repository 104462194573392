import React, { memo, useEffect, useRef, useState } from "react";
import "./style.css";
import EmojiPopup from "./EmojiPopup";
import { RxCross2 } from "react-icons/rx";
import { IoChatboxEllipses } from "react-icons/io5";
import { socket } from "../../config/socket";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { userInstance } from "../../config/axios";
import userImage from "../../assets/images/profile.png";

const ChatComponent = memo(({ toggleForm }) => {
  const result = useSelector((state) => state.user);
  const messagesEndRef = useRef(null);
  const wrapperRef = useRef(null);
  const { userDetails } = result || {};
  const { _id: userId, username, profile } = userDetails || {};
  const [emojiShow, setEmojiShow] = useState(false);
  const [userMessage, setUserMessage] = useState("");
  const [chatdata, setChatdata] = useState([]);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  console.log("jkbfwefhwfvhvwfwhefvwhfvwhefvwhje+++++++++++++++++++++")

  const handleChangeMessage = (e) => {
    const { value } = e.target;
    setUserMessage(value);
  };

  const handleChatSubmitByUser = () => {
    if (userMessage.trim().length > 0) {
      if (!localStorage.getItem("inboxi#@user")) {
        toast.error("You are not logged in . Please login to send message.", {
          toastId: "Chatmsg",
        });
      } else {
        socket.emit("chatMessageByUser", {
          chatMessage: userMessage,
          userId: userId,
          username: username,
          profile: profile || "",
        });
        setUserMessage("");
        scrollToBottom();
        setEmojiShow(false);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleChatSubmitByUser();
      scrollToBottom();
    }
  };

  const handleShowEmoji = () => {
    setEmojiShow(!emojiShow);
  };

  const handleFetchChatData = async () => {
    try {
      const globalChatData = await userInstance().get(
        "/api/user/getChatRoomData"
      );
      const { chatData, code } = globalChatData?.data;
      if (code === 200) {
        setChatdata(chatData);
      }
    } catch (error) {
      console.log(" Getting Error in the chat data fetching", error);
    }
  };

  useEffect(() => {
    handleFetchChatData();
  }, []);

  useEffect(() => {
    socket.on("newChatMessage", (data) => {
      setChatdata((prev) => [...prev, data]);
      scrollToBottom();
    });
    return () => {
      socket.off("newChatMessage");
    };
  }, []);

  const checkMessageTime = (createdAt) => {
    const date = new Date(createdAt);
    const now = new Date();
    const today = new Date(now.setHours(0, 0, 0, 0));
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    if (date >= today) {
      return "Today";
    } else if (date >= yesterday && date < today) {
      return "Yesterday";
    } else {
      return date.toLocaleDateString();
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messagesEndRef, chatdata, userMessage]);

  const handleSelectEmojiComment = (emoji) => {
    setUserMessage((old) => old + emoji);
  };

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setEmojiShow(false);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  useOutsideAlerter(wrapperRef);
  return (
    <div className=" d-flex justify-content-center chat-wrapper">
      <div className="card border-0 card-style" id="chat2">
        <div className="card-header d-flex justify-content-between align-items-center p-2">
          <div className="chat-header-left">
            <IoChatboxEllipses size={18} /> <h5 className="mb-0"> Chat</h5>
          </div>

          <button className="chat-button-toggleafteropen" onClick={toggleForm}>
            <RxCross2 size={18} />
          </button>
        </div>
        <div
          className="card-body chat-container"
          style={{ position: "relative" }}
        >
          <div className="overflow-auto chats">
            {chatdata &&
              chatdata.length > 0 &&
              chatdata.map((data, index) => {
                const createdAt = new Date(data?.createdAt);
                const formattedTime = createdAt.toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                });
                let showDateLabel = false;
                if (
                  index === 0 ||
                  checkMessageTime(chatdata[index - 1]?.createdAt) !==
                    checkMessageTime(data?.createdAt)
                ) {
                  showDateLabel = true;
                }
                return (
                  <>
                    {showDateLabel && (
                      <div className="msg-divider d-flex align-items-center">
                        <p
                          className="text-center mx-3 mb-0"
                          style={{ color: "#a2aab7" }}
                        >
                          {checkMessageTime(createdAt)}
                        </p>
                      </div>
                    )}
                    <div
                      className="d-flex flex-row justify-content-end pt-1"
                      key={index}
                    >
                      <img
                        src={data?.userId?.profile || userImage}
                        alt="avatar 2"
                        style={{ width: "38px", height: "38px" }}
                      />
                      <div className="chat-message">
                        <p className="small p-2 ms-3 mb-1 text-white rounded-3 bg-body-me">
                          {data?.message}
                        </p>
                        <p className="msg-time">{formattedTime}</p>
                      </div>
                    </div>
                    <span ref={messagesEndRef} />
                  </>
                );
              })}
          </div>
        </div>
        <div className="card-footer text-muted d-flex justify-content-start px-2 py-1 bottom-0 chatboxarea">
          <input
            type="text"
            className="form-control form-control-lg w-100 text-white fs-6"
            placeholder="Type message..."
            value={userMessage}
            onChange={handleChangeMessage}
            onKeyDown={handleKeyDown}
          />
          <span className="ms-3 text-muted emoji-btn" onClick={handleShowEmoji}>
            <i className="fas fa-smile"></i>
          </span>
          <button
            className={`ms-3 paperplanebox ${
              userMessage?.length === 0 ? "opacity-50" : "opacity-100"
            }`}
            disabled={userMessage?.length === 0}
            onClick={handleChatSubmitByUser}
          >
            <i className="fas fa-paper-plane"></i>
          </button>
          <div ref={wrapperRef}>
            {emojiShow && (
              <EmojiPopup handleSelectEmoji={handleSelectEmojiComment} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export default ChatComponent;
