import React from "react";

const HowToPlayCard = ({ title, description, description1, description2 }) => {
  return (
    <div className="howtoplay-card">
      <div className="howtoplay-card-left">
        <div className="howtoplay-image"></div>
      </div>
      <div className="howtoplay-card-right">
        <div className="howtoplay-card-title">
          <h4>{title}</h4>
        </div>
        <div className="howtoplay-card-desc">
          <p>
            {description}
            <span>{description1}</span>
            {description2}
          </p>
        </div>
      </div>
    </div>
  );
};

export default HowToPlayCard;
