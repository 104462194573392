import React from "react";
import "./pagenotfound.css";
import { Link } from "react-router-dom";
import brandLogo from "../../../src/assets/images/cily-logo.png";
import Astronout from "../../../src/assets/images/astronaut.svg";
import Earth from "../../../src/assets/images/earth.svg";

const PageNotFound = () => {
  return (
    <>
      <div className="stars">
        <div className="custom-navbar">
          <div className="container">
            <Link to="/" className="brand-logo">
              <img src={brandLogo} alt="" width="80px" />
            </Link>
          </div>
        </div>
        <div className="central-body">
          <img
            className="image-404"
            src="http://salehriaz.com/404Page/img/404.svg"
            alt=""
            width="300px"
          />
          <Link to="/" className="btn-go-home" target="_blank">
            GO BACK HOME
          </Link>
        </div>
        <div className="objects">
          <img
            className="object_rocket"
            src="http://salehriaz.com/404Page/img/rocket.svg"
            alt=""
            width="40px"
          />
          <div className="earth-moon">
            <img className="object_earth" src={Earth} alt="" width="100px" />
            <img
              className="object_moon"
              src="http://salehriaz.com/404Page/img/moon.svg"
              alt=""
              width="80px"
            />
          </div>
          <div className="box_astronaut">
            <img
              className="object_astronaut"
              src={Astronout}
              alt=""
              width="140px"
            />
          </div>
        </div>
        <div className="glowing_stars">
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
          <div className="star"></div>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
