import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
// import BoxImg from "../../assets/images/home/download.png";
// import FairnessGuaranteedPopUp from "../PopUp/fairnessGuaranteedPopup";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrders,
  getOrderDetailsByOrderId,
} from "../../reducers/userReducer";
import { includeToFixed } from "../../utils/useFunc";
import TabbleLoader from "../Loader/tableloader";
// import Loader from "../Loader/loader";
import OrderDetails from "./OrderDetails";
import { calcTime } from "../../utils/getTimeZone";
import { t } from "i18next";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

const Orders = () => {
  // const [fairnessOpen, setFairnessOpen] = useState(false);
  const dispatch = useDispatch();
  const [skip, setSkip] = useState(0);
  const [hide, setHide] = useState(false);
  const result = useSelector((state) => state.user);
  const { allOrders, orderDetails, loading,ordersCount } = result || {};
  const [viewOrder, setViewOrder] = useState(false);
  
  // console.log("------------------> ",allOrders);
  // console.log('pastGames',pastGames);
  // const [selected,setSelected] = useState({})

  // const handleFairness = (e,data) => {
  //   e.preventDefault();
  //   setFairnessOpen(true);
  //   setSelected(data)
  // };
  const handleLoadMore = () => {
    setSkip(skip + 5);
  };

  useEffect(() => {
    // dispatch(getPastGames(skip));
    dispatch(getOrders(skip));
  }, [dispatch, skip]);

  useEffect(()=>{
    if((allOrders.length > 0 && ordersCount<=allOrders.length) || allOrders.length === 0){
      setHide(true)
    }
  },[ordersCount,allOrders?.length])

  const handleViewOrder = (iden) => {
    setViewOrder(!viewOrder);
    if (iden) {
      dispatch(getOrderDetailsByOrderId(iden));
    }
  };


  return (
    <>
      {/* {loading && <Loader />} */}
      <div>
        {/* {fairnessOpen ? (
          <FairnessGuaranteedPopUp
            fairnessOpen={fairnessOpen}
            setFairnessOpen={setFairnessOpen}
            selected={selected}

          />
        ) : (
          ""
        )} */}
        <div className="all-transaction inventory__page past_game">
          <div className="all-transaction__header">
            <h3 className="title">{t("profile.orderTxt")}</h3>
            {/* <div className="date-range global-date-picker">
              <input type="date" className="datepicker-here form-control" />
              <i className="las la-calendar-alt"></i>
              <DatePicker
                selected={dateValue}
                onChange={handleDateSearch}
                maxDate={new Date()}
                placeholderText="JJ/MM/YYYY"
                dateFormat="d/MM/yyyy"
                showYearDropdown={true}
                dropdownMode="select"
              />
            </div> */}
          </div>
          {loading ? (
            <TabbleLoader />
          ) : (
            <>
              <div className="table-responsive-xl">
                <Table>
                  <thead>
                    <tr>
                      <th>{t("profile.orderThTxt1")}</th>
                      <th>{t("profile.orderThTxt2")}</th>
                      <th>{t("proTransactions.thTxt5")}</th>
                      <th>{t("profile.orderThTxt3")}</th>
                      <th>{t("profile.orderThTxt4")}</th>

                      {/* <th>Ticket Won</th> */}
                      {/* <th>Date/Time</th> */}
                      {/* <th>Loss</th>
                  <th>Fairness</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {allOrders.map((el) => {
                      
                      // const date = new Date(el?.createdAt);
                      // const curdata = date.getDate();
                      // const monthName = date.toLocaleString("default", {
                      //   month: "short",
                      // });
                      //  const boxPrice =  el?.boxPrice ? el.boxPrice : 0;
                      //  const {price} = el?.result?.pid || {}
                      //  const loss = boxPrice && (parseFloat(boxPrice) > parseFloat(price||0)) ? `${(boxPrice - price)} $` : '-'
                      //  const profit = boxPrice &&  (parseFloat(boxPrice) < parseFloat(price||0)) ? `${(price - boxPrice)} $` : '-'
                      return (
                        <tr>
                          <td>
                            <div className="date">
                              <span>{calcTime("+1", el?.createdAt)}</span>
                              {/* <span>{curdata}</span>
                              <span>{monthName}</span> */}
                            </div>
                          </td>
                          {/* <td>
                    <div className="box__img">
                      <img src={el?.productId?.bannerImage} alt="inventory-img" />
                    </div>
                  </td>
                  <td>
                    <p>{el?.productId?.name}</p>
                  </td> */}

                          <td>
                            <p>{el?.orderid}</p>
                          </td>

                          {/* <td>
                      <p>{el?.orderid}</p>
                    </td> */}

                          <td>
                            {/* {el?.status === "accepted" ? (
                              <p>{t(`orderStatus.${el?.status}`)}</p>
                            ) : (
                              <p>{t(`orderStatus.${el?.status}`)}</p>
                            )} */}
                            
                             <p>{t(`orderStatus.${el?.status}`)}</p>
                          </td>
                          <td>
                            {/* <div className="total_price profit"> 0</div> */}

                            <div className="total_price profit">
                              {includeToFixed(el?.totalAmount)}
                            </div>
                            {/* <div className="total_price profit"> {includeToFixed(el?.totalAmount)}</div> */}
                          </td>

                          <td>
                            <button
                              className="cmn-btn btn-style btn-style-transparent"
                              onClick={() => handleViewOrder(el._id)}
                            >
                              <span>{t("profile.viewOrdTxt")}</span>
                            </button>
                          </td>

                          {/* <td>
                 { el?.orderId?.status}
                  </td> */}
                          {/* <td>
                    <span className="quantity">3</span>
                  </td> */}
                          {/* <td>
                    <div className="total_price profit">{profit}</div>
                  </td>
                  <td>
                    <div className="total_price loss">{loss}</div>
                  </td>
                  <td>
                    <button className="cmn-btn" onClick={(e)=>handleFairness(e,el)}>Fairness Guaranteed</button>
                  </td> */}
                        </tr>
                      );
                    })}
                    {/* <tr>
                  <td>
                    <div className="date">
                      <span>16</span>
                      <span>APR</span>
                    </div>
                  </td>
                  <td>
                    <div className="box__img">
                      <img src={BoxImg} alt="inventory-img" />
                    </div>
                  </td>
                  <td>
                    <p>xyz, lorem</p>
                  </td>
                  <td>
                    <p>626</p>
                  </td>
                  <td>
                    <span className="quantity">3</span>
                  </td>
                  <td>
                    <div className="total_price profit">$1615</div>
                  </td>
                  <td>
                    <div className="total_price loss">$115</div>
                  </td>
                  <td>
                    <button className="cmn-btn" onClick={handleFairness}>Fairness Guaranteed</button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="date">
                      <span>16</span>
                      <span>APR</span>
                    </div>
                  </td>
                  <td>
                    <div className="box__img">
                      <img src={BoxImg} alt="inventory-img" />
                    </div>
                  </td>
                  <td>
                    <p>xyz, lorem</p>
                  </td>
                  <td>
                    <p>626</p>
                  </td>
                  <td>
                    <span className="quantity">3</span>
                  </td>
                  <td>
                    <div className="total_price profit">$1615</div>
                  </td>
                  <td>
                    <div className="total_price loss">$115</div>
                  </td>
                  <td>
                    <button className="cmn-btn" onClick={handleFairness}>Fairness Guaranteed</button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="date">
                      <span>16</span>
                      <span>APR</span>
                    </div>
                  </td>
                  <td>
                    <div className="box__img">
                      <img src={BoxImg} alt="inventory-img" />
                    </div>
                  </td>
                  <td>
                    <p>xyz, lorem</p>
                  </td>
                  <td>
                    <p>626</p>
                  </td>
                  <td>
                    <span className="quantity">3</span>
                  </td>
                  <td>
                    <div className="total_price profit">$1615</div>
                  </td>
                  <td>
                    <div className="total_price loss">$115</div>
                  </td>
                  <td>
                    <button className="cmn-btn" onClick={handleFairness}>Fairness Guaranteed</button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="date">
                      <span>16</span>
                      <span>APR</span>
                    </div>
                  </td>
                  <td>
                    <div className="box__img">
                      <img src={BoxImg} alt="inventory-img" />
                    </div>
                  </td>
                  <td>
                    <p>xyz, lorem</p>
                  </td>
                  <td>
                    <p>626</p>
                  </td>
                  <td>
                    <span className="quantity">3</span>
                  </td>
                  <td>
                    <div className="total_price profit">$1615</div>
                  </td>
                  <td>
                    <div className="total_price loss">$115</div>
                  </td>
                  <td>
                    <button className="cmn-btn" onClick={handleFairness}>
                      Fairness Guaranteed
                    </button>
                  </td>
                </tr> */}
                  </tbody>
                </Table>
              </div>

              {!hide && (
                <div className="load-more">
                  <button type="button" onClick={handleLoadMore}>
                    {t("proTransactions.showMore")}{" "}
                    <i className="las la-angle-down ml-2"></i>
                  </button>
                </div>
              )}
            </>
          )}
        </div>
        {orderDetails?.orderSummary?.length > 0 && (
          <OrderDetails
            show={viewOrder}
            handleClose={handleViewOrder}
            orderDetails={orderDetails}
          />
        )}
      </div>
    </>
  );
};

export default Orders;
