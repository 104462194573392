import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import mail from '../../assets/images/icon/mail-icon.png'


const ThanksOrderPage = () => {

    return (
        <>
            <div className="thanks-page bg-new-all">

                <div className="thanks-popup-content">
                    <div className="thanks-image">
                        <img src={mail} alt="mail" className="mail-img" />
                    </div>
                    <div className="thanks-title">
                        <h2>
                            Thank You For the Order!
                        </h2>
                        <h4>
                            Your order has been confirmed
                        </h4>
                    </div>
                    <Link to="/profile?tab=orders" className="btn-style"><span>Go to Orders</span></Link>
                </div>
            </div>
        </>
    );
};

export default ThanksOrderPage;
