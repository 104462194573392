import React from 'react'
const HidePlaceHolder = ({ displayProduct }) => {
    return (
      <div className='roulette-placeholder wow animate__animated animate__zoomOut'>
        <div className='roulette-placeholder-box'>
          <img src={displayProduct} alt='' className='' />
        </div>
      </div>
    )
  }
export default HidePlaceHolder