import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import wallet from "../../assets/images/user/wallet-icon.png";
import withdraw from "../../assets/images/user/withdraw-icon.png";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getTransactionDetails } from "../../reducers/userReducer";
// import Loader from '../Loader/loader';
import DepositPopup from "../PopUp/depositPopup";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import TabbleLoader from "../Loader/tableloader";
import { calcTime } from "../../utils/getTimeZone";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// import { setSelectionRange } from '@testing-library/user-event/dist/utils';

const UserTransaction = ({ walletAmount }) => {
  //   const monthNames = ["January", "February", "March", "April", "May", "June",
  //   "July", "August", "September", "October", "November", "December"
  // ];

  const dispatch = useDispatch();
  const result = useSelector((state) => state.user);
  const { userTransactions, totalTransaction, loading } = result || {};
  // console.log('userTransactions', userTransactions, totalTransaction);
  // const { paymentOption } = adminSetting || {};

  const [skip, setSkip] = useState(0);
  const [hide, setHide] = useState(false);
  const [dateValue, setDateValue] = useState("");
  const [deposit, setDeposit] = useState(false);
  //let hideBtn = false;

  const handleDateSearch = (e) => {
    //  console.log("eeeee=>", e);
    const value = e;
    dispatch(getTransactionDetails({ skip, filterValue: value?.toString() }));
    setDateValue(value);
  };

  useEffect(() => {
    // console.log('hitjtiuiufoiedufoieyhfuiioehyfiouehfiu===??>?>', totalTransaction)
    // // if(skip<=totalTransaction){
    // console.log('dateValue', dateValue)

    dispatch(getTransactionDetails({ skip: skip, filterValue: dateValue }));
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { t } = useTranslation();
  //console.log('hideBtn===', hideBtn)
  const handleLoadMore = () => {
    const newValue = skip + 5 + 5;
    // console.log('skip', newValue)
    if (newValue >= totalTransaction) {
      setHide(true);
    }
    const newSkip = skip + 5;
    setSkip(newSkip);

    dispatch(
      getTransactionDetails({
        skip: newSkip,
        filterValue: dateValue,
        type: "laodmore",
      })
    );
  };

  // useEffect(()=>{
  //   handleLoadMore()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[])

  const handledeposit = () => {
    //console.log('paymentOption',paymentOption)
    //if (paymentOption) {
    setDeposit(true);
    //}
  };

  const handleClose = () => {
    setDeposit(false);
  };

  return (
    <>
      {/* {loading&&<Loader/>} */}
      <div>
        <div className="transaction-balance-wrapper">
          <div className="ar-trans-left left">
            <div className="transaction-balance">
              <h4 className="balance">$ {walletAmount?.toFixed(2)} </h4>
              <span>{t("proTransactions.availTxt")}</span>
            </div>
          </div>
          <div className="right">
            <Link className="transaction-action-btn" onClick={handledeposit}>
              <img src={wallet} alt="wallet" />
              <span>{t("proTransactions.depoTxt")}</span>
            </Link>
            <Link
              to="/profile?tab=inventory"
              className="ar-trans transaction-action-btn ml-4"
            >
              <img src={withdraw} alt="withdraw" />
              <span>{t("proTransactions.withdTxt")}</span>
            </Link>
          </div>
        </div>
        <div className="all-transaction">
          <div className="all-transaction__header">
            <h3 className="title">{t("proTransactions.transHeading")}</h3>
            <div className="date-range global-date-picker">
              {/* <input type="date" onChange={handleDateSearch} className="datepicker-here form-control" /> */}
              {/* <i className="las la-calendar-alt"></i> */}
              <DatePicker
                selected={dateValue}
                onChange={handleDateSearch}
                maxDate={new Date()}
                placeholderText="JJ/MM/YYYY"
                dateFormat="d/MM/yyyy"
                showYearDropdown={true}
                dropdownMode="select"
              />
            </div>
          </div>
          {loading ? (
            <TabbleLoader />
          ) : (
            <>
              <div className="table-responsive-xl">
                <Table>
                  <thead>
                    <tr>
                      <th>{t("proTransactions.thTxt1")}</th>
                      <th>ACTION</th>
                      <th>{t("proTransactions.thTxt2")}</th>
                      {/* <th>{t("proTransactions.thTxt3")}</th> */}

                      <th>{t("proTransactions.thTxt4")}</th>
                      <th>{t("proTransactions.thTxt5")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userTransactions?.map((el) => {
                      // const date = new Date(el?.createdAt)
                      // const curdata = date.getDate();
                      // const monthName = date.toLocaleString('default', { month: 'short' });

                      return (
                        <tr>
                          <td>
                            <div className="date">
                              <span>{calcTime("+1", el?.createdAt)}</span>
                              {/* <span>{curdata}</span> */}
                              {/* <span>{monthName}</span> */}
                            </div>
                          </td>
                          <td>
                            <p>{el?.debitCreditType} </p>
                            {/* <span>{el?.descriptionMessage}</span> */}
                          </td>
                          <td>
                            <p>
                              {el?.type === "deposit" &&
                              el?.description !== "Sell Item"
                                ? el?.paymentType
                                : el?.description === "Inventory"
                                ? t("transaction.inventoryOrder")
                                : el?.description === "Box"
                                ? t("transaction.unboxing")
                                : el?.description === "store"
                                ? "STORE ORDER"
                                : el?.description === "Sell Item"
                                ? "ITEM SOLD"
                                : ""}
                            </p>
                          </td>
                          <td>
                            <span className="amount minus-amount">{`${
                              el?.debitCreditType === "debit"
                                ? "-"
                                : el?.status === "success"
                                ? "+"
                                : ""
                            } $ ${el?.amount} `}</span>
                          </td>
                          <td>
                            <div className="status-pending">
                              <i
                                className={
                                  el?.status === "success"
                                    ? "fas fa-check-circle"
                                    : "fas fa-times-circle"
                                }
                              ></i>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                    {/* <tr>
                      <td>
                        <div className="date">
                          <span>16</span>
                          <span>APR</span>
                        </div>
                      </td>
                      <td>
                        <p>lottery purchase</p>
                        <span>Contest No: D87T</span>
                      </td>
                      <td>
                        <p>Visa</p>
                      </td>
                      <td>
                        <span className="amount minus-amount">-$758.00 (USD)</span>
                      </td>
                      <td>
                        <div className="status-success"><i className="fas fa-check-circle"></i></div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="date">
                          <span>16</span>
                          <span>APR</span>
                        </div>
                      </td>
                      <td>
                        <p>lottery purchase</p>
                        <span>Contest No: D87T</span>
                      </td>
                      <td>
                        <p>Visa</p>
                      </td>
                      <td>
                        <span className="amount minus-amount">-$758.00 (USD)</span>
                      </td>
                      <td>
                        <div className="status-success"><i className="fas fa-check-circle"></i></div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="date">
                          <span>16</span>
                          <span>APR</span>
                        </div>
                      </td>
                      <td>
                        <p>Deposit</p>
                        <span>Bank account to Rifa Account</span>
                      </td>
                      <td>
                        <p>Visa</p>
                      </td>
                      <td>
                        <span className="amount plus-amount">-$758.00 (USD)</span>
                      </td>
                      <td>
                        <div className="status-success"><i className="fas fa-check-circle"></i></div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="date">
                          <span>16</span>
                          <span>APR</span>
                        </div>
                      </td>
                      <td>
                        <p>lottery purchase</p>
                        <span>Contest No: D87T</span>
                      </td>
                      <td>
                        <p>Visa</p>
                      </td>
                      <td>
                        <span className="amount minus-amount">-$758.00 (USD)</span>
                      </td>
                      <td>
                        <div className="status-success"><i className="fas fa-check-circle"></i></div>
                      </td>
                    </tr> */}
                  </tbody>
                </Table>
              </div>
              {!hide && (
                <div className="load-more" onClick={handleLoadMore}>
                  <button type="button">
                    <span>
                      {t("proTransactions.showMore")}{" "}
                      <i className="las la-angle-down ml-2"></i>
                    </span>
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <DepositPopup show={deposit} handleClose={handleClose} />
    </>
  );
};

export default UserTransaction;
