import React, { useEffect, useState } from "react";
import Layout from "../layout/layout";
import Helmet from "react-helmet";
import "./cart.css";
// import { SubscribeForm } from "./SubscribeForm";
import { CartItem } from "./cartItem";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  removeAllCartItems,
  getCartProducts,
} from "../../reducers/userReducer";
import Loader from "../Loader/loader";
import { t } from "i18next";

export const Cart = () => {
  const dispatch = useDispatch();
  const result = useSelector((state) => state.user);
  const { allCartsProducts, loading } = result || {};
  // console.log('addlsadsdsadasdsdsad',allCartsProducts)
  const [cartState, setCartState] = useState([]);
  // const [loading,setLoading] = useState(true);

  // console.log(JSON.parse(localStorage.getItem('addtoCarts')));
  const token = localStorage.getItem("inboxi#@user");
  const cartData = token
    ? allCartsProducts
    : JSON.parse(localStorage.getItem("addtoCarts"));

  useEffect(() => {
    // setLoading(true);
    const data = cartData?.length > 0 ? cartData : [];
    // if(cartData?.length>0){
    setCartState(data);
    // setLoading(false);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCartsProducts]);

  // const [allCarts,setAllCarts] = useState([])

  useEffect(() => {
    dispatch(getCartProducts());
  }, [dispatch]);

  // useEffect(()=>{
  //   if(allCartsProducts?.length>0){
  //     const copy =  allCartsProducts.slice()
  //   setAllCarts(copy)
  //   }
  // },[allCartsProducts])

  const calculatePrice = (quant, price) => {
    let result = parseFloat(quant || 0) * parseFloat(price || 0);
    return result;
  };

  const totalCartPrice = (data) => {
    if (data?.length > 0) {
      const result = data.reduce((prev, next) => {
        // console.log('next===',next)
        return (
          prev +
          parseFloat(next?.productQuantity || 0) *
            parseFloat(next?.productId?.price || 0)
        );
      }, 0);
      // console.log('result',result)
      return result;
    }
  };

  const handleDeleteAll = () => {
    dispatch(removeAllCartItems());
  };
  // const handleClick = (type,ind) =>{
  //   console.log('jfjdfhdhfj',type,ind, allCarts[ind])
  //   allCarts[ind].productQuantity = 5
  //   // let prQuant =  allCarts[ind].productQuantity;
  //   // if(type === 'minus' && allCarts[ind].productQuantity>1){
  //   //   allCarts[ind].productQuantity =  allCarts[ind]?.productQuantity - 1
  //   // }else{
  //   //   allCarts[ind].productQuantity =  allCarts[ind]?.productQuantity + 1
  //   // }
  //   // allCarts[ind].productQuantity = prQuant
  //   console.log('hhhh', allCarts[ind])
  // }

  // const cry = JSON.parse(localStorage.getItem('addtoCarts'))

  // useEffect(()=>{},[cry])

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Cart | Cilycase" />
        <title>Cart | Cilycase</title>
      </Helmet>
      <Layout>
        {loading && <Loader />}
        <section className="cart-page">
          {/* {loading && <Loader/>} */}
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="cart-wrapper">
                  <h2 className="cart-wrapper__title">{t("cart.cartTxt")}</h2>
                  {cartState?.length > 0 ? (
                    <div className="row justify-content-lg-between">
                      <div className="col-lg-7">
                        <div className="ticket-wrapper">
                          <div className="ticket-wrapper__header">
                            <h3>{t("boxDetails.productTxt")}:</h3>
                            <button type="button" onClick={handleDeleteAll}>
                              {t("cart.clearAllTxt")}
                            </button>
                          </div>
                          <div className="ticket-wrapper__body">
                            <div className="single-row">
                              {cartState &&
                                cartState.length > 0 &&
                                cartState.map((el, index) => (
                                  <CartItem
                                    data={el}
                                    index={index}
                                    setCartState={setCartState}
                                  />
                                ))}
                            </div>
                            {/* <div className='single-row'>
                          <CartItem />
                        </div>
                        <div className='single-row'>
                          <CartItem />
                        </div>
                        <div className='single-row'>
                          <CartItem />
                        </div>
                        <div className='single-row'>
                          <CartItem />
                        </div>
                        <div className='single-row'>
                          <CartItem />
                        </div>
                        <div className='single-row'>
                          <CartItem />
                        </div>
                        <div className='single-row'>
                          <CartItem />
                        </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-5 mt-lg-0 mt-4">
                        <div className="checkout-wrapper">
                          <div className="checkout-wrapper__header">
                            <h3>{t("cart.cartTxt2")}:</h3>
                          </div>
                          <div className="checkout-wrapper__body">
                            <ul className="price">
                              {cartState?.length > 0 &&
                                cartState.map((el) => {
                                  const prod = el?.productId;
                                  return (
                                    <li>
                                      <div className="left">
                                        <h4 className="caption">
                                          {prod?.name}
                                        </h4>
                                        <span>{`(${el?.productQuantity} product X $ ${prod?.price} )`}</span>
                                      </div>
                                      <div className="right">
                                        <span className="price">
                                          ${" "}
                                          {calculatePrice(
                                            el?.productQuantity,
                                            prod?.price
                                          )}{" "}
                                        </span>
                                      </div>
                                    </li>
                                  );
                                })}
                              <li>
                                <div className="left">
                                  <h4 className="caption">
                                    {t("cart.totalTxt")}
                                  </h4>
                                </div>
                                <div className="right">
                                  <span className="price">
                                    $ {totalCartPrice(cartState) || 0}
                                  </span>
                                </div>
                              </li>
                            </ul>
                            <div className="checkout-wrapper__btn">
                              <Link to="/checkout">
                                <button
                                  type="submit"
                                  className="cmn-btn btn-style"
                                >
                                  <span>{t("cart.checkoutTxt")}</span>
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="mt-30">
                          <img src={""} alt="" />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <h4 className="text-center">{t("cart.cartEmpTxt")}</h4>
                  )}
                </div>
              </div>
              {/* <div className='col-lg-12 pt-120 pb-120'>
              <div className='pick-lottery-package'>
                <h2 className='title'>Choose a Quick Pick</h2>
                <div className='lottery-package-list'>
                  <a href='#0'>3 Quick Picks For $14.97</a>
                  <a href='#0'>5 Quick Picks For $24.95</a>
                  <a href='#0'>10 Quick Picks For $49.90</a>
                  <a href='#0'>20 Quick Picks For $99.80</a>
                </div>
              </div>
            </div> */}
              {/* <SubscribeForm /> */}
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};
