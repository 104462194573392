import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
// import { Link } from "react-router-dom";
import "./login.css";
// import { set, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
// import { yupResolver } from "@hookform/resolvers/yup";
// import { yupLoginValidation } from "../../utils/index";
// import { getCurrentOS } from "../../utils/useFunc";

// import { toast } from "react-toastify";
// import { withoutAuth } from "../../config/axios";
// import {
//   addToCart,
//   getLoginUser,
//   getDistinctInventoryProducts,
//   getCartProducts,
// } from "./../../reducers/userReducer";
// import {publicIpv4} from 'public-ip';
// import { useTranslation } from "react-i18next";
import { forgetPass } from "../../reducers/userReducer";
import { useTranslation } from "react-i18next";

// import {detectBrowser,getDeviceType} from '../../utils/userDeviceInformation';
// import ReCAPTCHA from "react-google-recaptcha";
const ForgetPassword = ({ forget, handleForget }) => {
  // const { t } = useTranslation();
  const dispatch = useDispatch();
  // const token = localStorage.getItem("inboxi#@user");
  // const cartData = token ? [] : JSON.parse(localStorage.getItem("addtoCarts"));
  // const [checkedBox, setCheckedBox] = useState(false);
  const [value, setValue] = useState("");
  // const [payload,setPayload]=useState(ini)
  // const siteKeyCapthca = '6LcEv30gAAAAAKtMiisIEoTU3FD5BKhEbMKwVme8'
  //Local  reCaptcha key ==> const siteKeyCapthca = '6LeDMxkfAAAAAEhBNa8zcj4DuuPbyfIWuwG2uAqO'
  // const[captchaValue,setCaptchaValue]=useState('');
  // const {
  //   register,
  //   formState: { errors },
  // } = useForm({
  //   mode: "onBlur",
  //   resolver: yupResolver(yupLoginValidation()),
  // });

  const handleForgetPass = (e) => {
    e.preventDefault();
    const payload = {
      value,
    };
    dispatch(forgetPass(payload));
  };

  const [isRTL, setISRTL] = useState(localStorage.getItem("i18nextLng"));
  useEffect(() => {
    setISRTL(localStorage.getItem("i18nextLng"));
  }, []);
  const { t } = useTranslation();

  return (
    <Modal
      show={forget}
      onHide={() => handleForget("change")}
      centered
      className={`modal fade login-popup registration-popup ${
        isRTL === "ar" ? " wrapper rtl" : "wrapper"
      }`}
      id="loginModal"
      tabindex="1"
      role="dialog"
      aria-hidden="true"
    >
      {/* <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable"> */}
      <div className="modal-content">
        <div className="modal-body">
          <div className="account-form-area">
            <button
              onClick={() => handleForget("change")}
              //onClick={() =>setForget(false)}
              type="button"
              className="close-btn"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i className="las la-times"></i>
            </button>
            <h3 className="ar-login-title title">{t("form.frgetPswrd")}</h3>
            {/* <h3 className="ar-login-title title">{t("loginForm.wlcmHeading")}</h3> */}
            <div className="account-form-wrapper">
              <form>
                <div className="form-group">
                  <label>
                    {t("proReferral.thTxt4")} / {t("form.phnTxt")}
                    <sup>*</sup>
                    {/* {t("loginForm.lableTxt1")} <sup>*</sup> */}
                  </label>
                  <input
                    className="form-control"
                    placeholder={t("proReferral.thTxt4")}
                    type="text"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                  />
                </div>
                {/* <div className="form-group">
                  <label>
                    {t("loginForm.lableTxt2")} <sup>*</sup>
                  </label>
                  <input
                    {...register("password")}
                    className="form-control"
                    placeholder="Password"
                    type="password"
                  />
                </div> */}
                {/* <div className="d-flex flex-wrap justify-content-between mt-2">
                  <div className="custom-checkbox-section">
                    <input
                      type="checkbox"
                      name="id-1"
                      id="id-1"
                      checked={checkedBox}
                      // onClick={handleClickCheck}
                    />
                    <label for="id-1" className="custom-checkbox-label">
                      {t("loginForm.rembTxt")}
                    </label>
                    <span className="checkbox"></span>
                  </div>
                  <a href="#0" className="link">
                    {t("loginForm.forgotTxt")}
                  </a>
                </div> */}

                <div className="form-group text-center mt-0">
                  <button
                    type="submit"
                    className="cmn-btn btn-style"
                    onClick={handleForgetPass}
                  >
                    <span>{t("form.sndTxt")}</span>
                    {/* <span>{t("loginForm.btnTxt")}</span> */}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </Modal>
  );
};

export default ForgetPassword;
