import React from "react";
import { useTranslation } from "react-i18next";

// import StripeDetails from "../unboxing/stripeDetails";

const GameType = ({
  active,
  handleActive,
  spinvalue,
  handleMute,
  playSound,
  opacity,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {/* <StripeDetails handleMute={handleMute} playSound={playSound} /> */}
      <div className={`stripe-details-section ${opacity ? `` : `hide`}`}>
        <div className="stripe-details">
          <div className="hot-keys-section">
            <div className="content">
              <p>
                {t("boxDetails.buyCashTxt")}{" "}
                <kbd>{t("boxDetails.enterTxt")}</kbd>
              </p>
            </div>
            <div className="content">
              <p>
                {t("boxDetails.quickStopTxt")}{" "}
                <kbd>{t("boxDetails.spaceTxt")}</kbd>
              </p>
            </div>
            {/* <div className="content">
              <p>
                sell <kbd>s</kbd>
              </p>
            </div> */}
            <div className="fairness-link" onClick={handleMute}>
              {playSound ? (
                <span>
                  <i class="fas fa-volume-up"></i>
                </span>
              ) : (
                <span>
                  <i className="fas fa-volume-mute" />
                </span>
              )}
            </div>
          </div>
          <div className="case-quantity">
            <div
              className={`game-type wow animate__animated animate__slideInUp ${
                spinvalue > 0 ? `disables` : ``
              } `}
            >
              <h6>{t("boxDetails.caseQuantityTxt")}</h6>
              <ul>
                <li className={`${active === "1" ? `active` : ``}`}>
                  <span onClick={(e) => handleActive("1")}>1</span>
                </li>
                <li className={`${active === "2" ? `active` : ``}`}>
                  <span onClick={(e) => handleActive("2")}>2</span>
                </li>
                <li className={`${active === "3" ? `active` : ``}`}>
                  <span onClick={(e) => handleActive("3")}>3</span>
                </li>
                <li className={`${active === "4" ? `active` : ``}`}>
                  <span onClick={(e) => handleActive("4")}>4</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default GameType;
