import React, { useState } from "react";
import Layout from "../layout/layout";
import Helmet from "react-helmet";
//import { Link } from "react-router-dom";
import BattlesFilter from "./battles-filter";
import BattlesTable from "./battles-table";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "./battles.css";

const Battles = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [sortTye, setSortTye] = useState(false);
  const handleCreateBattle = () => {
    if (!localStorage.getItem("inboxi#@user")) {
      history.push("/?modal=Login ");
      return;
    } else {
      history.push("/create-battle");
    }
  };
  const handleSort = (e) => {
    setSortTye(!sortTye);
    //console.log('eeee=>', e.target.value, e.target.name)
  };

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Battles | Cilycase" />
        <title>Battles | Cilycase</title>
      </Helmet>
      <Layout>
        <div className="battles-page">
          <div className="container ">
            <section className="position-relative pb-120">
              <div className="battles-section">
                <div className="row battles-top-header">
                  <div className="col-lg-12">
                    <h1 class="section-title">
                      <span class="section-title-arrow-left"></span>
                      <span class="section-title-text">
                        {t("header.battles")}
                      </span>
                      <span class="section-title-arrow-right"></span>
                    </h1>
                    <div
                      className=" battles-history"
                      onClick={handleCreateBattle}
                    >
                      <span className="cmn-btn btn-style">
                        <span>{t("openBoxes.createBattleBtn")}</span>
                      </span>
                    </div>
                  </div>

                  <div className="col-lg-6 header-content">
                    <span>{t("home.playHeading2")}?</span>
                    <i class="las la-dice"></i>
                    <span className="fairness">
                      {t("openBoxes.fairnessTxt")}
                    </span>
                  </div>
                </div>
              </div>

              <BattlesFilter handleSort={handleSort} />
              <BattlesTable sortTye={sortTye} />
            </section>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Battles;
