import React from 'react'
import { Link } from 'react-router-dom'

const BoxesHeader = ({heading}) => {
    return (
        <div className="container">
            <div className="row justify-content-center">
                <div
                    className="col-lg-12 wow fadeInUp"
                    data-wow-duration="0.5s"
                    data-wow-delay="0.3s">
                    <div className="mystery-cardbox-header py-5  d-flex justify-content-between align-items-center">
                        <div className='d-flex align-items-center gap-3'> <BoxesHeaderSvg />
                         <p>{heading}</p>
                        </div>
                        <div className='view-box'>
                        <Link
          to="/boxes"
          className="d-flex align-items-center justify-content-center">
           <p className='d-flex align-items-center gap-2'>View All <ArrowRight /></p>
        </Link> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BoxesHeader

const BoxesHeaderSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="28" viewBox="0 0 29 28" fill="none">
            <g clip-path="url(#clip0_226_1190)">
                <path d="M17.9706 5.0944V5.08747L23.1561 7.41004L28.3624 5.45811L14.5066 0.262207L0.650879 5.45811L14.5066 10.654L19.6921 8.70902L14.5066 6.57177V6.56657L17.9706 5.0944Z" fill="url(#paint0_linear_226_1190)" />
                <path d="M0.650879 7.18994V22.7777L13.6406 27.6497V12.062L0.650879 7.18994ZM7.57875 22.8348L4.11482 21.5358V19.6878L7.57875 20.9868V22.8348Z" fill="url(#paint1_linear_226_1190)" />
                <path d="M23.1664 9.13841V13.477L19.7025 14.776V10.4374L15.3726 12.062V27.6497L28.3623 22.7777V7.18994L23.1664 9.13841Z" fill="url(#paint2_linear_226_1190)" />
            </g>
            <defs>
                <linearGradient id="paint0_linear_226_1190" x1="14.5066" y1="0.262207" x2="14.5066" y2="10.654" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F06723" />
                    <stop offset="1" stop-color="#F4A21D" />
                </linearGradient>
                <linearGradient id="paint1_linear_226_1190" x1="7.14576" y1="7.18994" x2="7.14576" y2="27.6497" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F06723" />
                    <stop offset="1" stop-color="#F4A21D" />
                </linearGradient>
                <linearGradient id="paint2_linear_226_1190" x1="21.8674" y1="7.18994" x2="21.8674" y2="27.6497" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F06723" />
                    <stop offset="1" stop-color="#F4A21D" />
                </linearGradient>
                <clipPath id="clip0_226_1190">
                    <rect width="27.7115" height="27.7115" fill="white" transform="translate(0.650879 0.100098)" />
                </clipPath>
            </defs>
        </svg>
    )
}

const ArrowRight=()=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
<path d="M10.6055 21.5177L17.5635 14.5596L10.6055 7.60156" stroke="#F4A21D" stroke-width="2.22658" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
    )
}