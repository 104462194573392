import React from "react";
import "./cart.css";
import { updateCartQty } from "../../reducers/userReducer";
// import cartProduct from "../../assets/images/cartProduct.png";
import {  useDispatch } from "react-redux";
import {
  getCartProducts,deleteCartItems,updateCartQuantity
} from "../../reducers/userReducer";

export const CartItem = ({data,index,setCartState}) => {
 
  const  prod  =  data?.productId;
  // console.log('PRODUCT DETAILS===>1',prod?.brandId?.name)
  const token = localStorage.getItem('inboxi#@user')
  const dispatch = useDispatch()
  const handleQuantity = (increament, index) => {

      if(!token){
        const cartData =  JSON.parse(localStorage.getItem('addtoCarts'));
        // console.log('cartData',cartData)
        cartData[index].productQuantity += increament;
        setCartState(cartData)
      return  localStorage.setItem('addtoCarts',JSON.stringify(cartData))
      }

      if(data?.productQuantity >= data?.productId?.totalProduct && increament === 1){
        return false;
      }
      if(data?.productQuantity <= 1 && increament === -1){
        return false;
      }

      dispatch(updateCartQty({increament,index}))
      // console.log('dataIndex',data,data[index])
      dispatch(updateCartQuantity({id:data?._id,qty: data.productQuantity + increament}))
      
  }

  const  handleDelete = async(id) =>{
    // console.log('iiid',id)
    const res =  await dispatch(deleteCartItems(id))
    const { code } = res?.payload?.resultData?.data;
    // console.log('codeeeeeeeeeeee==>>>',code)
    if(code === 200){
      dispatch(getCartProducts())
    }
    
  }



  return (
    <div className='cart-items'>
      <div className='items'>
        <div className='item-img'>
          <img src={prod?.bannerImage} alt='' />
        </div>
        <div className='item-desc'>
          <h3>{prod?.name}</h3>
          <p>{prod?.brandId?.name}</p>
        </div>
      </div>
      <div className='action-btns'>
        <div className='item-quantity-btn d-flex'>
          <button type='button' className='edit-btn' onClick={()=>handleQuantity(-1,index)}>
            <i class='las la-minus'></i>
          </button>
          <div className='item-count'>
            <p>{data?.productQuantity}</p>
          </div>
          <button type='button' className='del-btn' onClick={()=>handleQuantity(1,index)}>
            <i class='las la-plus'></i>
          </button>
        </div>
        {/* <button type='button' className='edit-btn'>
          <i className='las la-pen'></i>
        </button> */}
        <button type='button' className='del-btn' onClick={()=>handleDelete(data?._id)}>
          <i className='las la-trash-alt'></i>
        </button>
      </div>
    </div>
  );
};
