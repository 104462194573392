/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import { Modal, Spinner, Form } from "react-bootstrap";
// import { marketPlaceInstance } from "../../config/axios.js";
import {  useEffect, useState } from "react";
import { useForm } from "react-hook-form";
// import { number } from "card-validator";
import { getLoginUser } from "../../reducers/userReducer";
import { useDispatch,useSelector } from "react-redux";
import { userInstance } from "../../config/axios";
import axios from "axios";



const AuthrizeCustomModel = ({
  showAuthForm,
  setShowAuthForm,
  amount,
  promoCode,
  prize,
  handleClose,
  getGCPackages,
}) => {
  // const { user } = useContext(AuthContext);
  const dispatch = useDispatch();
  const result = useSelector((state) => state.user);
  const {  userDetails } = result || {};

console.log(userDetails)
  const { reset, setValue } = useForm({
    defaultValues: {
      firstName: userDetails?.firstname,
      lastName: userDetails?.lastname,

      // phone: user?.phone,
    },
    mode: "onBlur",
  });

  // const customStyles = {
  //   container: (provided) => ({
  //     ...provided,
  //     width: "100%",
  //   }),
  //   option: (provided) => ({
  //     ...provided,
  //     background: "#1f2332",
  //     color: "#bcbcbc",
  //     fontWeight: "400",
  //     fontSize: "16px",
  //     padding: "10px 20px",
  //     lineHeight: "16px",
  //     cursor: "pointer",
  //     borderRadius: "4px",
  //     borderBottom: "1px solid #212529",
  //     ":hover": {
  //       background: "#191b20",
  //       borderRadius: "4px",
  //     },
  //   }),
  //   menu: (provided) => ({
  //     ...provided,
  //     background: "#1f2332",
  //     borderRadius: "6px",
  //     padding: "10px 20px",
  //     border: "2px solid transparent",
  //   }),
  //   control: () => ({
  //     display: "flex",
  //     alignItem: "center",
  //     margin: "2px 0",
  //     boxShadow: " 0 2px 10px #000000a5",
  //     cursor: "pointer",
  //     fontSize: "14px",
  //     background: "#1f2332",
  //     border: "1px solid #ffc700 ",
  //     borderRadius: "10px",
  //     color: "#bcbcbc",
  //     height: "54px",
  //     ":hover": {
  //       border: "1px solid #ffc700 ",
  //       borderRadius: "10px",
  //       color: "#bcbcbc",
  //     },
  //   }),
  //   singleValue: (provided) => ({
  //     ...provided,
  //     color: "#bcbcbc",
  //     fontWeight: "400",
  //     fontSize: "14px",
  //     lineHeight: "16px",
  //   }),
  //   indicatorSeparator: (provided) => ({
  //     ...provided,
  //     display: "none",
  //   }),
  //   placeholder: (provided) => ({
  //     ...provided,
  //     fontWeight: "400",
  //     fontSize: "14px",
  //     lineHeight: "19px",
  //     background: "#1f2332",
  //     border: "1px solid #ffc700 ",
  //     borderRadius: "10px",
  //     color: "#bcbcbc",
  //   }),
  //   input: (provided) => ({
  //     ...provided,
  //     height: "42px",
  //     color: "#bcbcbc",
  //   }),
  //   valueContainer: (provided) => ({
  //     ...provided,
  //     padding: "2px 20px",
  //   }),
  //   indicatorsContainer: (provided) => ({
  //     ...provided,
  //     paddingRight: "20px",
  //     color: "#bcbcbc",
  //   }),
  //   svg: (provided) => ({
  //     ...provided,
  //     fill: "#d7d7d7 !important",
  //     ":hover": {
  //       fill: "#d7d7d7 !important",
  //     },
  //   }),
  // };

  const [loader, setLoader] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  // const [isValid, setIsValid] = useState(true); // Assuming initial state is valid
  const [expDate, setExpDate] = useState("");
  const [cardCode, setCardCode] = useState("");
  // const [success, setSuccess] = useState(false);
  const [error, setErrorMsg] = useState("");
  // // const [country, setCountry] = useState({
  //   value: "US",
  //   label: "US",
  // });

  // const [packgaeData, setPackageData] = useState();
  // const [showFreeSpin /* setShowFreeSpin */] = useState(true);

  // const countries = [
  //   { value: "US", label: "US" },
  //   { value: "CA", label: "CA" },
  // ];
  const handleClosePayForm = () => {
    reset();
    // setIsValid(true);
    setCardCode("");
    setCardNumber("");
    setShowAuthForm(!showAuthForm);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    // if (value && !value.match(/^[0-9]{1,19}$/)) {
    //   return;
    // }
    if (value && value.length > 19) {
      return;
    }
    setCardNumber(value);
    // setIsValid(number(value).isValid);
  };
  const handleExpDateChange = (event) => {
    var code = event.keyCode;
    var allowedKeys = [8];
    if (allowedKeys.indexOf(code) !== -1) {
      return;
    }

    event.target.value = event.target.value
      .replace(
        /^([1-9]\/|[2-9])$/g,
        "0$1/" // 3 > 03/
      )
      .replace(
        /^(0[1-9]|1[0-2])$/g,
        "$1/" // 11 > 11/
      )
      .replace(
        /^([0-1])([3-9])$/g,
        "0$1/$2" // 13 > 01/3
      )
      .replace(
        /^(0?[1-9]|1[0-2])([0-9]{2})$/g,
        "$1/$2" // 141 > 01/41
      )
      .replace(
        /^([0]+)\/|[0]+$/g,
        "0" // 0/ > 0 and 00 > 0
      )
      .replace(
        /[^\d\/]|^[\/]*$/g,
        "" // To allow only digits and `/`
      )
      .replace(
        /\/\//g,
        "/" // Prevent entering more than 1 `/`
      );
    setExpDate(event.target.value); // Limit to "MM/YY"
  };

  const handleInputCardCodeChange = (event) => {
    const inputValue = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    setCardCode(inputValue);
  };

  // const handleCountryChange = (selectedOption) => {
  //   setValue("country", selectedOption?.label);
  //   setCountry(selectedOption);
  // };

  // const getPromoCode = () => {
  //   console.log("prizehgfghgfhfgh", prize.priceInBUSD, promoCode);
  //   if (prize.offerType !== "MegaOffer" && prize?.priceInBUSD !== "250") {
  //     return promoCode ? promoCode : "";
  //   } else {
  //     return "";
  //   }
  // };
  const pay = async (values) => {
    setLoader(true);
    console.log("valuesvalues", values);
    try {
      const res =
        await userInstance().post(
        `/api/user/auth-make-payment`,
        {
          ...values,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
          credentials: "include",
        }
      );
      setLoader(false);
      console.log("res", res);
      if (res?.data?.success) {
        handleClosePayForm();
        setCardNumber("");
        setExpDate("");
        setCardCode("");
        // setSuccess(true);
        dispatch(getLoginUser())
        handleClose()

        // toast.success(res.data.message, { id: "buy-sucess" });
      } else {
        setErrorMsg(res.data.error);
        // toast.error(res.data.error, { id: "buy-failed" });
      }
    } catch (e) {
      setLoader(false);
      console.log("ee55", e.response);
      // console.log("ee55", JSON.parse(e));
      if (axios.isAxiosError(e) && e?.response) {
        if (e?.response?.status !== 200) {
          setErrorMsg(e?.response?.data?.error || e?.response?.data?.message);
          // toast.error(e?.response?.data?.error || e?.response?.data?.message, {
          //   toastId: "login",
          // });
        }
      }
    }
  };
  // const handleOk = async (event) => {
  //   try {
  //     window.location.href = "/crypto-to-gc";

  //     setSuccess(false);
  //     window.location.href = "/crypto-to-gc";
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // };

  // const handleCloseFreeSpin = () => {
  //   window.location.href = "/crypto-to-gc";
  // };

  useEffect(() => {
    setValue("country", "US");
  }, []);

  return (
    <>
        <Modal
          show={showAuthForm}
          onHide={handleClosePayForm}
          centered
          size="lg"
          backdrop="static"
          className="free-st-popup payment_popup"
          style={{color:"white"}}
        >
          <Modal.Body>
            <Modal.Header className="payment-header">Payment Form</Modal.Header>
            <div
              className="paymentCrossIcon"
              onClick={() => handleClosePayForm()}
            >
              {" "}
              <CrossIconSVG />
            </div>
            <Form >
              <div className="select-banner-option">
                <Form.Group controlId="cardNumber">
                  <Form.Label>Card Number*</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="1234 5678 9123 4567"
                    autoComplete="off"
                    value={cardNumber}
                    onChange={handleChange}
                    style={{color:"white"}}
                    // isInvalid={!isValid}
                    required
                  />
                  {/* {!isValid && (
                    <Form.Control.Feedback type="invalid">
                      Invalid card number
                    </Form.Control.Feedback>
                  )} */}
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label>Exp. Date*</Form.Label>
                  <Form.Control
                    type="text"
                    name="expDate"
                    placeholder="Ex. 07/29"
                    onKeyUp={handleExpDateChange}
                    maxLength={5}
                    style={{color:"white"}}
                    required
                  />
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label>Card Code*</Form.Label>
                  <Form.Control
                    type="text"
                    name="cardCode"
                    placeholder="CVV"
                    autoComplete="off"
                    value={cardCode}
                    onChange={handleInputCardCodeChange} // Call handleInputChange on input change
                    maxLength={5} // Limit input to 4 characters
                    style={{color:"white"}}
                    required // Make the field required if needed
                  />
                </Form.Group>
              </div>
              {/* <Modal.Header className="payment-header">
                Billing Address
              </Modal.Header> */}
              <div className="select-banner-option">
                {/* <Form.Group className="form-group">
                  <Form.Label>First Name*</Form.Label>
                  <Form.Control
                    type="text"
                    readOnly
                    disabled
                    name="firstName"
                    placeholder="Ex. John"
                    autoComplete="off"
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(
                        /[^A-Za-z]/gi,
                        ""
                      ); // Remove non-alphabetical characters
                    }}
                    {...register("firstName")}
                    required
                  />
                </Form.Group> */}
                {/* <Form.Group className="form-group">
                  <Form.Label>Last Name*</Form.Label>
                  <Form.Control
                    type="text"
                    readOnly
                    disabled
                    name="lastName"
                    placeholder="Ex. Smith"
                    autoComplete="off"
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(
                        /[^A-Za-z]/gi,
                        ""
                      ); // Remove non-alphabetical characters
                    }}
                    {...register("lastName")}
                    required
                  />
                </Form.Group> */}
                {/* <Form.Group className="form-group">
                  <Form.Label>Country*</Form.Label>
                  <Select
                    id="country"
                    className="country_select"
                    value={country}
                    onChange={handleCountryChange}
                    options={countries}
                    styles={customStyles}
                  />
                </Form.Group> */}
              </div>
              {/* <div className="select-banner-option">
                <Form.Group className="form-group">
                  <Form.Label>Zip Code*</Form.Label>
                  <Form.Control
                    type="text"
                    name="zipCode"
                    placeholder="Ex. 898989ee"
                    autoComplete="off"
                    maxLength={20}
                    {...register("zipCode")}
                    required
                  />
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label>Street Address*</Form.Label>
                  <Form.Control
                    type="text"
                    name="streetAddress"
                    placeholder="Street Address"
                    autoComplete="off"
                    {...register("streetAddress")}
                    required
                  />
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label>City*</Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    placeholder="City"
                    autoComplete="off"
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(
                        /[^A-Za-z\s]/g,
                        "" // Remove non-alphabetical characters except spaces
                      );
                    }}
                    {...register("city")}
                    required
                  />
                </Form.Group>
              </div> */}
              {/* <div className="select-banner-option">
                <Form.Group className="form-group">
                  <Form.Label>State*</Form.Label>
                  <Form.Control
                    type="text"
                    name="state"
                    placeholder="State"
                    autoComplete="off"
                    onKeyPress={(e) => {
                      const regex = /^[A-Za-z\s]+$/;
                      if (!regex.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    {...register("state")}
                    required
                  />
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="number"
                    name="phoneNumber"
                    placeholder="Phone Number"
                    autoComplete="off"
                    {...register("phoneNumber")}
                  />
                </Form.Group>
              </div> */}
              {error && (
                <div style={{ color: "red", textAlign: "center" }}>{error}</div>
              )}
              <div className="popupBtn">
                <button
                  onClick={()=>pay({cardNumber,expDate,cardCode,amount})}
                  disabled={loader}
                  className="yellowBtn greenBtn"
                  variant="primary"
                  type="submit"
                >
                  {loader ? <Spinner animation="border" /> : "Pay"}
                </button>

                {/* <Button
      className="yellowBtn"
      variant="primary"
      onClick={() => handleClosePayForm()}
    >
      Cancel
    </Button> */}
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      
    </>
  );
};

export default AuthrizeCustomModel;
export const CrossIconSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-x"
      viewBox="0 0 16 16"
    >
      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
    </svg>
  );
};
