import React from "react";
import { Modal, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import close from "../../assets/images/game/close.png";

const InventoryPopUp = ({
  onCloseModal,
  open,
  handleConfirm,
  userDetails,
  handleAddressChange,
  selectedAddress,
  allShippingMethods,
  selectedShipping,
  handleSelectShippingMethod,
  handleAccept,
  accept,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={open}
      onHide={() => onCloseModal("change")}
      className={`login-popup order-details-popup address-details-popup`}
      size="lg"
      centered
    >
      {/* {console.log("SELECT ADDRESS ==>",selectedAddress)} */}
      <div class="modal_contents">
        <Modal.Header closeButton>
          <Modal.Title>{t("model.addressTxt")}</Modal.Title>
          <div className="close-img">
            <img
              src={close}
              onClick={() => onCloseModal("change")}
              alt="close"
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="adress-section">
            {userDetails?.shippingAddress?.length > 0 ? (
              userDetails?.shippingAddress?.map((el, index) => (
                <div
                  className={`address-list  ${
                    el.status ? "selected-address" : ""
                  }`}
                >
                  <h6>{t("proShipDetails.placeTxt4")} </h6>
                  <div className="address-details">
                    <i class="las la-home"></i>
                    {el.address}
                  </div>
                  <i class="las la-check"></i>
                </div>
              ))
            ) : (
              <h2 className="text-center">{t("model.addressTxt2")}</h2>
            )}
            {userDetails?.shippingAddress?.length > 0 &&
              allShippingMethods?.length > 0 && (
                <div className="address-list">
                  <h6>{t("model.addressTxt3")}</h6>
                  {/* {console.log('UserDetail UID==>>',userDetails)} */}
                  <div className="address-details">
                    <select
                      name="select-address"
                      onChange={handleSelectShippingMethod}
                      value={selectedShipping}
                    >
                      {allShippingMethods?.map((el) => (
                        <option value={el.shippingName}>
                          {el.shippingName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="inventroy-policy">
                    <h6>{t("model.addressTxt4")}</h6>
                    <div className="select-all">
                      <Form.Group
                        className=""
                        controlId="formBasicCheckboxcgcgcfffg"
                      >
                        <Form.Check
                          type="checkbox"
                          checked={accept}
                          label={t("model.addressTxt5")}
                          onClick={handleAccept}
                          id="jhjrgjhgbt4h"
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>
              )}

            <div className="d-flex my-2 inventroy-btn">
              <button
                onClick={onCloseModal}
                className="cmn-btn pop_btn pop_btns btn-style btn-style-transparent"
              >
                <span>{t("model.addressTxt6")}</span>
              </button>
              <button
                onClick={handleConfirm}
                className="cmn-btn pop_btn bor_btn btn-style"
              >
                <span>{t("model.addressTxt7")}</span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};
export default InventoryPopUp;
