import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import close from "../../assets/images/game/close.png";
import { shoppingValidation } from "../../utils/validation";
import { userInstance } from "../../config/axios";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { getLoginUser } from "../../reducers/userReducer";
const AddShippingPopup = ({ show, handleClose, setOpen }) => {
  const dispatch = useDispatch();
  const governoratesData = [
    "Ariana",
    "Béja",
    "Ben Arous",
    "Bizerte",
    "Gabès",
    "Gafsa",
    "Jendouba",
    "Kairouan",
    "Kasserine",
    "Kebili",
    "Kef",
    "Mahdia",
    "Manouba",
    "Medenine",
    "Monastir",
    "Nabeul",
    "Sfax",
    "Sidi Bouzid",
    "Siliana",
    "Sousse",
    "Tataouine",
    "Tozeur",
    "Tunis",
    "Zaghouan",
  ];
  const initialAddress = {
    address: "",
    zipCode: "",
    country: "Tunisia",
    city: "",
    state: "",
    uid: "",
    status: true,
  };
  const [errors, setErrors] = useState({});
  const [userData, setUserData] = useState(initialAddress);
  const handleEditChange = (e) => {
    const { value, name } = e.target;
    setUserData({ ...userData, [name]: value });
  };
  const handleAddShippingAddress = async () => {
    let randomStr = (
      Math.floor(Math.random() * 9 + 1) +
      Date.now() +
      (Math.random() + 1).toString(36).substring(7)
    ).toString();
    userData.uid = randomStr;
    const { error, isValid } = shoppingValidation(userData);
    setErrors(error);
    if (!isValid) {
      return;
    }
    const response = await userInstance().put(
      "/api/user/updateShippingAddress",
      userData
    );
    const { code } = response.data;
    if (code === 200) {
      dispatch(getLoginUser());
      toast.success(t("toast.addressaddsuccess"), { toastId: "shippingadd" });
      setOpen(true);
      handleClose();
    }
  };
  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className={`login-popup order-details-popup address-details-popup`}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {t("model.addressTxt")}
          <p>{t("model.addressTxt8")}</p>
        </Modal.Title>
        <div className="close-img">
          <img src={close} onClick={handleClose} alt="close" />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="adress-section">
          <div className="user-info-card">
            <div className="user-info-card__header">
              <h3 className="user-info-card__title">
                {t("model.addressTxt9")}
              </h3>
            </div>

            <div className="user-info-card__list">
              <div className="shipping-card-body">
                <div className="row">
                  <div className="col-md-6">
                    <input
                      type="text"
                      placeholder={t("proShipDetails.shipAddTxt")}
                      name="address"
                      onChange={(e) => handleEditChange(e)}
                    />
                    {errors?.address && (
                      <p className="error-msg">{errors.address}</p>
                    )}
                  </div>
                  <div className="col-md-6">
                    <input
                      type="text"
                      placeholder={t("proShipDetails.placeTxt3")}
                      onChange={(e) => handleEditChange(e)}
                      name="zipCode"
                    />
                    {errors?.zipCode && (
                      <p className="error-msg">{errors.zipCode}</p>
                    )}
                  </div>
                  <div className="col-md-4">
                    <select
                      name="country"
                      // onChange={(e) => handleCountryChange(e.target.value)}
                      disabled
                    >
                      <option value="Tunisia">Tunisia</option>
                    </select>
                    {errors?.country && (
                      <p className="error-msg">{errors.country}</p>
                    )}
                  </div>
                  <div className="col-md-4 ship-col-padding-0">
                    <select name="state" onChange={(e) => handleEditChange(e)}>
                      <option value="">Choose Governorate </option>
                      {governoratesData.map((el) => (
                        <option value={el}>{el}</option>
                      ))}
                    </select>
                    {errors?.state && (
                      <p className="error-msg">{errors.state}</p>
                    )}
                  </div>
                  <div className="col-md-4">
                    <input
                      type="text"
                      placeholder={t("proShipDetails.placeTxt7")}
                      name="city"
                      onChange={(e) => handleEditChange(e)}
                    />
                    {errors?.city && <p className="error-msg">{errors.city}</p>}
                  </div>
                  <Button
                    className="shipping-btn2"
                    onClick={handleAddShippingAddress}
                  >
                    <i className="fa fa-pencil"></i>&nbsp;{" "}
                    {t("proShipDetails.addBtn")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default AddShippingPopup;
