import moment from "moment";
import React from "react";
import 'moment/locale/fr'
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
// import { useSelector } from "react-redux";
// import orderImage from "../../assets/images/game/10gaming.png";
import close from "../../assets/images/game/close.png";

const OrderDetails = ({ show, handleClose, orderDetails }) => {
  // const result = useSelector((state)=>state.user);
  let status=orderDetails?.orderSummary[0].status;
  console.log("this is my order details ",orderDetails);
  console.log("this is my status  ",status);
  const { t } = useTranslation();

  // const {orderDetails} = result;
  // console.log("orderDetails", orderDetails);

  return (
    <Modal
      show={show}
      onHide={() => handleClose("")}
      className={`login-popup order-details-popup`}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("boxDetails.orderSumTxt")}</Modal.Title>
        <div className="close-img">
          <img src={close} onClick={() => handleClose("")} alt="close" />
        </div>
      </Modal.Header>
      <Modal.Body>
        {/* {orderDetails?.orderInfo?.reason && (
          <h6 className="info-section">
            Info : <span>{orderDetails?.orderInfo?.reason}</span>
          </h6>
        )} */}

        {orderDetails?.orderSummary?.map((el) => (
          <div className="order-details-content">
            <div className="left-section">
              <img src={el?.productId?.bannerImage} alt="orderimage" />
            </div>
            <div className="right-section">
              <p>
                {t("boxDetails.orderSumTxt1")} :{" "}
                <span>#{orderDetails?.orderInfo?.orderid}</span>
              </p>
              <h5>{el?.productId?.name}</h5>
              {/* <p>{el?.productId?.description}</p> */}
              <h6>
                {t("home.boxPriceTitle")}: <span>{el.price} Dt</span>
              </h6>
              <div class="quantity">
                <h6>
                  {t("boxDetails.orderSumTxt2")} : <span>{el?.quantity}</span>
                </h6>
              </div>
              <h6>
                {t("boxDetails.orderSumTxt3")} :{" "}
                <span>{orderDetails&&t(`orderStatus.${status}`)}</span>
                <span>{`${t("orderStatus.at")} ${moment(el?.updatedAt).locale("fr").format("DD MMMM YYYY")}`}</span>
              </h6>
              {el?.reason && (
                <h6>
                  Info: <span>{el?.reason}</span>
                </h6>
              )}
            </div>
          </div>
        ))}
        {/* <div className="order-details-content">
          <div className="left-section">
            <img src={orderImage} alt="orderimage" />

          </div>
          <div className="right-section">
              <p>Order No.: <span>#697579</span></p>
            <h5>Lorem ipsum is a dummy text</h5>
            <p>Lorem ipsum is a dummy text</p>
            <h6>Price: <span> $33,344</span></h6>
            <div class="quantity">
                <h6>Quantity: <span>5</span></h6>
            </div>
          </div>
        </div>
        <div className="order-details-content">
          <div className="left-section">
            <img src={orderImage} alt="orderimage" />

          </div>
          <div className="right-section">
              <p>Order No.: <span>#697579</span></p>
            <h5>Lorem ipsum is a dummy text</h5>
            <p>Lorem ipsum is a dummy text</p>
            <h6>Price: <span> $33,344</span></h6>
            <div class="quantity">
                <h6>Quantity: <span>5</span></h6>
            </div>
          </div>
        </div>
        <div className="order-details-content"> */}
        {/* <div className="left-section">
            <img src={orderImage} alt="orderimage" />

          </div>
          <div className="right-section">
              <p>Order No.: <span>#697579</span></p>
            <h5>Lorem ipsum is a dummy text</h5>
            <p>Lorem ipsum is a dummy text</p>
            <h6>Price: <span> $33,344</span></h6>
            <div class="quantity">
                <h6>Quantity: <span>5</span></h6>
            </div>
          </div>
        </div> */}
      </Modal.Body>
    </Modal>
  );
};
export default OrderDetails;
