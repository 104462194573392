import React from "react";
import Layout from "../layout/layout";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import "./faq.css";
import { useTranslation } from "react-i18next";
import faqIcon from "../../assets/images/faq/faqIcon.png";

const FaqPage = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <div className="faq-page">
        <div className="faq-page-topsection">
          <div className="container">
            <div className="faq-page-title">
              <h2>{t("faq.pageTitle")}</h2>
              <p>{t("faq.pageTitle2")}</p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="faq-page-icon">
            <img src={faqIcon} alt="" />
          </div>
          <div className="faq-section">
            <Accordion>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>{t("faq.ftitle1")}</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="accordian-desc">
                    <p>{t("faq.fdescTxt1")}</p>
                    <p>
                      {t("faq.fdescTxt2")}
                      <span> {t("faq.fdescTxt3")}</span>
                    </p>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>{t("faq.ftitle2")}</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="accordian-desc">
                    <p>{t("faq.fdescTxt4")}</p>
                    <p>
                      {t("faq.fdescTxt5")} <span>{t("faq.fdescTxt6")}</span>
                    </p>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>{t("faq.ftitle3")}</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="accordian-desc">
                    <p>
                      {t("faq.fdescTxt7")}
                      <span>{t("faq.fdescTxt8")}</span>
                    </p>
                    <p>
                      {t("faq.fdescTxt9")} <span>{t("faq.fdescTxt10")}</span>
                    </p>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>{t("faq.ftitle4")}</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="accordian-desc">
                    <p>
                      {t("faq.fdescTxt11")} <span>{t("faq.fdescTxt12")}</span>
                    </p>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default FaqPage;
