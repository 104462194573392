import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { socket } from "../../config/socket";
import { getAllBoxes } from "../../reducers/userReducer";

import BoxesHeader from "./BoxesHeader";
import MysterBox from "./MysterBox";
// import BattlesTable from "../battles/battles-table";
import NewBattlesTable from "../battles/newBattlesTable";

const OurRecomendedBox = ({t}) => {
    const dispatch = useDispatch();
    const result = useSelector((state) => state.user);
    const {allBattles, boxesList } = result;
    useEffect(() => {
        socket.on("newUpdatedBox", (data) => {
            dispatch(getAllBoxes());
        });

    }, [dispatch]);

    return (
        <section className="position-relative Our-recommended-box">
            <BoxesHeader heading="Our Recommended Boxes" />
            <MysterBox boxesList={boxesList} t={t} />
         <div className="battles-page battles-page-box">
          <div className="container ">
          {allBattles.length>0 && <BoxesHeader heading="Battles" /> } 
          <NewBattlesTable />
          </div>
          </div>
          
        </section>
    )
}

export default OurRecomendedBox



