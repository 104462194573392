/* eslint-disable array-callback-return */
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Layout from "../layout/layout";
import "./unboxing.css";
import item1 from "../../assets/images/home/item-1.png";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Roulette from "./game";
import { useSelector, useDispatch } from "react-redux";
// import img1 from '../../assets/images/home/1.jpg';
import {
  getSingleBoxData,
  getBoxesByProductId,
  getTopItemsUnboxed,
  getLiveDrops,
} from "../../reducers/userReducer";
import { socket } from "../../config/socket";
import { queryString } from "../../utils/queryString";
import ProductDetailsPopup from "./productdetailspopup";
import { timeDifference, includeToFixed } from "./../../utils/useFunc";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Helmet } from "react-helmet";
import Loader from "../Loader/loader";
import { t } from "i18next";
// import moment from 'moment';
// import { current } from '@reduxjs/toolkit';

const Boxdetails = () => {
  const history = useHistory();
  const { id: boxId } = queryString();
  const dispatch = useDispatch();
  const result = useSelector((state) => state.user);
  // const urlParams = new URLSearchParams(window.location.search);
  // let lee = document.getElementById(urlParams.get("tab"));
  const { adminSetting } = result || {};
  const { hideRollChance } = adminSetting || "";
  const {
    boxDetails,
    productBoxes,
    topItemsUnboxed,
    liveDrops,
    boxProductList,
  } = result || {};
  const { image, name, description } = boxDetails || {};
  const [show, setShow] = useState(false);
  const [showChance, setShowChance] = useState(false);
  const [particularProduct, setParticularProduct] = useState({});
  const [checkNewOne, setNewOne] = useState(false);
  const [checkStatus, setCheckStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (productBoxes.length > 0) {
      if (productBoxes.length > 2) {
        setState(true);
      } else {
        setState(false);
      }
    }
  }, [productBoxes]);

  // console.log("state", state);

  const handleClick = async (ee) => {
    // console.log("eeell", ee);
    setParticularProduct(ee);
    setShow(true);
    const id = ee?.pid?._id;
    dispatch(getBoxesByProductId(id));
  };
  useEffect(() => {
    getBox();
    dispatch(getTopItemsUnboxed(boxId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boxId, dispatch]);
  useEffect(() => {
    dispatch(getLiveDrops());
  }, [boxId, dispatch]);

  const getBox = async () => {
    const boxResult = await dispatch(getSingleBoxData(boxId));
    const {
      resultData: { data },
    } = boxResult?.payload;
    const { boxData, code } = data || {};
    if (code === 200) {
      setLoading(false);
    }
    if (boxData === null) {
      history.push("/");
    }
  };
  const handleClose = () => {
    setShow(!show);
  };
  useEffect(() => {
    socket.on("getLiveDrops", (data) => {
      setNewOne(true);
      dispatch(getLiveDrops());
      setTimeout(() => {
        setNewOne(false);
      }, 800);
    });
    socket.on("getBoxStatus", (data) => {
      setCheckStatus(true);
    });
  }, [dispatch]);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShow(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  return (
    <>
      {loading && <Loader />}
      <Helmet>
        <meta property="og:title" content={`${name} | BOXI`} />
        {name && <title>{`${name || ""} | BOXI`}</title>}
      </Helmet>
      <Layout>
        <>
          <div className="game-page">
            <section className="unboxing-main-section">
              <Roulette
                boxDetails={boxDetails}
                setShow={setShow}
                checkStatus={checkStatus}
              />
              <div className="container">
                <RecentBoxSlider
                  liveDropState={liveDrops}
                  checkNewOne={checkNewOne}
                />

                <div className="row">
                  <div className="col-lg-12">
                    <div className="contest-details-content">
                      <div className="contest-details-grid">
                        <div className="row align-items-center">
                          <div className="col-md-3 detail-main-img-section">
                            <div className="detail-main-img">
                              <img src={image || item1} alt="box img" />
                            </div>
                          </div>
                          <div className="col-md-9">
                            <div className="contest-details-meta">
                              <div className="">
                                <h2 className="contest-name">{name || ""}</h2>
                              </div>
                              <div className="">
                                <ul>
                                  <li>
                                    <p className="des">{description || ""}</p>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="contest-description row"
                        id="scroll-item-box"
                      >
                        <div
                          className={`col-lg-9 ${
                            localStorage.getItem("inboxi#@user")
                              ? ""
                              : "overlay-view"
                          }`}
                        >
                          {!localStorage.getItem("inboxi#@user") ? (
                            <div className="overlay-text">
                              {t("loginHeading")}
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="content-block">
                            <div className="heading-button">
                              <h3 className="title">
                                {t("boxDetails.itemsInBox")}
                              </h3>

                              <div className="chances-slider">
                                {hideRollChance && hideRollChance ? (
                                  <div className="chances">
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        onClick={() =>
                                          setShowChance(!showChance)
                                        }
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <label>{t("boxDetails.chanceTxt")}</label>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="row mb-none-30 item-box-col">
                              {boxDetails &&
                                boxProductList?.length > 0 &&
                                boxProductList?.map((el) => {
                                  const { rarityLevel } = el?.pid || {};
                                  const price = includeToFixed(el?.pid?.price);
                                  return (
                                    <>
                                      {el?.pid ? (
                                        <div className="col-xl-3 col-md-6 mb-30">
                                          <div
                                            className="contest-card item-card"
                                            style={{
                                              background: `linear-gradient(180deg, #041f2d 50%, ${rarityLevel?.color}40 100%)`,
                                              borderBottom: `2px solid ${rarityLevel?.color}90`,
                                            }}
                                            onClick={() => handleClick(el)}
                                          >
                                            <div>
                                              <div className="contest-card__thumb item-card__thumb">
                                                {showChance && (
                                                  <span
                                                    className="percentage"
                                                    style={{ color: "#e89c31" }}
                                                  >
                                                    {el.chance}%
                                                  </span>
                                                )}
                                                <img
                                                  src={
                                                    el?.pid?.bannerImage ||
                                                    item1
                                                  }
                                                  alt="box"
                                                />
                                              </div>
                                            </div>
                                            <div className="item-card__content">
                                              <div className="left">
                                                <h5 className="contest-card__name">
                                                  {el?.pid?.name || ""}
                                                </h5>
                                                <span className="item-price">
                                                  $ {price || "0"}
                                                </span>
                                              </div>
                                              <div className="right">
                                                <p className="item-category"></p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="content-block top-item-box">
                            <h3 className="title">
                              {t("boxDetails.itemsUnboxed")}
                            </h3>
                            <div className="contest-sidebar__cart">
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="row mb-none-30">
                                    {topItemsUnboxed?.length > 0 &&
                                      topItemsUnboxed.map(
                                        ({
                                          product,
                                          rarityLevel,
                                          date,
                                          boxData,
                                        }) => (
                                          <div className="col-6 mb-30">
                                            <div
                                              className="recent-item"
                                              style={{
                                                background: `linear-gradient(180deg, #041f2d 50%, ${rarityLevel?.color}40 100%)`,
                                                borderBottom: `2px solid ${rarityLevel?.color}90`,
                                              }}
                                            >
                                              <div className="icon-item-thumb">
                                                <img
                                                  className="initial-img"
                                                  src={product[0]?.bannerImage}
                                                  alt="box img"
                                                />
                                                <img
                                                  className="final-img"
                                                  src={boxData?.image}
                                                  alt="box img"
                                                />
                                              </div>
                                              <div className="recent-item-content">
                                                <p>
                                                  <i
                                                    className="fa fa-square yellow"
                                                    style={{
                                                      color: rarityLevel?.color,
                                                    }}
                                                  ></i>
                                                </p>
                                                <p className="time_min">
                                                  <span>
                                                    {timeDifference(
                                                      new Date(),
                                                      new Date(date)
                                                    )}
                                                  </span>{" "}
                                                  <br />$ {product[0]?.price}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <div
            className={`abc ${show ? "show-popup" : "hide-popup"}`}
            ref={wrapperRef}
          >
            {particularProduct?.pid?.name && (
              <ProductDetailsPopup
                handleClose={handleClose}
                item={particularProduct}
                productBoxes={productBoxes}
                state={state}
              />
            )}
          </div>
        </>
      </Layout>
    </>
  );
};

export default Boxdetails;

const RecentBoxSlider = ({ liveDropState, checkNewOne }) => {
  const ischeck = liveDropState?.length > 7 ? true : false;
  const settings = {
    dots: false,
    infinite: ischeck,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 8,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  const { t } = useTranslation();

  return (
    <>
      <div className="last-live-drops-section  wow animate__animated animate__slideInUp">
        <div className="content-block">
          <h3 className="title live-title">
            <div className="live-bg">
              <span className="live-icon"></span>
            </div>
            <div className="drop">{t("home.liveDrops")}</div>
          </h3>
          <Slider {...settings}>
            {liveDropState.map((el, index) => (
              <div>
                <div
                  className="recent-item"
                  style={{
                    background: `linear-gradient(180deg, #082b3d 50%, #80262840 100%)`,
                    borderBottomColor: "#80262890",
                  }}
                >
                  <Link>
                    <div className="recent-item-content">
                      <div className="live_bg">
                        <span className="live_icon"></span>{" "}
                        <span className="live">Live</span>
                      </div>
                      <p>
                        {timeDifference(new Date(), new Date(el?.createdAt))}
                      </p>
                    </div>
                    <div className="icon-item-thumb">
                      <div className="icon-item-img-section">
                        <div className="icon-item-thumb-img">
                          <img
                            src={el?.pid?.bannerImage}
                            className={`initial-img ${
                              index === 0 && checkNewOne
                                ? "wow animate__animated animate__slideInLeft"
                                : ""
                            }`}
                            alt="box img"
                          />
                          <img
                            className="final-img"
                            src={el?.boxId?.image}
                            alt="box img"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="price-name">
                      <span className="live_price">$ {el?.pid?.price} </span>
                      {el?.userId?.username && (
                        <span className="user_name">{`${el?.userId?.username.slice(
                          0,
                          2
                        )}****${el?.userId?.username.charAt(
                          el?.userId?.username.length - 1
                        )}`}</span>
                      )}
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
};
