/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import box1 from "../../assets/images/home/1.jpg";
import "./unboxing.css";
import { handleBoxPrice } from "../../utils/useFunc";

const Boxes = ({ boxes }) => {
  return (
    <>
      <div
        className="row wow fadeInUp"
        data-wow-duration="0.5s"
        data-wow-delay="0.3s"
      >
        <div className="col-lg-12">
          <div className="row mb-none-30">
            {boxes &&
              boxes.map((box, i) => {
                const { boxItems, image, profitMargin } = box || {};
                const prices = handleBoxPrice(boxItems, profitMargin);
                return (
                  <>
                    {parseFloat(prices) > 0 ? (
                      <div key={i} className="col-xl-3 col-md-4 mb-30">
                        <div className="contest-card">
                          <div className="contest-card__thumb">
                            <a href={`/boxdetails?id=${box._id}`}>
                              <img src={image || box1} alt="box" />
                            </a>

                            <div
                              className="contest-num"
                              style={{
                                background: `${box?.color}`,
                              }}
                            >
                              <h4 className="number">
                                {box?.specialTag || ""}
                              </h4>
                            </div>
                          </div>
                          <div className="contest-card__content">
                            <h5 className="contest-card__name">
                              {box.name || ""}
                            </h5>
                            <span className="contest-card__price">
                              $ {prices}
                            </span>
                          </div>
                          <div className="contest-card__footer">
                            <a
                              href={`/boxdetails?id=${box._id}`}
                              className="cmn-btn details-btn style--three btn--sm btn-style"
                            >
                              <span>Open Case</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Boxes;
