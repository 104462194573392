import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./login.css";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { yupLoginValidation } from "../../utils/index";
import { getCurrentOS } from "../../utils/useFunc";
import GoogleLogin from "react-google-login";
import { gapi } from "gapi-script";
import { toast } from "react-toastify";
import FacebookLogin from "react-facebook-login";
import { withoutAuth } from "../../config/axios";
import {
  addToCart,
  getLoginUser,
  getDistinctInventoryProducts,
  getCartProducts,
} from "./../../reducers/userReducer";
import { publicIpv4 } from "public-ip";
import { useTranslation } from "react-i18next";
import {
  detectBrowser,
  getDeviceType,
} from "../../utils/userDeviceInformation";
// import ReCAPTCHA from "react-google-recaptcha";
import { socket } from "../../config/socket";

const Login = ({ login, handleLogin, handleSignup, handleForget }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = localStorage.getItem("inboxi#@user");
  const cartData = token ? [] : JSON.parse(localStorage.getItem("addtoCarts"));
  const [checkedBox, setCheckedBox] = useState(false);
  // const siteKeyCapthca = '6LcEv30gAAAAAKtMiisIEoTU3FD5BKhEbMKwVme8'
  //Local  reCaptcha key ==> ;
  // const siteKeyCapthca = process.env.REACT_APP_SITE_KEY;
  // const [captchaValue, setCaptchaValue] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(yupLoginValidation()),
  });
  const onSubmitHandler = async (data) => {
    try {
      if (Object.keys(errors).length === 0) {
        const os = getCurrentOS();
        const navtest = navigator;
        const { platform } = navtest || {};
        data.email = data.email.toLowerCase();
        const browser = detectBrowser();
        const deviceType = getDeviceType();
        data.browser = browser;
        data.deviceType = deviceType;
        data.platform = platform;
        data.os = os;
        const ipAddress = await publicIpv4();
        data.ipAddress = ipAddress;

        const response = await withoutAuth().post("/api/auth/login", data);
        const { token, code, msg } = response.data;
        if (code === 200) {
          toast.success(msg, { toastId: "loginuser" });
          if (token) {
            socket.emit("join", token);
            localStorage.setItem("inboxi#@user", token);
            reset();
            handleLogin("change");
            if (cartData?.length > 0) {
              cartData.forEach((el) => {
                const payload = {
                  productId: el.productId._id,
                  productQuantity: el.productQuantity,
                };
                payload.type = "";
                dispatch(addToCart(payload));
              });
              localStorage.removeItem("addtoCarts");
            }
            dispatch(getLoginUser());
            dispatch(getCartProducts());
            dispatch(getDistinctInventoryProducts());
            setCheckedBox(false);
          }
        } else {
          toast.error(msg);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickCheck = () => {
    setCheckedBox(!checkedBox);
  };

  const [isRTL, setISRTL] = useState(localStorage.getItem("i18nextLng"));
  useEffect(() => {
    setISRTL(localStorage.getItem("i18nextLng"));
  }, []);
  // const handleCaptchaChange = (value) => {
  //   setCaptchaValue(value);
  // };
  const onSuccess = async (res) => {
    const { tokenId } = res || {};
    let payobj = {
      token: tokenId,
    };

    const respone = await withoutAuth().post("/api/auth/google-auth", payobj);
    const { code, msg, token } = respone?.data || {};
    if (code === 200) {
      // if (!isVerified) {
      //   return handleSignup("", userid);
      // }
      toast.success(t("toast.loginsuccess"), { toastId: "loginuser" });
      socket.emit("join", token);
      localStorage.setItem("inboxi#@user", token);
      handleLogin("change");
      if (cartData?.length > 0) {
        cartData.forEach((el) => {
          const payload = {
            productId: el.productId._id,
            productQuantity: el.productQuantity,
          };
          payload.type = "";
          dispatch(addToCart(payload));
        });
        localStorage.removeItem("addtoCarts");
      }
      dispatch(getLoginUser());
      dispatch(getCartProducts());
      dispatch(getDistinctInventoryProducts());
      setCheckedBox(false);
    }
    if (code === 402) {
      toast.error(msg, {
        toastId: "googleerror",
      });
    }
  };
  const onFailure = (err) => {
    console.log("failed glogin:", err);
  };

  const responseFacebook = async (response) => {
    const { accessToken } = response || {};
    if (accessToken) {
      const respone = await withoutAuth().post(
        "/api/auth/facebook-auth",
        response
      );
      const { code, token, isVerified, userid } = respone?.data || {};
      if (code === 200) {
        if (!isVerified) {
          return handleSignup("", userid);
        }
        toast.success(t("toast.loginsuccess"), { toastId: "loginuser" });
        socket.emit("join", token);
        localStorage.setItem("inboxi#@user", token);
        handleLogin("change");
        if (cartData?.length > 0) {
          cartData.forEach((el) => {
            const payload = {
              productId: el.productId._id,
              productQuantity: el.productQuantity,
            };
            payload.type = "";
            dispatch(addToCart(payload));
          });
          localStorage.removeItem("addtoCarts");
        }
        dispatch(getLoginUser());
        dispatch(getCartProducts());
        dispatch(getDistinctInventoryProducts());
        setCheckedBox(false);
      }
    }
  };
  const handleClick = (data) => {
    console.log("fail facebokk", data);
  };

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId:
          "937476515925-uirndo5j93ies8muk7qmql24918enkj1.apps.googleusercontent.com",
        scope: "",
      });
    };
    gapi.load("client:auth2", initClient);
  });
  return (
    <Modal
      show={login}
      onHide={() => handleLogin("change")}
      centered
      className={`modal fade login-popup registration-popup ${
        isRTL === "ar" ? " wrapper rtl" : "wrapper"
      }`}
      id="loginModal"
      tabindex="1"
      role="dialog"
      aria-hidden="true">
      {/* <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable"> */}
      <div className="modal-content loginpage">
        <div className="modal-body">
          <div className="account-form-area">
            <button
              onClick={() => handleLogin("change")}
              type="button"
              className="close-btn"
              data-dismiss="modal"
              aria-label="Close">
              <i className="las la-times"></i>
            </button>
            <h3 className="ar-login-title title">
              {t("loginForm.wlcmHeading")}
            </h3>
            <div className="account-form-wrapper">
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div className="form-group">
                  <label>
                    {t("loginForm.lableTxt1")} <sup>*</sup>
                  </label>
                  <input
                    {...register("email")}
                    className="form-control"
                    placeholder={t("regForm.lableTxt3")}
                    type="text"
                  />
                </div>
                <div className="form-group">
                  <label>
                    {t("loginForm.lableTxt2")} <sup>*</sup>
                  </label>
                  <input
                    {...register("password")}
                    className="form-control"
                    placeholder={t("loginForm.lableTxt2")}
                    type="password"
                  />
                </div>
                <div className="d-flex flex-wrap justify-content-between mt-2">
                  <div className="custom-checkbox-section">
                    <input
                      type="checkbox"
                      name="id-1"
                      id="id-1"
                      checked={checkedBox}
                      onClick={handleClickCheck}
                    />
                    <label for="id-1" classNameName="custom-checkbox-label">
                      {t("loginForm.rembTxt")}
                    </label>
                    <span className="checkbox"></span>
                  </div>
                  <Link onClick={() => handleForget()}>
                    {t("loginForm.forgotTxt")}
                  </Link>
                  {/* <a href="#0" className="link">
                    {t("loginForm.forgotTxt")} 
                  </a> */}
                </div>
                {/* <div className='recaptcha-section mt-4'>
          
                  <ReCAPTCHA
                    theme='dark'
                    sitekey={siteKeyCapthca}
                    onChange={handleCaptchaChange}
                  />
                </div> */}
                <div className="form-group text-center mt-0">
                  <button type="submit" className="cmn-btn btn-style">
                    <span>{t("loginForm.btnTxt")}</span>
                  </button>
                </div>
              </form>
              <p className="text-center mt-4">
                {t("loginForm.accTxt")}{" "}
                <Link
                  // to="/?modal=SignUp"
                  onClick={() => handleSignup()}
                  data-toggle="modal"
                  data-target="#signupModal"
                  // onClick={handleSignup}
                >
                  {/* {' '} */}
                  {t("loginForm.signupTxt")}
                </Link>
              </p>
              <div className="divider">
                <span>{t("loginForm.orTxt")}</span>
              </div>
              <ul className="social-link-list">
                {!localStorage.getItem("inboxi#@user") && (
                  <GoogleLogin
                    clientId="357172866227-oaqn6oeo6idk4n7vksd07r3epggau5rh.apps.googleusercontent.com"
                    buttonText=""
                    onSuccess={onSuccess}
                    onFailure={onFailure}
                    cookiePolicy={"single_host_origin"}
                  />
                )}
                {!localStorage.getItem("inboxi#@user") && (
                  <FacebookLogin
                    appId="762320081701248"
                    // autoLoad={true}
                    fields="name,email,picture"
                    callback={responseFacebook}
                    onClick={handleClick}
                    cssClass="my-facebook-button-class"
                    icon="fa fa-facebook"
                    textButton=""
                  />
                )}
              </ul>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </Modal>
  );
};

export default Login;
