import React from "react";
import Layout from "../layout/layout";
import { Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { isEmailInValid } from "../../utils/validation";
import "./contactus.css";
import { toast } from "react-toastify";
import { withoutAuth } from "./../../config/axios";
import phoneIcon from "../../assets/images/contact/phoneIcon.png";
import emailIcon from "../../assets/images/contact/emailIcon.png";
import { Link } from "react-router-dom";
import { useState } from "react";

const ContactUs = () => {
  let initialContact = {
    name: "",
    email: "",
    telephone: "",
    message: "",
  };

  const { t } = useTranslation();
  const [contact, setContact] = useState(initialContact);
  const [errors, setErrors] = useState({ email: "" });
  const handlleContactInput = (e) => {
    const { name, value } = e.target;
    // console.log(name, " : ", value);
    setContact({ ...contact, [name]: value });
  };

  const handleSubmitBtn = async (e) => {
    console.log(
      "hi am in handle submit button ",
      contact.name,
      contact.email,
      contact.message
    );
    e.preventDefault();

    let isInValidE = isEmailInValid(contact.email, t);
    console.log("this is my isValidE ", isInValidE);
    if (isInValidE) {
      setErrors(isInValidE);
      return;
    } else {
      let payload = {
        name: contact.name,
        email: contact.email,
        telephone: contact.telephone,
        message: contact.message,
      };
      setErrors({});
      const res = await withoutAuth().post("/api/admin/contactUser", payload);
      console.log("this is my response ", res);
      const { code, msg } = res.data;
      if (code === 200) {
        toast(msg);
      } else {
        toast(msg);
      }
      setContact(initialContact);
    }
  };

  return (
    <Layout>
      <div className="contactus-page">
        <div className="faq-page">
          <div className="faq-page-topsection">
            <div className="container">
              <div className="contact-page-title faq-page-title">
                <h2>{t("footer.contact")}</h2>
                <p>{t("contact.heading")}</p>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="contactus-wrapper">
              <div className="contactus-form-body">
                <div className="contactus-form-left">
                  <div className="contactus-form-title">
                    <p>{t("contact.haveQue")}</p>
                    <h3>{t("contact.dropMsg")}</h3>
                  </div>
                  <div className="contactus-form">
                    <Form>
                      <Form.Group className="mb-3">
                        <Form.Label>{t("proShipDetails.placeTxt1")}</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="Enter name"
                          value={contact?.name}
                          onChange={handlleContactInput}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>{t("regForm.lableTxt3")}</Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          value={contact?.email}
                          onChange={handlleContactInput}
                          placeholder="Enter email"
                        />
                        {errors.email && (
                          <p className="error-msg">{errors?.email}</p>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>{t("regForm.lableTxt5")}</Form.Label>
                        <Form.Control
                          type="text"
                          name="telephone"
                          value={contact?.telephone}
                          onChange={handlleContactInput}
                          placeholder="Enter telephone"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>{t("regForm.msgTxt")}</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={1}
                          name="message"
                          value={contact?.message}
                          onChange={handlleContactInput}
                          placeholder="Enter message"
                        />
                      </Form.Group>
                      <div className="contactus-btn">
                        <Button className="btn-style" onClick={handleSubmitBtn}>
                          <span>{t("regForm.sndMsgTxt")}</span>
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
                <div className="contactus-form-right">
                  <div className="contactus-right-wrapper">
                    <div className="contactus-details">
                      <div className="contactus-info">
                        <img src={phoneIcon} alt="" />
                        <a href="tel:+216 52 11 88 88">
                          <span>+216 52 11 88 88</span>
                        </a>
                      </div>
                      <div className="contactus-info">
                        <img src={emailIcon} alt="" />
                        <a href="mailto:contact@cilycase.com">
                          <span>contact@cilycase.com</span>
                        </a>
                      </div>
                      <hr />
                      <p>
                        {t("contact.fromFaq")}{" "}
                        <Link to="/faq">{t("contact.fromFaq1")} </Link>{" "}
                        {t("contact.fromFaq2")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="contactus-footer"></div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ContactUs;
