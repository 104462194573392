import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import battle from "../../assets/images/home/1.jpg";
import { useTranslation } from "react-i18next";
import player1 from "../../assets/images/user/user-icon.png";
import { getBattlesHistory } from "../../reducers/userReducer";
// import {Product5} from '../../../services/script.js'
import "./battles.css";
import { includeToFixed } from "../../utils/useFunc";

const BattleHistoryTable = () => {
  const dispatch = useDispatch();
  const result = useSelector((state) => state.user);
  const { t } = useTranslation();
  const { allBattleHistory, userDetails } = result || {};
  const userId = userDetails?._id;
  useEffect(() => {
    dispatch(getBattlesHistory(userId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <Table className="table battle-table" responsive>
      <thead>
        <tr>
          <th>{t("battleTable.tabletxt1")}</th>
          <th>{t("battleTable.tabletxt2")}</th>
          <th>{t("battleTable.tabletxt3")}</th>
          <th>{t("battleTable.tabletxt4")}</th>
          <th>{t("battleTable.tabletxt5")}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {allBattleHistory.map((el) => {
          const testArr = el?.joinUser
            .slice()
            .sort((a, b) => a.position - b.position);
          return (
            <tr>
              <td className={el?.status === "running" ? "circle-active" : ""}>
                <div className="cirlce-section">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    class=""
                    viewBox="0 1 448 515"
                  >
                    <path d="M223.554,8.667 7.048,133.667 7.048,383.667 223.554,508.667 440.061,383.667 440.061,133.667z"></path>
                  </svg>
                  <span>{el.round}</span>
                  <p>{el?.status === "running" ? "Running" : ""}</p>
                </div>
              </td>

              <td>
                <div className="battle-slider-section">
                  <div className="battle-slider">
                    {el.selectedBoxes.map((ele, index) => (
                      <div className="battle-img">
                        <img src={ele.image} alt="battle" />
                        <span>{ele.boxName}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </td>

              <td>$ {includeToFixed(el.totalbattleCost)}</td>
              <td>
                <div className="players-section">
                  <h6 className="no-of-players">1/{el?.players}</h6>
                  <div className="players-img">
                    <>
                      {testArr &&
                        testArr?.map((el2) => (
                          <img
                            src={el2?.userId?.profile || player1}
                            alt="player1"
                          />
                        ))}
                    </>
                    <>
                      {[
                        ...Array(parseInt(el?.players - el?.joinUser.length)),
                      ].map((elementInArray, index) => (
                        <img src={player1} alt="player1" />
                      ))}
                    </>
                  </div>
                </div>
              </td>
              <td>
                <Link
                  to={`/watch-battle/?id=${el.joinLink}`}
                  className="cmn-btn"
                >
                  Watch Battle
                </Link>
              </td>
            </tr>
          );
        })}
        {/* <tr>
          <td>
            <div className="cirlce-section">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                class=""
                viewBox="0 1 448 515"
              >
                <path d="M223.554,8.667 7.048,133.667 7.048,383.667 223.554,508.667 440.061,383.667 440.061,133.667z"></path>
              </svg>
              <span>2</span>
            </div>
          </td>
          <td>
            <div className="battle-slider">
              <div className="battle-img">
                <img src={battle} alt="battle" />
                <span>Milspec</span>
              </div>
              <div className="battle-img">
                <img src={battle} alt="battle" />
                <span>Milspec</span>
              </div>
            </div>
          </td>
          <td>10.09 $</td>
          <td>
            <div className="players-section">
              <h6 className="no-of-players">1/4</h6>
              <div className="players-img">
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
              </div>
            </div>
          </td>
          <td>
            <Link to="/watch-battle" className="cmn-btn">
              Watch Battle
            </Link>
          </td>
        </tr>
        <tr>
          <td className="circle-active">
            <div className="cirlce-section">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                class=""
                viewBox="0 1 448 515"
              >
                <path d="M223.554,8.667 7.048,133.667 7.048,383.667 223.554,508.667 440.061,383.667 440.061,133.667z"></path>
              </svg>
              <span>3</span>
            </div>
            <p>Running</p>
          </td>
          <td>
            <div className="battle-slider-section">
              <div className="battle-slider">
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
              </div>
            </div>
          </td>
          <td>10.09 $</td>
          <td>
            <div className="players-section">
              <h6 className="no-of-players">1/4</h6>
              <div className="players-img">
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
              </div>
            </div>
          </td>
          <td>
            <Link to="/watch-battle" className="cmn-btn">
              Watch Battle
            </Link>
          </td>
        </tr>
        <tr>
          <td>
            <div className="cirlce-section">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                class=""
                viewBox="0 1 448 515"
              >
                <path d="M223.554,8.667 7.048,133.667 7.048,383.667 223.554,508.667 440.061,383.667 440.061,133.667z"></path>
              </svg>
              <span>1</span>
            </div>
          </td>
          <td>
            <div className="battle-img">
              <img src={battle} alt="battle" />
              <span>Milspec</span>
            </div>
          </td>
          <td>10.09 $</td>
          <td>
            <div className="players-section">
              <h6 className="no-of-players">1/4</h6>
              <div className="players-img">
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
              </div>
            </div>
          </td>
          <td>
            <Link to="/watch-battle" className="cmn-btn">
              Watch Battle
            </Link>
          </td>
        </tr>
        <tr>
          <td>
            <div className="cirlce-section">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                class=""
                viewBox="0 1 448 515"
              >
                <path d="M223.554,8.667 7.048,133.667 7.048,383.667 223.554,508.667 440.061,383.667 440.061,133.667z"></path>
              </svg>
              <span>2</span>
            </div>
          </td>
          <td>
            <div className="battle-slider">
              <div className="battle-img">
                <img src={battle} alt="battle" />
                <span>Milspec</span>
              </div>
              <div className="battle-img">
                <img src={battle} alt="battle" />
                <span>Milspec</span>
              </div>
            </div>
          </td>
          <td>10.09 $</td>
          <td>
            <div className="players-section">
              <h6 className="no-of-players">1/4</h6>
              <div className="players-img">
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
              </div>
            </div>
          </td>
          <td>
            <Link to="/watch-battle" className="cmn-btn">
              Watch Battle
            </Link>
          </td>
        </tr>
        <tr>
          <td className="circle-active">
            <div className="cirlce-section">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                class=""
                viewBox="0 1 448 515"
              >
                <path d="M223.554,8.667 7.048,133.667 7.048,383.667 223.554,508.667 440.061,383.667 440.061,133.667z"></path>
              </svg>
              <span>3</span>
            </div>
            <p>Running</p>
          </td>
          <td>
            <div className="battle-slider-section">
              <div className="battle-slider">
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
              </div>
            </div>
          </td>
          <td>10.09 $</td>
          <td>
            <div className="players-section">
              <h6 className="no-of-players">1/4</h6>
              <div className="players-img">
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
              </div>
            </div>
          </td>
          <td>
            <Link to="/watch-battle" className="cmn-btn">
              Watch Battle
            </Link>
          </td>
        </tr>
        <tr>
          <td>
            <div className="cirlce-section">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                class=""
                viewBox="0 1 448 515"
              >
                <path d="M223.554,8.667 7.048,133.667 7.048,383.667 223.554,508.667 440.061,383.667 440.061,133.667z"></path>
              </svg>
              <span>1</span>
            </div>
          </td>
          <td>
            <div className="battle-img">
              <img src={battle} alt="battle" />
              <span>Milspec</span>
            </div>
          </td>
          <td>10.09 $</td>
          <td>
            <div className="players-section">
              <h6 className="no-of-players">1/4</h6>
              <div className="players-img">
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
              </div>
            </div>
          </td>
          <td>
            <Link to="/watch-battle" className="cmn-btn">
              Watch Battle
            </Link>
          </td>
        </tr>
        <tr>
          <td>
            <div className="cirlce-section">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                class=""
                viewBox="0 1 448 515"
              >
                <path d="M223.554,8.667 7.048,133.667 7.048,383.667 223.554,508.667 440.061,383.667 440.061,133.667z"></path>
              </svg>
              <span>2</span>
            </div>
          </td>
          <td>
            <div className="battle-slider">
              <div className="battle-img">
                <img src={battle} alt="battle" />
                <span>Milspec</span>
              </div>
              <div className="battle-img">
                <img src={battle} alt="battle" />
                <span>Milspec</span>
              </div>
            </div>
          </td>
          <td>10.09 $</td>
          <td>
            <div className="players-section">
              <h6 className="no-of-players">1/4</h6>
              <div className="players-img">
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
              </div>
            </div>
          </td>
          <td>
            <Link to="/watch-battle" className="cmn-btn">
              Watch Battle
            </Link>
          </td>
        </tr>
        <tr>
          <td className="circle-active">
            <div className="cirlce-section">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                class=""
                viewBox="0 1 448 515"
              >
                <path d="M223.554,8.667 7.048,133.667 7.048,383.667 223.554,508.667 440.061,383.667 440.061,133.667z"></path>
              </svg>
              <span>3</span>
            </div>
            <p>Running</p>
          </td>
          <td>
            <div className="battle-slider-section">
              <div className="battle-slider">
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
                <div className="battle-img">
                  <img src={battle} alt="battle" />
                  <span>Milspec</span>
                </div>
              </div>
            </div>
          </td>
          <td>10.09 $</td>
          <td>
            <div className="players-section">
              <h6 className="no-of-players">1/4</h6>
              <div className="players-img">
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
                <img src={player1} alt="player1" />
              </div>
            </div>
          </td>
          <td>
            <Link to="/watch-battle" className="cmn-btn">
              Watch Battle
            </Link>
          </td>
        </tr> */}
      </tbody>
    </Table>
  );
};

export default BattleHistoryTable;
