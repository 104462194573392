// import React from 'react'
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { handleBoxPrice } from "../../utils/useFunc";
import { useTranslation } from "react-i18next";

const BoxAddedPopUp = ({ onCloseModal, open, handleConfirm, addBoxes }) => {
  // const dispatch = useDispatch()
  const result = useSelector((state) => state.user);
  const { boxesList } = result || {};
  const { t } = useTranslation();

  return (
    <Modal
      show={open}
      size="xl"
      centered
      onHide={() => onCloseModal("change")}
      className={`modal fade login-popup`}
      id="loginModal"
      tabindex="1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-content modal_contents boxadd-model-page">
        <div class="modal-body">
          <div className="modal_header">
            <div className="case_add">
              <h4>
                {t("form.caseTxt")} <span className="case">$ 00 </span>{" "}
              </h4>
            </div>
            <div className="search">
              {/* <i class="las la-search"></i> */}
              <input placeholder={t("form.lookUpTxt")} type="search" />
            </div>
            <div className="close_pup_btn">
              <button
                onClick={() => onCloseModal("change")}
                type="button"
                className="close-btn"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i class="las la-times"></i>
              </button>
            </div>
          </div>
          <div class="account-form-area">
            <div class="form-group text-center">
              <div
                className="row wow fadeInUp"
                data-wow-duration="0.5s"
                data-wow-delay="0.3s"
              >
                <div className="col-lg-12">
                  <div className="row mb-none-30">
                    {boxesList &&
                      boxesList.map((box, i) => {
                        const { boxItems, image, profitMargin, boxBattle } =
                          box || {};
                        const boxPrices = handleBoxPrice(
                          boxItems,
                          profitMargin
                        );
                        return (
                          <>
                            {parseFloat(boxPrices) > 0 &&
                            (boxBattle === "undefined" ||
                              boxBattle === true) ? (
                              <div
                                key={i}
                                className="col-md-6 col-lg-3 p-1 all_battle_card"
                              >
                                <div className="contest-card battle_card">
                                  <div className="contest-card__thumb">
                                    <img src={image} alt="box" />

                                    {/* <a href="#0" className="action-icon">
                                  <i className="far fa-heart"></i>
                                </a> */}
                                  </div>
                                  <div className="contest-card__content">
                                    <div className="left">
                                      <h5 className="contest-card__name">
                                        {box.name || ""}
                                      </h5>
                                    </div>
                                    <div className="right">
                                      <span className="contest-card__price">
                                        $ {boxPrices}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="add_box">
                                    <button
                                      onClick={() =>
                                        addBoxes(box, profitMargin)
                                      }
                                      className="cmn-btn details-btn style--three btn--sm"
                                    >
                                      <i class="fas fa-box-open"></i> Add Box
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal_footer">
            <div className="done_btn">
              <button className="cmn-btn" onClick={onCloseModal}>
                {t("form.doneTxt")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default BoxAddedPopUp;
