import React, { useState, useRef, useEffect } from "react";
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import "react-tabs/style/react-tabs.css";
import "../unboxing/unboxing.css";
// import Boxes from './Boxes'
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllInventoryProducts,
  getInventoryProducts,
  getRarity,
} from "../../reducers/userReducer";
// import {handleBoxPrice} from '../../utils/useFunc'

const InventoryFilterComp = ({ setSeachPayload, searchPayload }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const result = useSelector((state) => state.user);
  const { allRarity } = result;
  const [niceselect, setniceselect] = useState(true);
  const niceselecthandle = () => setniceselect(!niceselect);
  const [orderdropdown, setorderdropdown] = useState(true);
  // const [niceselect,setNiceSelect] = useState(true)
  const orderdropdownhandle = () => setorderdropdown(!orderdropdown);
  // const [rangeSelect,setRangeSelect] = useState(true)
  // const rangeSelectHandle = () => setRangeSelect(!rangeSelect)
  // const niceselecthandle = () => setNiceSelect(!niceselect)
  const wrapperRef = useRef(null);

  // const [copyPayload,setCopyPayload] = useState([]);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setorderdropdown(true);
          setniceselect(true);
          // setRangeSelect(true)
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const handleSearchBox = async (name, value, nextValue) => {
    const copySearchPayLoad = { ...searchPayload };
    if (name === "sort" && nextValue) {
      copySearchPayLoad["sortType"] = nextValue;
    }
    copySearchPayLoad[name] = value;
    copySearchPayLoad["searchBy"] = name;
    delete copySearchPayLoad.newType;
    setSeachPayload(copySearchPayLoad);
    dispatch(getInventoryProducts(copySearchPayLoad));
    dispatch(getAllInventoryProducts(copySearchPayLoad));
  };

  useEffect(() => {
    dispatch(getInventoryProducts(searchPayload));
    dispatch(getAllInventoryProducts(searchPayload));
    dispatch(getRarity());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(()=>{
  //   const copy = boxListByCategory.map((el)=>({...el}));
  //   copy.forEach((el)=>el.boxPrice = handleBoxPrice(el.boxItems,el.profitMargin))
  //   // setCopyPayload(copy)
  // },[boxListByCategory])

  // console.log('allRarity', allRarity)

  return (
    <>
      {" "}
      <div className="filter-tabs-section">
        <div className="unboxing-header unboxing__header"></div>

        <div
          className="row contest-filter-wrapper mt-30 mb-none-30"
          ref={wrapperRef}
        >
          <div className="col-lg-4 col-sm-6 mb-30">
            <div
              className={`${
                orderdropdown
                  ? "order-dropdown-list"
                  : "order-dropdown-list open"
              }`}
              onClick={orderdropdownhandle}
            >
              <span className="current">
                {searchPayload?.sortType !== "All"
                  ? searchPayload?.sortType
                  :  t("openBoxes.sortTxt1")}
              </span>
              <ul className="list">
                <li
                  data-value="Filter option"
                  className="option"
                  onClick={() =>
                    handleSearchBox("sort", { createdAt: -1 }, "All")
                  }
                >
                  {t("openBoxes.sortTxt9")}
                </li>
                <li
                  data-value="Filter option"
                  className="option"
                  onClick={() =>
                    handleSearchBox("sort", { createdAt: -1 }, "Newest")
                  }
                >
                  {t("openBoxes.sortTxt10")}
                </li>
                <li
                  data-value="Filter option"
                  className="option"
                  onClick={() =>
                    handleSearchBox("sort", { createdAt: 1 }, "Oldest")
                  }
                >
                  {t("openBoxes.sortTxt11")}
                </li>
                <li
                  data-value="Filter option"
                  className="option"
                  onClick={() =>
                    handleSearchBox("sort", { price: 1 }, "Price (Low to high)")
                  }
                >
                  {t("openBoxes.sortTxt4")}
                </li>
                <li
                  data-value="Filter option"
                  className="option"
                  onClick={() =>
                    handleSearchBox(
                      "sort",
                      { price: -1 },
                      "Price (High to Low)"
                    )
                  }
                >
                  {t("openBoxes.sortTxt5")}
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6 mb-30">
            <div
              className={`${niceselect ? "nice-select" : "nice-select open"}`}
              onClick={niceselecthandle}
            >
              <span className="current">
                {searchPayload?.rarity
                  ? searchPayload?.rarity
                  : t("openBoxes.sortTxt12")}
              </span>
              <ul className="list">
                <li
                  data-value="Filter  option"
                  className="option"
                  onClick={() => handleSearchBox("rarity", "")}
                >
                  All
                </li>
                {allRarity?.length > 0 &&
                  allRarity.map(
                    (el) =>
                      el?.name && (
                        <li
                          data-value="Filter  option"
                          className="option"
                          onClick={() => handleSearchBox("rarity", el.name)}
                        >
                          {el.name}
                        </li>
                      )
                  )}
              </ul>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6 mb-30">
            <div className="contest-search-form">
              <input
                type="text"
                name="title"
                onChange={(e) => handleSearchBox(e.target.name, e.target.value)}
                placeholder={t("openBoxes.searchTxt")}
              />
              <button>
                <i className="fa fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default InventoryFilterComp;
