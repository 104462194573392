import React, {  useState } from 'react'
//import { userInstance } from '../../config/axios';
// import { queryString } from '../../utils/useFunc'
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import fail from "../../assets/images/depositpopup/cross.png"
import "./paymentPages.css";
import { Modal } from 'react-bootstrap';
import close from '../../assets/images/game/close.png';

const FailurePage = () => {

  // const { payment_ref } = queryString();
  const [show, setShow] = useState();

  // useEffect(() => {
  //   async function failurePayment() {
  //     if (payment_ref) {
  //       //await userInstance().get(`api/user/failurePayment/${payment_ref}`);
  //       // console.log('response==>>>', response)
  //     }
  //   }
  //   failurePayment();
  // }, [payment_ref])

  const handlePopup = () => {
    setShow(!show);
  }
  return (
    <div className="payment-page bg-new-all">

      <div className="payment-page-content">
        <div className="payment-image">
          <img src={fail} alt="check" />
        </div>
        <div className="payment-title">
          <h2>There has been an error</h2>
          <h3>
            While processing your payment, please change your payment method,
            or try again later. You can check your transactions by
            clicking <Link to="profile?tab=transactions"> here</Link>
          </h3>
        </div>
      </div>
      {/* <Button onClick={handlePopup}>For modal click here</Button> */}
      <Modal show={show} onHide={handlePopup} className="payment-status-popup" centered size="lg">
        <Modal.Header>
          <div className='payment-close-btn' onClick={handlePopup}>
            <img src={close} alt="close" />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="payment-status-content">
            <div className="payment-image">
              <img src={fail} alt="check" />
            </div>
            <div className="payment-title">
              <h2>
                There has been an error
              </h2>
              <h3>
                While processing your payment, please change your payment method,
                or try again later. You can check your transactions by
                clicking <Link to="profile?tab=transactions"> here </Link>
              </h3>

            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>

  )
}

export default FailurePage