import React from "react";
import { Modal, Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const FairnessPopUp = ({
  setFairnessOpen,
  boxItems,
  clientSeed,
  genarateNewSeed,
  handleNewSeed,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={true}
      size="xl"
      centered
      onHide={() => setFairnessOpen(false)}
      className={`modal fade login-popup`}
      id="loginModal"
      tabindex="1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-content modal_contents boxadd-model-page fairness-info-popup">
        <div className="modal-body">
          <div className="modal_header">
            <div className="case_add">
              <h4>{t("fairness.title")}</h4>
            </div>
            <div className="close_pup_btn">
              <button
                onClick={() => setFairnessOpen(false)}
                type="button"
                className="close-btn"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i class="las la-times"></i>
              </button>
            </div>
          </div>
          <div className="account-form-area">
            <div className="form-group text-center">
              <div
                className="row wow fadeInUp"
                data-wow-duration="0.5s"
                data-wow-delay="0.3s"
              ></div>
            </div>

            <Tabs defaultActiveKey="howitworks" id="uncontrolled-tab-example">
              <Tab eventKey="howitworks" title={t("fairness.tab1")}>
                {/* <div className="form-group text-center">
                  <div
                    className="row wow fadeInUp"
                    data-wow-duration="0.5s"
                    data-wow-delay="0.3s"
                  >
                    <div className="col-lg-12">
                      <h1 className="section-title">
                        <span className="section-title-text">
                          {t("header.howitWorks")}
                        </span>
                      </h1>
                    </div>
                  </div>
                </div> */}
                <div className="how-works">
                  <div className="col-lg-12">
                    <h3 className="text-danger">{t("form.proFairTxt")}</h3>
                    <br />
                    <div className="fairmodalbady">
                      {t("fairness.howdesc1")}&nbsp;
                      <Link target="_blank"> {t("fairness.howdesc2")}</Link>
                      <br />
                      {t("fairness.howdesc3")}
                      <br />
                      {t("fairness.howdesc4")}
                      <br />
                      <br />
                      <h4>
                        <b>{t("fairness.title2")}</b>
                      </h4>
                      <br />
                      {t("fairness.howdesc5")}
                      <br />
                      {t("fairness.howdesc6")}
                      <br />
                      {t("fairness.howdesc7")}
                      <br />
                      {t("fairness.howdesc8")}
                      <br />
                      {t("fairness.howdesc9")}
                      <br />
                      <br />
                      <h4>
                        <b>{t("fairness.title3")}</b>
                      </h4>
                      {t("fairness.howdesc10")}
                      <br />
                      {t("fairness.howdesc11")}
                      <br />
                      <br />
                      <b>{t("fairness.title5")}</b>&nbsp;-
                      {t("fairness.howdesc12")}
                      <br />
                      {t("fairness.howdesc13")}
                      <br />
                      {t("fairness.howdesc14")}
                      <br />
                      <br />
                      <b>{t("fairness.title6")}</b>&nbsp;-
                      {t("fairness.howdesc15")}
                      <br />
                      {t("fairness.howdesc16")}
                      <br />
                      {t("fairness.howdesc17")}
                      <br />
                      <br />
                      <b>{t("fairness.title7")}</b>&nbsp;-{" "}
                      {t("fairness.howdesc18")}
                      <br />
                      {t("fairness.howdesc19")}
                      <br />
                      {t("fairness.howdesc20")}
                      <br />
                      <br />
                      <b>{t("fairness.title8")}</b>&nbsp;-{" "}
                      {t("fairness.howdesc21")}
                      <br />
                      {t("fairness.howdesc22")}
                      <br />
                      <br />
                      <b>{t("fairness.title9")}</b>&nbsp;-{" "}
                      {t("fairness.howdesc23")}
                      <br />
                      {t("fairness.howdesc24")}
                      <br />
                      <br />
                      <b>{t("fairness.title10")}</b>&nbsp;-{" "}
                      {t("fairness.howdesc25")}
                      <br />
                      <br />
                      <b>{t("fairness.title11")}</b>&nbsp;-{" "}
                      {t("fairness.howdesc26")}
                      <br />
                      <br />
                      {/* <div className="fairness-code">
                        hash_hmac(‘sha512’, $clientSeeds.‘-’.$nonce, $beacon);
                      </div> */}
                      <b>{t("fairness.title12")}</b>&nbsp;-{" "}
                      {t("fairness.howdesc26")}
                      <br />
                      {/* <div className="fairness-code">
                        $hex = substr($hash, 0, 7);
                        <br />
                        $number = hexdec($hex);
                        <br />
                        $roll = $number % 100000 + 1;
                      </div> */}
                      <br />
                      <h4>
                        <b>{t("fairness.title4")}</b>
                      </h4>
                      <br />
                      {t("fairness.howdesc27")}
                      <br />
                      {t("fairness.howdesc28")}
                      <br />
                      {t("fairness.howdesc29")}{" "}
                      <span>{t("fairness.howdesc30")}</span>
                      {t("fairness.howdesc31")}
                    </div>
                  </div>
                </div>
                {/* <div className='text-center'>
                  <div className='col-lg-12'>
                    <h1 className='section-title'>
                      <span className='section-title-text'>VERIFY</span>
                    </h1>
                  </div>
                </div>
                <div className='fairmodalbody-verify'>
                  <ul className='varify-modal'>
                    <li>
                      <div className='dd-list__item-title'>
                        <span className='fair-title-arrow-left'></span>
                        <span className='dd-list__item-text'>
                          Open the battle you want to join
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className='dd-list__item-title'>
                        <span className='fair-title-arrow-left'></span>
                        <span className='dd-list__item-text'>
                          Click on Verify Ticket or open&nbsp;
                          <a
                            href='/'
                            target='_blank'
                            rel='nofollow noopener noreferrer'>
                            https://api.random.org/tickets/form
                          </a>
                          &nbsp;and enter Ticket ID. You should get message “—
                          The randomization will be available after the ticket
                          has been used —” That means that ticket ID has not
                          been initiated yet.
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className='dd-list__item-title'>
                        <span className='fair-title-arrow-left'></span>
                        <span className='dd-list__item-text'>
                          Check all the client seeds. A user can change his
                          client seed for the battle only before he joins it.
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className='dd-list__item-title'>
                        <span className='fair-title-arrow-left'></span>
                        <span className='dd-list__item-text'>
                          Join the battle or wait for other users to join your
                          battle.
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className='dd-list__item-title'>
                        <span className='fair-title-arrow-left'></span>
                        <span className='dd-list__item-text'>
                          When the battle started, check start timestamp.
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className='dd-list__item-title'>
                        <span className='fair-title-arrow-left'></span>
                        <span className='dd-list__item-text'>
                          Wait until the battle has finished.
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className='dd-list__item-title'>
                        <span className='fair-title-arrow-left'></span>
                        <span className='dd-list__item-text'>
                          Open Fairness modal
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className='dd-list__item-title'>
                        <span className='fair-title-arrow-left'></span>
                        <span className='dd-list__item-text'>
                          Click "Verify Ticket" or open&nbsp;
                          <a href='/' target='_blank'>
                            https://api.random.org/tickets/form
                          </a>
                          &nbsp;and enter Ticket ID
                          <div>
                            <a href="/images/78ee27ab4be8160f3ad9c10ab090aa41.jpg" target="_blank" rel="nofollow noopener noreferrer">
                                    <img src="/images/1f059eb059348f31390f1c20c278d4d4.jpg" class="_2QAey1RvRNXIfcD_cSrx3o" alt="Randomorg verifier">
                                </a>
                            <ul>
                              <li>
                                <div className='dd-list__item-title'>
                                  <span className='fair-title-arrow-left'></span>
                                  <span className='dd-list__item-text'>
                                    a. Ticket ID should match.
                                  </span>
                                </div>
                              </li>
                              <li>
                                <div className='dd-list__item-title'>
                                  <span className='fair-title-arrow-left'></span>
                                  <span className='dd-list__item-text'>
                                    b. "Used" timestamp should be later than
                                    battle start timestamp.
                                  </span>
                                </div>
                              </li>
                              <li>
                                <div className='dd-list__item-title'>
                                  <span className='fair-title-arrow-left'></span>
                                  <span>
                                    c. To check the beacon (ticket output) click
                                    "View Full Results" button.
                                  </span>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div>
                            <a href="/images/ae547d209dca785cea6fea00c78f0a9b.jpg" target="_blank" rel="nofollow noopener noreferrer">
                                    <img src="/images/34971cb70cd06af2b78f391ff3d2ef19.jpg" class="_2QAey1RvRNXIfcD_cSrx3o" alt="Randomorg verifier">
                                </a>
                            <ul>
                              <li>
                                <div className='dd-list__item-title'>
                                  <span class="fair-title-arrow-left"></span>
                                  <span>
                                    d. The value in the "Random Values
                                    Generated" field should be equal the beacon.
                                  </span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className='dd-list__item-title'>
                        <span className='fair-title-arrow-left'></span>
                        <span className='dd-list__item-text'>
                          Keep in mind that tickets expire in 1 month, which
                          means that ticket validation is available within one
                          month.
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className='dd-list__item-title'>
                        <span className='fair-title-arrow-left'></span>
                        <span className='dd-list__item-text'>
                          Now you can verify battle rolls
                        </span>
                      </div>
                    </li>
                  </ul>
                  To verify battle rolls:
                  <ul className='varify-modal'>
                    <li>
                      <div className='dd-list__item-title'>
                        <span className='fair-title-arrow-left'></span>
                        <span className='dd-list__item-text'>
                          Click the “Fairness” button on the battle page
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className='dd-list__item-title'>
                        <span className='fair-title-arrow-left'></span>
                        <span className='dd-list__item-text'>
                          Check nonce for each drop
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className='dd-list__item-title'>
                        <span className='fair-title-arrow-left'></span>
                        <span className='dd-list__item-text'>
                          Go to Verify tab. You can change nonce and check roll
                          number
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className='dd-list__item-title'>
                        <span className='fair-title-arrow-left'></span>
                        <span className='dd-list__item-text'>
                          You can also use this&nbsp;
                          <a href='/' target='_blank'>
                            code snippet
                          </a>
                          &nbsp;to check roll number by yourself.
                        </span>
                      </div>
                    </li>
                  </ul>
                </div> */}
              </Tab>

              <Tab eventKey="verify" title={t("fairness.tab2")}>
                <table className="table_info table__info">
                  <thead className="table_head">
                    <tr>
                      <th className="table_row">{t("openBoxes.typeTxt")}</th>
                      <th className="table_row">{t("form.valueTxt")}</th>
                    </tr>
                  </thead>
                  <tbody className="table_body table__body">
                    <tr className="info_row">
                      <td className="table_data">{t("form.seedTxt2")}</td>
                      <td className="table_data">
                        <input
                          type="text"
                          value={clientSeed}
                          onChange={(e) => handleNewSeed(e.target.value)}
                        />
                        <span
                          onClick={() => genarateNewSeed()}
                          className="genrate-seeds"
                        >
                          <i class="fa fa-refresh" aria-hidden="true"></i>{" "}
                          {t("form.seedTxt4")}{" "}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Tab>

              <Tab eventKey="oddsrange" title={t("fairness.tab3")}>
                <table className="table_info table__info odds_range">
                  <thead className="table_head">
                    <tr>
                      <th className="table_row">{t("boxDetails.sellTxt2")}</th>
                      <th className="table_row">{t("form.rangeTxt")}</th>
                      <th className="table_row">{t("form.oddTxt")}</th>
                    </tr>
                  </thead>
                  <tbody className="table_body table__body">
                    {boxItems &&
                      boxItems.map((el) => {
                        return (
                          <tr className="info_row">
                            <td className="table_data">{el?.pid?.name}</td>
                            <td className="table_data">{el?.range}</td>
                            <td className="table_data">{el?.chance} %</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default FairnessPopUp;
