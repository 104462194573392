import React from "react";
// import LightGlowImg from "../../assets/images/elements/light2.png";
// import box from '../../assets/images/game/10gaming.png'

const ShowPlaceHolder = ({ displayProduct }) => {
  return (
    <div className="roulette-placeholder wow animate__animated animate__fadeIn">
      <div className="placeholder-section">
      <div className="roulette-placeholder-box">
        <img src={displayProduct} alt="" className="" />
        {/* <img src={LightGlowImg} alt="" className="light-glow" /> */}
      </div>
      </div>
    </div>
  );
};
export default ShowPlaceHolder;
