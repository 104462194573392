import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { Link } from "react-router-dom";
// import { timeDifference } from "../../utils/useFunc";
import { useDispatch, useSelector } from "react-redux";
import { getLiveDrops } from "../../reducers/userReducer";

const RecentBoxSlider = () => {

  const result = useSelector((state) => state.user);
  const { liveDrops } = result || {};
  const ischeck = liveDrops?.length > 7 ? true : false;
  const settings = {
    dots: false,
    infinite: ischeck,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 6,
    mobileFirst: true,
    arrows: false,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLiveDrops());
  }, [dispatch]);

  // useEffect(() => {
  //   socket.on("getLiveDrops", (data) => {
  //     setNewOne(true);
  //     dispatch(getLiveDrops());
  //     setTimeout(() => {
  //       setNewOne(false);
  //     }, 800);
  //   });
  // }, [dispatch]);
  
console.log("liveDrops==>",liveDrops);

  return (
    <>
      <div className="last-live-drops-section  wow animate__animated1 animate__slideInUp1">
        <div className="content-block new-content-block">
          <h3 className="title live-title">
            <div className="drop">
              {/* {t("home.liveDrops")} */}
              LIVE DROP
            </div>
            <div className="live-bg">
              <span className="live-icon"></span>
            </div>
          </h3>
          <Slider {...settings}>
            {liveDrops &&
              liveDrops?.length > 0 &&
              liveDrops?.map((el, index) => (
                <div>
                  <div>
                    <div class="live-drop-items" bis_skin_checked="1">
                      <div className="live_drop_bg">
                        <span className="live_icon"></span>{" "}
                        {/* <span className="live">{t("home.liveTxt")}</span> */}
                      </div>
                      <div class="rarity-shadow" bis_skin_checked="1">
                      </div>
                      <div class="custom-card-cover " bis_skin_checked="1">
                        <img
                          src={el?.pid?.bannerImage}
                          className={`initial-img
                            
                            `}
                          alt="box img"
                        />
                        <img
                          className="final-img"
                          src={el?.boxId?.image}
                          alt="box img"
                        />
                      </div>
                      <div className="live-drop-content">
                        <p>Dragon</p>
                        <span>Bike Ride</span>
                      </div>
                    </div>
                  </div>
                  {/* <div
                    className="recent-item"
                    style={{
                      background: `linear-gradient(180deg, #082b3d 50%, #80262840 100%)`,
                      borderBottomColor: "#80262890",
                    }}
                  >
                    <Link>
                      <div className="recent-item-content">
                        <div className="live_bg">
                          <span className="live_icon"></span>{" "}
                          <span className="live">{t("home.liveTxt")}</span>
                        </div>
                        <p>
                          {timeDifference(new Date(), new Date(el?.createdAt))}
                        </p>
                      </div>
                      <div className="icon-item-thumb">
                        <div className="icon-item-img-section">
                          <div className="icon-item-thumb-img">
                            <img
                              src={el?.pid?.bannerImage}
                              className={`initial-img ${
                                index === 0 && checkNewOne
                                  ? "wow animate__animated animate__slideInLeft"
                                  : ""
                              }`}
                              alt="box img"
                            />
                            <img
                              className="final-img"
                              src={el?.boxId?.image}
                              alt="box img"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="price-name">
                        <span className="live_price">$ {el?.pid?.price}</span>
                        {el?.userId?.username && (
                          <span className="user_name">{`${el?.userId?.username.slice(
                            0,
                            2
                          )}****${el?.userId?.username.charAt(
                            el?.userId?.username.length - 1
                          )}`}</span>
                        )}
                      </div>
                    </Link>
                  </div> */}
                </div>
              ))}
          </Slider>
        </div>
      </div>
    </>
  );
};
export default RecentBoxSlider;
