import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { propTypes } from 'react-bootstrap/esm/Image';

const InfoPopup = ({ show, handleClose, selectedBlockchain, subtitle }) => {
	return (
		<Modal
			centered
			className="login-popup info-popup"
			show={show}
			onHide={handleClose}
		>
			<Modal.Header closeButton>
				<Modal.Title>{selectedBlockchain}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="assets-listing-section">
					<p>
						{subtitle ?? (
							<>
								This is a {selectedBlockchain} network address. Do not send{' '}
								{selectedBlockchain} over any other network to this address or
								your funds may be lost. Do not send any other coin to this
								address except {selectedBlockchain}.
							</>
						)}
					</p>
					<div className="edit-button btn-style">
						<Button onClick={handleClose}><span>Close</span></Button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};
InfoPopup.propTypes = {
	show: propTypes.boolean,
	handleClose: propTypes.function,
	selectedBlockchain: propTypes.string,
	subtitle: propTypes.string,
};

export default InfoPopup;
