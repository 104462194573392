import React, { useEffect, useState } from 'react'
import { userInstance } from '../../config/axios';
import { queryString } from '../../utils/useFunc'
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import success from "../../assets/images/depositpopup/check.png"
import "./paymentPages.css";
import { Modal } from 'react-bootstrap';
import close from '../../assets/images/game/close.png';
import { useDispatch } from 'react-redux';
import { getLoginUser,
 } from './../../reducers/userReducer';
 import { socket } from "../../config/socket";

const SuccessPage = () => {
  const dispatch=  useDispatch();
  const { payment_ref } = queryString();
  const [show, setShow] = useState();

  useEffect(() => {
    async function successPayment() {
      if (payment_ref) {
        const response = await userInstance().get(`api/user/successPayment/${payment_ref}`);
        const { code } = response.data || {}
        if(code === 200){
          dispatch(getLoginUser())
        }
        
      }
    }
    successPayment();
  }, [dispatch, payment_ref])
  const handlePopup = () => {
    setShow(!show);
  }

  useEffect(() => {
    socket.on("updateRealTimeUserData", (data) => {
      dispatch(getLoginUser());
    });
  }, [dispatch]);
  

  return (
    <div className="payment-page bg-new-all">

      <div className="payment-page-content">
        <div className="payment-image">
          <img src={success} alt="check" />
        </div>
        <div className="payment-title">
          <h2>
            Thank you for your payment!
          </h2>
          <h3>
            Your balance has been topped up successfully.
            You can check your transactions by clicking <Link to="profile?tab=transactions"> here</Link>
          </h3>

        </div>
      </div>
      {/* <Button onClick={handlePopup}>For modal click here</Button> */}
      <Modal show={show} onHide={handlePopup} className="payment-status-popup" centered size='lg'>
        <Modal.Header>
          <div className='payment-close-btn' onClick={handlePopup}>
            <img src={close} alt="close" />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="payment-status-content">
            <div className="payment-image">
              <img src={success} alt="check" />
            </div>
            <div className="payment-title">
              <h2>
                Thank you for your payment!
              </h2>
              <h3>
                Your balance has been topped up successfully.
                You can check your transactions by clicking <Link to="profile?tab=transactions"> here</Link>
              </h3>

            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default SuccessPage