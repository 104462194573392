import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import "./register.css";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  registrationValidation,
  validateStepRegister,
} from "../../utils/validation";
import { toast } from "react-toastify";
import { withoutAuth } from "./../../config/axios";
import {
  getCartProducts,
  getDistinctInventoryProducts,
  getLoginUser,
  addToCart,
} from "../../reducers/userReducer";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import GoogleLogin from "react-google-login";
import { gapi } from "gapi-script";
import FacebookLogin from "react-facebook-login";
import { socket } from "../../config/socket";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

// const governoratesData = [
//   "Ariana",
//   "Béja",
//   "Ben Arous",
//   "Bizerte",
//   "Gabès",
//   "Gafsa",
//   "Jendouba",
//   "Kairouan",
//   "Kasserine",
//   "Kebili",
//   "Kef",
//   "Mahdia",
//   "Manouba",
//   "Medenine",
//   "Monastir",
//   "Nabeul",
//   "Sfax",
//   "Sidi Bouzid",
//   "Siliana",
//   "Sousse",
//   "Tataouine",
//   "Tozeur",
//   "Tunis",
//   "Zaghouan",
// ];

const Register = ({
  signup,
  handleSignup,
  handleLogin,
  refUserId,
  isOtpOpen,
  isUser,
}) => {
  const [acceptCheckAge, setAcceptCheckAge] = useState(false);
  const [acceptCheckTerms, setAcceptCheckTerms] = useState(false);
  const initialState = {
    username: "",
    email: "",
    password: "",
    cpassword: "",
    firstname: "",
    lastname: "",
    mobile: "",
    // governorate: "",
    dateOfBirth: "",
    shippingAddress: [],
  };
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [stepOne, setStepOne] = useState(true);
  const [steptwo, setSteptwo] = useState(false);
  const [showSubmit, setShowSubmit] = useState(false);
  const [verify, setVerify] = useState(false);
  const [errors, setErrors] = useState({});
  const [payload, setPayload] = useState(initialState);
  const [password, setPassword] = useState(false);
  const [cpassword, setCpassword] = useState(false);
  const [captchaValue, setCaptchaValue] = useState("");
  const [userId, setUserId] = useState("");
  const [otp, setOtp] = useState("");
  const [isRTL, setISRTL] = useState(localStorage.getItem("i18nextLng"));
  const [ischeckResend, setischeckResend] = useState(false);
  const [countryCode, setCountryCode] = useState(null);
  const [isSocial, setIsSocial] = useState(false);
  let seconds = 60;
  const token = localStorage.getItem("inboxi#@user");
  const cartData = token ? [] : JSON.parse(localStorage.getItem("addtoCarts"));
  useEffect(() => {
    setISRTL(localStorage.getItem("i18nextLng"));
    if (isOtpOpen) {
      setUserId(isUser);
      setIsSocial(true);
      setShowSubmit(true);
      setStep(3);
    }
  }, [isOtpOpen, isUser]);

  const handleStepOne = () => {
    setStepOne(false);
    setSteptwo(true);
  };

  const [accceptCheckMessage, setAccceptCheckMessage] = useState(false);
  const handleAccept = (e) => {
    if (e?.target?.name === "age") {
      setAcceptCheckAge(!acceptCheckAge);
    } else if (e?.target?.name === "terms") {
      setAcceptCheckTerms(!acceptCheckTerms);
    }
    setAccceptCheckMessage(!accceptCheckMessage);
  };
  //  const handleCheckBox=async()=>{
  //    if(!acceptCheckAge  ){
  //     return  toast.error("Please Accept Age verify ")
  //    }else if(!acceptCheckTerms){
  //     return  toast.error("Please Accept Terms & Conditions  ")
  //    }
  //    else{
  //     goToStep(step+1)
  //    }
  //  }
  // console.log("Step==>",step)
  const goToStep = async (s) => {
    const validData = await validateStepRegister(payload, s - 1, t);
    setErrors(validData.errors);
    if (!validData.isValid) {
      return;
    }
    if (Object.keys(validData.errors).length > 0) {
      return;
    }
    // if (s === 3 && (!acceptCheckTerms || !acceptCheckAge)) {
    //   const msgCheck = !acceptCheckTerms
    //     ? t("toast.termcondition")
    //     : t("toast.verifyAge");
    //   return toast.error(msgCheck);
    // }
    setStep(s);
    if (s === 3) {
      setShowSubmit(true);
    }
  };

  const handleSteptwo = () => {
    setSteptwo(false);
  };

  const handleResendOTP = async (e, type) => {
    try {
      e.preventDefault();
      if (!captchaValue) {
        return toast.error(t("toast.captchaerror"), {
          toastId: "VerifyCaptcha",
        });
      }
      if (type !== "resend") {
        const validData = await validateStepRegister(payload, 3, t);
        setErrors(validData.errors);
        if (!validData.isValid) {
          return;
        }
      }
      const res = await withoutAuth().post("/api/auth/resendOTP", {
        userId: userId,
        mobile: payload.mobile,
      });
      const { id, code } = res?.data;
      if (code === 200) setUserId(id);
      seconds = 60;
      handleTimeCounter();
      if (stepOne) {
        handleStepOne();
      } else if (steptwo) {
        handleSteptwo();
      }
      setVerify(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSteps = async (e) => {
    e.preventDefault();
    if (!captchaValue) {
      return toast.error(t("toast.captchaerror"), {
        toastId: "VerifyCaptcha",
      });
    }
    const validData = await validateStepRegister(payload, 3, t);
    setErrors(validData.errors);
    if (!validData.isValid) {
      return;
    }
    if (!validData.isValid) {
      return;
    }
    if (Object.keys(validData.errors).length > 0) {
      return;
    }
    if (refUserId) {
      payload.referalBy = refUserId;
    }
    if (isSocial) {
      return handleResendOTP(e, "resend");
    }
    payload.isSocial = isSocial;
    payload.userId = userId;
    if (!acceptCheckTerms || !acceptCheckAge) {
      const msgCheck = !acceptCheckTerms
        ? t("toast.termcondition")
        : t("toast.verifyAge");
      return toast.error(msgCheck);
    }
    const res = await withoutAuth().post("/api/auth/register/user", payload);
    const { id, code, msg } = res?.data;
    if (code === 200) {
      setUserId(id);
      handleTimeCounter();
      if (stepOne) {
        handleStepOne();
      } else if (steptwo) {
        handleSteptwo();
      }
      setVerify(true);
    } else {
      toast.error(msg);
    }
  };
  const handleTimeCounter = () => {
    tick();
  };
  function tick() {
    const counter = document.getElementById("counter");
    seconds--;
    counter.innerHTML = "0:" + (seconds < 10 ? "0" : "") + String(seconds);
    if (seconds > 0) {
      document.getElementById("counter").style.display = "block";
      setischeckResend(false);
      setTimeout(tick, 1000);
    } else {
      document.getElementById("counter").style.display = "none";
      setischeckResend(true);
    }
  }

  console.log("payLoad===>", payload);

  const handleVerify = async () => {
    if (userId && otp) {
      const payload = {
        id: userId,
        otp: otp,
        isMessageRecieve: accceptCheckMessage,
      };
      const res = await withoutAuth().post("/api/auth/verifyOtp", payload);
      const { code, token } = res?.data;
      if (code === 200) {
        localStorage.setItem("inboxi#@user", token);
        dispatch(getLoginUser());
        dispatch(getCartProducts());
        dispatch(getDistinctInventoryProducts());
        toast.success(t("toast.registersuccess"), {
          toastId: "registerSuccess",
        });
      }
      if (code === 401) {
        toast.error(t("toast.invalidOTP"), {
          toastId: "VerifyOtps",
        });
      }
    } else {
      toast.error(t("toast.otperror"), {
        toastId: "VerifyOtps",
      });
    }
    setVerify(true);
  };

  const handleOtpChange = (e) => {
    // console.log("esd===>", e)
    const { value } = e.target;
    setOtp(value);
  };

  const handleValidation = async (val, key) => {
    const msg = await registrationValidation(val, key, t);
    let copyError = { ...errors, ...msg };
    setErrors(copyError);
  };

  const handleOnchange = (e) => {
    const { name, value } = e.target;
    setPayload({ ...payload, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  const handlePassword = (type) => {
    if (type === "password") {
      setPassword(!password);
    } else {
      setCpassword(!cpassword);
    }
  };
  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleDateChange = (name) => (val) => {
    setPayload({ ...payload, [name]: val });
    setErrors({ ...errors, [name]: "" });
  };

  const onSuccess = async (res) => {
    const { tokenId } = res || {};
    let payobj = {
      token: tokenId,
    };
    const respone = await withoutAuth().post("/api/auth/google-auth", payobj);
    const { code, msg, userid, isVerified, token } = respone?.data || {};
    if (code === 200) {
      if (!isVerified) {
        setUserId(userid);
        setIsSocial(true);
        setShowSubmit(true);
        setStep(3);
        return;
      }
      toast.success(t("toast.loginsuccess"), { toastId: "loginuser" });
      socket.emit("join", token);
      localStorage.setItem("inboxi#@user", token);
      handleLogin("change");
      if (cartData?.length > 0) {
        cartData.forEach((el) => {
          const payload = {
            productId: el.productId._id,
            productQuantity: el.productQuantity,
          };
          payload.type = "";
          dispatch(addToCart(payload));
        });
        localStorage.removeItem("addtoCarts");
      }
      dispatch(getLoginUser());
      dispatch(getCartProducts());
      dispatch(getDistinctInventoryProducts());
    }
    if (code === 402) {
      toast.error(msg, {
        toastId: "googleerror",
      });
    }
  };
  const onFailure = (err) => {
    console.log("failed glogin:", err);
  };
  const handleClick = (data) => {
    console.log("fail facebokk", data);
  };
  const responseFacebook = async (response) => {
    const { accessToken } = response || {};
    if (accessToken) {
      const respone = await withoutAuth().post(
        "/api/auth/facebook-auth",
        response
      );
      const { code, userid, isVerified, token } = respone?.data || {};

      if (code === 200) {
        if (!isVerified) {
          setUserId(userid);
          setIsSocial(true);
          setShowSubmit(true);
          setStep(3);
          return;
        }
        toast.success(t("toast.loginsuccess"), { toastId: "loginuser" });
        socket.emit("join", token);
        localStorage.setItem("inboxi#@user", token);
        handleLogin("change");
        if (cartData?.length > 0) {
          cartData.forEach((el) => {
            const payload = {
              productId: el.productId._id,
              productQuantity: el.productQuantity,
            };
            payload.type = "";
            dispatch(addToCart(payload));
          });
          localStorage.removeItem("addtoCarts");
        }
        dispatch(getLoginUser());
        dispatch(getCartProducts());
        dispatch(getDistinctInventoryProducts());
      }
    }
  };
  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId:
          "937476515925-uirndo5j93ies8muk7qmql24918enkj1.apps.googleusercontent.com",
        scope: "",
      });
    };
    gapi.load("client:auth2", initClient);
  });

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          fetch(
            `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${position.coords.latitude}&longitude=${position.coords.longitude}&localityLanguage=en`
          )
            .then((response) => response.json())
            .then((data) => {
              setCountryCode(data.countryCode);
            })
            .catch((error) => {
              console.error("Error fetching country code: ", error);
            });
        },
        (error) => {
          console.error("Error fetching location: ", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);
  const props = {
    handleValidation,
    errors,
    handleOnchange,
    handleVerify,
    verify,
    setVerify,
    handlePassword,
    password,
    cpassword,
    // governoratesData,
    handleOtpChange,
    handleCaptchaChange,
    goToStep,
    step,
    handleSteps,
    handleResendOTP,
    handleAccept,
    acceptCheckAge,
    acceptCheckTerms,
    accceptCheckMessage,
    seconds,
    ischeckResend,
    payload,
    handleDateChange,
    countryCode,
  };
  return (
    <Modal
      show={signup}
      centered
      onHide={() => handleSignup("change")}
      className={`modal fade login-popup registration-popup ${
        isRTL === "ar" ? " wrapper rtl" : "wrapper"
      }`}
      id="loginModal"
      tabindex="1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-content">
        <div className="modal-body registerpage">
          <div className="account-form-area">
            <button
              onClick={() => handleSignup("change")}
              type="button"
              className="close-btn"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i className="las la-times"></i>
            </button>
            <h3 className="ar-reg-title title">{t("regForm.accHeading")}</h3>
            <div className="steps-section">
              <div className={`step-one ${step > 1 ? "complete-step" : ""}`}>
                {/* <span>1</span> */}
                <span> {step > 1 ? <i className="las la-check"></i> : 1}</span>
              </div>
              <div className={`step-two ${step > 2 ? "complete-step" : ""}`}>
                <span>{step > 2 ? <i className="las la-check"></i> : 2}</span>
              </div>
              <div className="step-three">
                <span>{step > 3 ? <i className="las la-check"></i> : 3}</span>
              </div>
            </div>
            <div className="account-form-wrapper">
              <form>
                {step === 1 ? (
                  <StepOne {...props} />
                ) : step === 2 ? (
                  <StepTwo {...props} />
                ) : step === 3 ? (
                  <StepThree {...props} />
                ) : (
                  ""
                )}
                <div id="counter"></div>
                {!verify && (
                  <div className="form-group text-center">
                    {showSubmit ? (
                      <button
                        type="button"
                        className="cmn-btn btn-style"
                        onClick={userId ? handleResendOTP : handleSteps}
                      >
                        <span>
                          {" "}
                          {verify
                            ? t("form.submitTxt")
                            : "Send Otp in to Email"}
                        </span>
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="cmn-btn btn-style"
                        //onClick={(step===2)?handleCheckBox:()=> goToStep(step + 1) }
                        onClick={() => goToStep(step + 1)}
                      >
                        <span>{t("form.nxtTxt")}</span>
                      </button>
                    )}
                  </div>
                )}
              </form>
              <p className="text-center mt-3">
                {" "}
                {t("regForm.alreadyTxt")}{" "}
                <a href="#0" data-target="#loginModal" onClick={handleLogin}>
                  {t("regForm.loginTxt")}
                </a>
              </p>
              {step === 1 && (
                <>
                  <div className="divider">
                    <span>{t("regForm.orTxt")}</span>
                  </div>
                  {/* <ul className="social-link-list">
                    <li>
                      <a href="#0">
                        <i className="fa fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#0">
                        <i className="fa fa-twitter" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#0">
                        <i className="fa fa-google-plus" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul> */}
                  <ul className="social-link-list">
                    {!localStorage.getItem("inboxi#@user") && (
                      <GoogleLogin
                        // clientId="937476515925-uirndo5j93ies8muk7qmql24918enkj1.apps.googleusercontent.com"
                        clientId="937476515925-uirndo5j93ies8muk7qmql24918enkj1.apps.googleusercontent.com"
                        buttonText=""
                        onSuccess={onSuccess}
                        onFailure={onFailure}
                        cookiePolicy={"single_host_origin"}
                      />
                    )}
                    {!localStorage.getItem("inboxi#@user") && (
                      <FacebookLogin
                        appId="859704661600275"
                        // autoLoad={true}
                        fields="name,email,picture"
                        callback={responseFacebook}
                        onClick={handleClick}
                        cssClass="my-facebook-button-class"
                        icon="fa fa-facebook"
                        textButton=""
                      />
                    )}
                  </ul>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Register;

const StepOne = ({
  handleValidation,
  errors,
  handleOnchange,
  handlePassword,
  password,
  cpassword,
  payload,
  countryCode,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="form-group">
        <label>
          {t("regForm.lableTxt4")} <sup>*</sup>
        </label>
        <input
          className="form-control"
          name="username"
          placeholder={t("regForm.lableTxt4")}
          type="text"
          onBlur={(e) => handleValidation(e.target.value, "username")}
          onChange={handleOnchange}
        />
        {errors.username && (
          <p className="error-msg">
            {errors.key === "isRequired"
              ? t("errors.isRequired")
              : errors.key === "isUserExists"
              ? t("errors.isUserExists")
              : ""}
          </p>
        )}
      </div>
      <div className="form-group">
        <label>
          {t("form.phnNoTxt")} <sup>*</sup>
        </label>
        {/* <input
          className="form-control"
          placeholder={t("form.phnNoTxt")}
          type="text"
          name="mobile"
          value={payload?.mobile}
          onBlur={(e) => handleValidation(e.target.value, "mobile")}
          onChange={handleOnchange}
        /> */}

        <PhoneInput
          country={countryCode?.toLowerCase()}
          enableSearch={true}
          name="mobile"
          value={payload?.mobile}
          onChange={(value, country) => {
            handleOnchange({ target: { name: "mobile", value } });
          }}
          onBlur={(e) => {
            const { value } = e.target;
            handleValidation(value, "mobile");
          }}
          containerClass="phone-input"
        />
        {errors.mobile && <p className="error-msg">{errors.mobile}</p>}
      </div>

      <div className="form-group">
        <label>
          {t("regForm.lableTxt6")} <sup>*</sup>
        </label>
        <input
          className="form-control"
          placeholder={t("regForm.lableTxt6")}
          name="password"
          type={password ? "text" : "password"}
          onBlur={(e) => handleValidation(e.target.value, "password")}
          onChange={handleOnchange}
        />
        <span className="eye-icon" onClick={() => handlePassword("password")}>
          {password ? (
            <i class="fa fa-eye" aria-hidden="true"></i>
          ) : (
            <i class="fa fa-eye-slash" aria-hidden="true"></i>
          )}
        </span>
        <p className="password-msg">{t("form.warnTxt1")}</p>
      </div>
      <div className="form-group">
        <label>
          Confrim Password <sup>*</sup>
        </label>
        <input
          className="form-control"
          placeholder="Confrim Password"
          name="cpassword"
          type={cpassword ? "text" : "password"}
          onBlur={(e) => handleValidation(e.target.value, "cpassword")}
          onChange={handleOnchange}
        />
        <span className="eye-icon" onClick={() => handlePassword("cpassword")}>
          {cpassword ? (
            <i class="fa fa-eye" aria-hidden="true"></i>
          ) : (
            <i class="fa fa-eye-slash" aria-hidden="true"></i>
          )}
        </span>
        {errors.cpassword && <p className="error-msg">{errors.cpassword}</p>}
      </div>
    </>
  );
};

const StepTwo = ({
  handleValidation,
  errors,
  handleOnchange,
  handleAccept,
  acceptCheckAge,
  acceptCheckTerms,
  handleDateChange,
  payload,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="form-group">
        <label>
          {t("regForm.lableTxt1")} <sup>*</sup>
        </label>
        <input
          className="form-control"
          placeholder={t("regForm.lableTxt1")}
          name="firstname"
          type="text"
          onBlur={(e) => handleValidation(e.target.value, "firstname")}
          onChange={handleOnchange}
        />
        {errors.firstname && <p className="error-msg">{errors.firstname}</p>}
      </div>
      <div className="form-group">
        <label>
          {t("regForm.lableTxt2")} <sup>*</sup>
        </label>
        <input
          className="form-control"
          placeholder={t("regForm.lableTxt2")}
          name="lastname"
          type="text"
          onBlur={(e) => handleValidation(e.target.value, "lastname")}
          onChange={handleOnchange}
        />
        {errors.lastname && <p className="error-msg">{errors.lastname}</p>}
      </div>

      <div className="form-group">
        <label>
          {t("profile.lableTxt3")} <sup>*</sup>
        </label>
        {/* <input className="form-control"  placeholder="Second Name" name="dateOfBirth" type="date" onChange={handleOnchange} /> */}
        <DatePicker
          selected={payload.dateOfBirth}
          onChange={handleDateChange("dateOfBirth")}
          onBlur={(e) => handleValidation(e.target.value, "dateOfBirth")}
          maxDate={new Date()}
          placeholderText="JJ/MM/YYYY"
          dateFormat="d/MM/yyyy"
          showYearDropdown={true}
          dropdownMode="select"
        />

        {errors.dateOfBirth && (
          <p className="error-msg">{errors.dateOfBirth}</p>
        )}
      </div>
      {/* <div className="form-group">
        <label>
          Governorate <sup>*</sup>
        </label>
        <select
          name="governorate"
          onBlur={(e) => handleValidation(e.target.value, "governorate")}
          onChange={handleOnchange}
        >
          <option value="">--{t("form.selectLabel")}--</option>
          {governoratesData.map((el) => (
            <option value={el}>{el}</option>
          ))}
        </select>
        {errors.governorate && (
          <p className="error-msg">{errors.governorate}</p>
        )}
      </div> */}
      {/* <div className="privacy-policy">
        <Form.Group controlId="formBasicCheckbox">
          <Form.Check
            type="checkbox"
            name="age"
            checked={acceptCheckAge}
            onClick={handleAccept}
            id="hghshdsgh"
          />
          <label>{t("form.warnTxt2")}</label>
          {errors.age && <p className="error-msg">{errors.age}</p>}
        </Form.Group>
      </div>
      <div className="privacy-policy">
        <Form.Group controlId="formBasicCheckbox">
          <Form.Check
            type="checkbox"
            name="terms"
            checked={acceptCheckTerms}
            onClick={handleAccept}
            id="hghshdsgh"
          />
          <label>
            {t("regForm.agreeTxt")}{" "}
            <Link to="/agreement">{t("form.privacyTxt3")}</Link>{" "}
            {t("form.andTxt")}{" "}
            <Link to="/privacy">{t("form.privacyTxt4")}</Link>
          </label>
          {errors.terms && <p className="error-msg">{errors.terms}</p>}
        </Form.Group>
      </div> */}
    </>
  );
};

const StepThree = ({
  handleCaptchaChange,
  handleVerify,
  verify,
  handleValidation,
  errors,
  handleOnchange,
  handleOtpChange,
  setVerify,
  handleResendOTP,
  accceptCheckMessage,
  handleAccept,
  ischeckResend,
  seconds,
  payload,
  acceptCheckAge,
  acceptCheckTerms,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {!verify && (
        <div className="form-group">
          <label>
            {t("profile.lableTxt5")} <sup>*</sup>
          </label>
          <input
            className="form-control"
            placeholder={t("profile.lableTxt5")}
            name="email"
            type="text"
            onBlur={(e) => handleValidation(e.target.value, "email")}
            onChange={handleOnchange}
          />
          {errors.email && <p className="error-msg">{errors.email}</p>}
        </div>
        // <div className="form-group">
        //   <label>
        //     {t("form.phnNoTxt")} <sup>*</sup>
        //   </label>
        //   <input
        //     className="form-control"
        //     placeholder={t("form.phnNoTxt")}
        //     type="text"
        //     name="mobile"
        //     value={payload?.mobile}
        //     onBlur={(e) => handleValidation(e.target.value, "mobile")}
        //     onChange={handleOnchange}
        //   />
        //   {errors.mobile && <p className="error-msg">{errors.mobile}</p>}
        // </div>
      )}
      {verify ? (
        <div className="form-group">
          <label>
            {t("form.otpTxt2")} <sup>*</sup>
          </label>
          <input
            className="form-control"
            placeholder={t("form.verifyTxt")}
            type="text"
            onChange={handleOtpChange}
          />
        </div>
      ) : (
        ""
      )}
      <div className={`send-otp ${verify ? "verify-otp" : ""}`}>
        {/* {verify && ischeckResend ? (
          <p onClick={(e) => handleResendOTP(e, "resend")}>
            {t("form.otpTxt3")}{" "}
          </p>
        ) : (
          verify && <p>{t("form.otpTxt3")}</p>
        )}
        {verify && ischeckResend ? (
          <p onClick={() => setVerify(false)}>{t("form.chngPhnTxt3")} </p>
        ) : (
          verify && <p>{t("form.chngPhnTxt3")}</p>
        )} */}
        {verify && (
          <Button onClick={handleVerify} className="btn-style mt-4">
            <span>{t("form.otpTxt2")}</span>
          </Button>
        )}
      </div>
      <div className="recaptcha-section mt-4">
        {!verify && (
          <ReCAPTCHA
            theme="light"
            // sitekey="6LeDMxkfAAAAAEhBNa8zcj4DuuPbyfIWuwG2uAqO"
            sitekey="6Lf2HREqAAAAAHHnQMev5cXDHKeLS1Qt3vwzDLtp"
            onChange={handleCaptchaChange}
          />
        )}
      </div>
      {!verify && (
        // <div className="privacy-policy">
        //   <Form.Group controlId="formBasicCheckbox">
        //     <Form.Check
        //       type="checkbox"
        //       name="message"
        //       checked={accceptCheckMessage}
        //       onClick={handleAccept}
        //     />
        //     <label>{t("form.infoTxt")}</label>
        //   </Form.Group>
        // </div>

        <>
          <div className="privacy-policy">
            <Form.Group controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                name="age"
                checked={acceptCheckAge}
                onClick={handleAccept}
                id="hghshdsgh"
              />
              <label>{t("form.warnTxt2")}</label>
              {errors.age && <p className="error-msg">{errors.age}</p>}
            </Form.Group>
          </div>
          <div className="privacy-policy">
            <Form.Group controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                name="terms"
                checked={acceptCheckTerms}
                onClick={handleAccept}
                id="hghshdsgh"
              />
              <label>
                {t("regForm.agreeTxt")}{" "}
                <Link to="/agreement">{t("form.privacyTxt3")}</Link>{" "}
                {t("form.andTxt")}{" "}
                <Link to="/privacy">{t("form.privacyTxt4")}</Link>
              </label>
              {errors.terms && <p className="error-msg">{errors.terms}</p>}
            </Form.Group>
          </div>
        </>
      )}
    </>
  );
};
