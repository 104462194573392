import React, { memo, useCallback } from "react";
import "./ChatBox.css";
import ChatComponent from "../LiveChat/ChatComponent";
import { IoChatboxEllipses } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { setToggleState } from "../../reducers/userReducer";

const ChatBox = memo(() => {
  const dispatch = useDispatch();
  const isChatEnabled = useSelector((state) => state?.user?.chatToggle);

  const handleToggle = useCallback(() => {
    dispatch(setToggleState());
  }, [dispatch]);
console.log(isChatEnabled)
  return (
    <div className="chat-wrapper">
      <button className="chat-button-toggle" onClick={handleToggle}>
        <IoChatboxEllipses size={25} />
      </button>
      {isChatEnabled && (
        <div className={`chat-popup new-chatOpen ${isChatEnabled ? "open" : ""}`}>
          <ChatComponent toggleForm={handleToggle} />
        </div>
      )}
    </div>
  );
});

// const MemoizedChatComponent = memo(ChatComponent);

export default ChatBox;
