/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import box1 from "../../assets/images/home/1.jpg";
// import { useHistory } from "react-router-dom";
import ".././unboxing/unboxing.css";
import { useTranslation } from "react-i18next";

const ProdList = ({ products, handleLoadMore, hide }) => {
  const { t } = useTranslation();
  // const history = useHistory();

  const handleLinkClick = (id) => {
    // if(!localStorage.getItem('inboxi#@user')){
    //   return history.push('/?modal=Login')
    // }
    //history.push(`/product-details?id=${id}`);
    window.open(`/product-details?id=${id}`, "_parent");
  };
  return (
    <>
      <div
        className="row wow fadeInUp"
        data-wow-duration="0.5s"
        data-wow-delay="0.3s"
      >
        <div className="col-lg-12">
          <div className="row mb-none-30">
            {products &&
              products.map((el, i) => {
                //   const { boxItems, image, profitMargin} = box || {}
                return (
                  <div key={i} className="col-xl-3 col-md-6 mb-30">
                    <div className="contest-card allproductbox">
                      <div className="contest-card__thumb">
                        <img
                          src={el?.bannerImage || box1}
                          alt="box"
                          onClick={() => handleLinkClick(el._id)}
                        />

                        {/* <a href='#0' className='action-icon'>
                          <i className='far fa-heart'></i>
                        </a> */}
                        {/* <div className='contest-num'> */}
                        {/* <span>{t('openBoxes.boxNoTitle')}</span> */}
                        {/* <h4 className='number'>{box?.specialTag || ''}</h4>
                        </div> */}
                      </div>
                      <div className="contest-card__content">
                        <div className="left">
                          {/* <h5 className="contest-card__name">{t("openBoxes.boxName")}</h5> */}
                          <h5 className="contest-card__name">
                            {el?.name || ""}
                          </h5>
                        </div>
                        <div className="right">
                          <span className="contest-card__price">
                            $ {el?.price}
                          </span>
                          <p>{t("home.boxPriceTitle")}</p>
                        </div>
                      </div>
                      <div className="contest-card__footer">
                        <button
                          onClick={() => handleLinkClick(el?._id)}
                          className="cmn-btn details-btn style--three btn--sm btn-style"
                        >
                          <span>
                            {" "}
                            <i class="fa fa-file-text-o" aria-hidden="true"></i>
                            {t("boxDetails.seeDetailTxt")}
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      {hide && (
        <div className="row mt-30">
          <div className="col-lg-12">
            <div className="btn-grp" onClick={() => handleLoadMore("loadMore")}>
              <a className="btn-border btn-style">
                <span> {t("openBoxes.loadBtn")}</span>
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProdList;
