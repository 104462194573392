/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
// import { Dropdown } from "react-bootstrap";
// import logo from "../../assets/images/boxilogof.png";
import logo from "../../assets/images/cily-logo.png";
// import alarm from "../../assets/images/alarm-clock.png";
import user from "../../assets/images/user/user-icon.png";
// import ticket from "../../assets/images/icon/btn/tag.png";
import Login from "../login/login";
import Register from "../register/register";
import "./header.css";
import { queryString, includeToFixed } from "../../utils/useFunc";
import { useTranslation } from "react-i18next";
import { withoutAuth } from "../../config/axios";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { getLoginUser } from "../../reducers/userReducer";
import InventoryPopup from "../unboxing/inventoryPopup";
import wallet from "../../assets/images/wallet.png";
import inventory from "../../assets/images/inventory.png";
import { Button } from "react-bootstrap";
import DepositPopup from "../PopUp/depositPopup";
import { socket } from "../../config/socket";
import ForgetPassword from "../ForgetPassword/forgetPassword";
// import { setLanguages } from "../../reducers/userReducer";
import { userInstance } from "../../config/axios";
import { clientPath } from "../../config/keys";
import ChatBox from "../layout/ChatBox";
import {
  CaseBattle,
  CasesSvg,
  ContactUsSVG,
  HomeSvg,
  HowItsWorks,
} from "./headerImg";
// import { CiMenuBurger } from "react-icons/ci";
// import { RxCross1 } from "react-icons/rx";

// import ResetPassword from "../ForgetPassword/resetPassword";
const NewHeader = () => {
  const history = useHistory();
  const { payment_ref } = queryString();
  const dispatch = useDispatch();
  const result = useSelector((state) => state.user);
  const { userDetails, distinctInventProd } = result || {};
  // const { paymentOption } = adminSetting || {};
  let preview = userDetails?.profile || user;
  const { t } = useTranslation();
  const [togglenavbar, settogglenavbar] = useState(true);
  const [deposit, setDeposit] = useState(false);
  const handletogglenavbar = () => settogglenavbar(!togglenavbar);
  // const [sticky, setSticky] = useState(false);
  const [login, setLogin] = useState(false);
  const [signup, setSignup] = useState(false);
  const [show, setShow] = useState(false);
  const [isOtpOpen, setIsOtpOpen] = useState(false);
  const [isUser, setisUser] = useState();

  const [loggedinmenu, setloggedinmenu] = useState(true);
  const loggedinmenuhandle = () => setloggedinmenu(!loggedinmenu);
  const [forget, setForget] = useState(false);
  // const[reset,setReset]=useState(false);
  // const changeLanguage = (event) => {
  //   i18n.changeLanguage(event);
  //   dispatch(setLanguages(event));
  // };
  const { modal, ref } = queryString();
  const handledeposit = () => {
    // if(paymentOption){
    setDeposit(true);
    // }
  };
  const handleClose = () => {
    setDeposit(false);
  };

  const [refUserId, setRefUserId] = useState("");

  const checkReferalLink = async () => {
    const response = await withoutAuth().get(
      `/api/auth/checkReferalLink?referalLink=${ref}`
    );
    const { code, msg, referalBy } = response.data;
    if (code === 200) {
      setRefUserId(referalBy);
      // window.location.href = '/?modal=SignUp'
    } else {
      toast(msg);
      // console.log("msg", msg);
    }
  };

  useEffect(() => {
    if (ref) {
      checkReferalLink();
    }
  }, []);

  // const path = window.location.pathname;

  // const watchScroll = () => {
  //   if (window.scrollY > 60) {
  //     setSticky(true);
  //   } else {
  //     setSticky(false);
  //   }
  // };

  // useEffect(() => {
  //   if (window !== undefined && window.screen.width >= 0) {
  //     window.addEventListener("scroll", watchScroll);
  //   }
  //   return () => {
  //     window.removeEventListener("scroll", watchScroll);
  //   };
  // }, []);

  const handleLogin = () => {
    if (login) {
      //window.history.replaceState({}, document.title, "/");
    }
    setLogin(!login);
    setSignup(false);
    if (!login) {
      localStorage.setItem("islogin", "yes");
    } else {
      localStorage.removeItem("islogin");
    }
  };
  const handleSignup = (change, uId) => {
    if (uId) {
      setIsOtpOpen(true);
      setisUser(uId);
    }
    setSignup(!signup);
    setLogin(false);
    if (change) {
      setLogin(!login);
    }
  };

  const handleForget = (change) => {
    // console.log("Chenge in forget==>",change)
    setForget(!forget);
    setLogin(!login);
  };

  // const handleReset=()=>{
  //   setReset(!reset)
  //   setLogin(!login)
  // }
  useEffect(() => {
    if (modal === "Login") {
      handleLogin();
    } else if (modal === "SignUp" || ref) {
      handleSignup();
    }
  }, [modal]);

  const handleLogout = () => {
    localStorage.removeItem("inboxi#@user");
    window.location.href = "/";
    localStorage.removeItem("islogin");
  };
  const handleProfilePage = () => {
    history.push("/profile");
  };

  // for add to cart show
  const handleShow = (e) => {
    e.preventDefault();
    setShow(!show);
  };

  const totalInventoryItems = () => {
    if (distinctInventProd?.length > 0) {
      const result = distinctInventProd.reduce(
        (pre, next) => pre + next?.quantity,
        0
      );
      return result;
    }
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setloggedinmenu(true);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef1 = useRef();

  useOutsideAlerter1(wrapperRef1);

  function useOutsideAlerter1(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        //  setShow(true)
        if (ref.current && !ref.current.contains(event.target)) {
          setShow(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useEffect(() => {
    socket.on("updateRealTimeUserData", (data) => {
      if (data === "opendInventory") {
        setShow(true);
        return;
      }
      dispatch(getLoginUser());
    });
  }, []);
  /*********  payment start  *****/
  useEffect(() => {
    async function successPayment() {
      if (payment_ref) {
        const response = await userInstance().get(
          `api/user/successPayment/${payment_ref}`
        );
        //console.log('response.data==>', response.data);
        const {
          code,
          status,
          paymentDetails: { payment },
        } = response.data || {};
        const { successUrl } = payment || {};
        let txtUrl = "";
        const cUrl = successUrl.substring(
          0,
          successUrl.indexOf("&payment_ref")
        );
        if (cUrl) {
          txtUrl = cUrl;
        } else {
          txtUrl = successUrl.substring(0, successUrl.indexOf("?payment_ref"));
        }
        if (status !== "success") {
          toast.error(t("toast.headererror"), {
            toastId: "battles",
          });
        }
        if (code === 200 && status === "success") {
          toast.success(t("toast.headersuccess"), {
            toastId: "battles",
          });
          dispatch(getLoginUser());
        }
        const newUrl = txtUrl?.replace(clientPath, "");
        history.push(newUrl);
      }
    }
    successPayment();
  }, [dispatch, payment_ref]);

  return (
    <>
      <div className="header-wrapper new-header-wrapper">
        <header className={`header new_header`}>
          <div className={`header__top d-none`}>
            <div className="container">
              <div className="ar-row row align-items-center">
                <div className="col-sm-4">
                  {/* <div className='ar-left left d-flex align-items-center no-display-xl flex-wrap'>
                  <a href="tel:+216 52 11 88 88">
                      <i className='las la-phone-volume'></i>{" "}
                      +216 52 11 88 88
                    </a>
                    <div className='work-time'>
                      <i class='las la-clock'></i>
                      de 10H à 18H
                    </div>
                  </div> */}
                </div>
                <div className="col-sm-8">
                  <div className="right">
                    <div className="product__cart" ref={wrapperRef1}>
                      {localStorage.getItem("inboxi#@user") && (
                        <>
                          <Link onClick={handledeposit}>
                            <span className="total__amount">
                              <img src={wallet} alt="wallet" />${" "}
                              {includeToFixed(userDetails?.walletAmount) || "0"}{" "}
                            </span>
                          </Link>
                          <Link className="amount__btn" onClick={handleShow}>
                            <img src={inventory} alt="inventory" />
                            <span className="cart__num">
                              {totalInventoryItems() || 0}
                            </span>
                          </Link>
                        </>
                      )}
                      <div>{show && <InventoryPopup setShow={setShow} />}</div>
                    </div>

                    {localStorage.getItem("inboxi#@user") && (
                      <div
                        className={`loggedin-menu ${
                          loggedinmenu ? "close-dropdown" : "open"
                        }`}
                        onClick={loggedinmenuhandle}
                        ref={wrapperRef}
                      >
                        {/* <i class="las la-bars loggedin"></i> */}
                        <div className="loggedin">
                          <img
                            src={preview}
                            width={40}
                            height={40}
                            alt="user icon"
                          />{" "}
                          &nbsp;
                          <span>
                            {userDetails?.username}{" "}
                            <i class="fa fa-caret-down"></i>
                          </span>
                        </div>
                        <ul className="loggedin-list">
                          <li
                            data-value="Filter option"
                            className="option"
                            onClick={handleProfilePage}
                          >
                            {/* <Link to = '/profile'> */}
                            <i class="las la-user"></i> {t("topHeader.proTxt")}
                            {/* </Link> */}
                          </li>
                          <li
                            data-value="Filter option"
                            className="option"
                            onClick={handleLogout}
                          >
                            {/* <Link to = '/'> */}
                            <i class="fa fa-sign-out"></i>{" "}
                            {t("topHeader.logoutTxt")}
                            {/* </Link> */}
                          </li>
                        </ul>
                      </div>
                    )}
                    <div className="sticky-header-btn">
                      <Link to="/boxes" className="btn-style">
                        <span className="mr-2">
                          {/* <img src={ticket} alt="icon" className="mr-2" /> */}
                          <i class="fa fa-archive" aria-hidden="true"></i>
                        </span>
                        <span>{t("header.openBtn")}</span>
                      </Link>
                    </div>
                    {/* <div className="language">
                      <Dropdown>
                        <Dropdown.Toggle variant="default" id="dropdown-basic">
                          {i18n?.resolvedLanguage?.toUpperCase()}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => changeLanguage("en")}>
                            <span class="flag-icon flag-icon-sun"></span>{" "}
                            English
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => changeLanguage("fr")}>
                            <span class="flag-icon flag-icon-sun"></span> French
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => changeLanguage("ar")}>
                            <span class="flag-icon flag-icon-sun"></span> Arabic
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="header__bottom">
            <div className="container">
              <nav className="navbar navbar-expand-xl p-0 align-items-center">
                <Link className="site-logo site-title" to="/">
                  <img src={logo} alt="site-logo" />
                  <span className="logo-icon">
                    <i className="flaticon-fire"></i>
                  </span>
                </Link>
                <button
                  className="navbar-toggler ml-auto nav-toggle"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={handletogglenavbar}
                >
                  <span className={`bar ${!togglenavbar ? "x" : ""}`}></span>
                  <span className={`bar ${!togglenavbar ? "x" : ""}`}></span>
                  <span className={`bar ${!togglenavbar ? "x" : ""}`}></span>
                  {/* <span className="menu-toggle"></span> */}
                  {/* <div
                    className={`icon-wrapper ${togglenavbar ? "hide" : "show"}`}
                  >
                    <RxCross1
                      className="icon text-white"
                      color="white"
                      size={25}
                    />
                  </div>
                  <div
                    className={`icon-wrapper ${togglenavbar ? "show" : "hide"}`}
                  >
                    <CiMenuBurger
                      className="icon text-white"
                      color="white"
                      size={25}
                    />
                  </div> */}
                </button>
                <div
                  className={`${
                    togglenavbar
                      ? "collapse navbar-collapse"
                      : "collapse navbar-collapse show"
                  } justify-content-between`}
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav main-menu new-main-menu">
                    <li>
                      <Link to="/">
                        {" "}
                        <HomeSvg /> <p>Home</p>{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="/boxes">
                        <CasesSvg /> <p>Cases</p>
                      </Link>
                    </li>
                    <li>
                      <Link to="/battles">
                        {" "}
                        <CaseBattle /> <p>Battles</p>
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/">
                       <HowItsWorks />
                       How it works</Link>
                    </li> */}
                    {localStorage.getItem("inboxi#@user") && (
                      <li>
                        <Link to="/store">
                          <HowItsWorks /> <p>Store</p>
                        </Link>
                      </li>
                    )}
                    <li>
                      <Link to="/contact-us">
                        {" "}
                        <ContactUsSVG /> <p>Contact us</p>
                      </Link>
                    </li>
                  </ul>
                  <div className="d-flex">
                    {!localStorage.getItem("inboxi#@user") && (
                      <div className="nav-right">
                        <Button
                          // to='/?modal=Login'
                          onClick={() => handleLogin()}
                          className="btn-style"
                          data-toggle="modal"
                          data-target="#loginModal"
                        >
                          <span>
                            {/* <i className="las la-user"></i> */}
                            Log in <LoginSvg />
                          </span>
                        </Button>
                      </div>
                    )}
                    <div className="nav-right left-nav-bottm d-flex align-items-center gap-4 gap-xl-3  mt-xl-0  mt-0 ">
                      <div className="product__cart d-flex" ref={wrapperRef1}>
                        {localStorage.getItem("inboxi#@user") && (
                          <>
                            <Link onClick={handledeposit}>
                              <div className="d-flex header-wallet">
                                <span className="total__amount">
                                  {/* <img src={wallet} alt="wallet" />${" "} */}
                                  {includeToFixed(userDetails?.walletAmount) ||
                                    "0"}{" "}
                                </span>
                                <span className="wallet-btn">Wallet</span>
                              </div>
                            </Link>
                            {/* <Link className="amount__btn" onClick={handleShow}>
                            <img src={inventory} alt="inventory" />
                            <span className="cart__num">
                              {totalInventoryItems() || 0}
                            </span>
                          </Link> */}
                          </>
                        )}
                        <div>
                          {show && <InventoryPopup setShow={setShow} />}
                        </div>
                      </div>

                      {localStorage.getItem("inboxi#@user") && (
                        <div
                          className={`loggedin-menu new-loggedin-menu ${
                            loggedinmenu ? "close-dropdown" : "open"
                          }`}
                          onClick={loggedinmenuhandle}
                          ref={wrapperRef}
                        >
                          {/* <i class="las la-bars loggedin"></i> */}
                          <div className="loggedin new-loggedin d-flex justify-content-center align-items-center">
                            <img
                              src={preview}
                              width={40}
                              height={40}
                              alt="user icon"
                            />{" "}
                            &nbsp;
                            <span>
                              {/* {userDetails?.username}{" "} */}
                              {/* <i class="fa fa-caret-down"></i> */}
                              <DownArrow />
                            </span>
                          </div>
                          <ul className="loggedin-list">
                            <li
                              data-value="Filter option"
                              className="option"
                              onClick={handleProfilePage}
                            >
                              {/* <Link to = '/profile'> */}
                              <i class="las la-user"></i>{" "}
                              {t("topHeader.proTxt")}
                              {/* </Link> */}
                            </li>
                            <li
                              data-value="Filter option"
                              className="option"
                              onClick={handleLogout}
                            >
                              {/* <Link to = '/'> */}
                              <i class="fa fa-sign-out"></i>{" "}
                              {t("topHeader.logoutTxt")}
                              {/* </Link> */}
                            </li>
                          </ul>
                        </div>
                      )}
                      {localStorage.getItem("inboxi#@user") && (
                        <>
                          <Link className="amount__btn" onClick={handleShow}>
                            {/* <i className='las la-shopping-basket'></i> */}
                            {/* <img src={inventory} alt="inventory" /> */}
                            <div className="notificationIcon">
                              <NotificationIcon />
                              <span className="cart__num">
                                {totalInventoryItems() || 0}
                              </span>
                            </div>
                          </Link>
                        </>
                      )}
                      <ChatBox />
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </header>

        {!localStorage.getItem("inboxi#@user") && (
          <Login
            login={login}
            handleLogin={handleLogin}
            handleSignup={handleSignup}
            handleForget={handleForget}
          />
        )}

        {!localStorage.getItem("inboxi#@user") && forget && (
          <ForgetPassword forget={forget} handleForget={handleForget} />
        )}

        {!localStorage.getItem("inboxi#@user") && (
          <Register
            signup={signup}
            handleSignup={handleSignup}
            handleLogin={handleLogin}
            refUserId={refUserId}
            isOtpOpen={isOtpOpen}
            isUser={isUser}
          />
        )}
        <DepositPopup show={deposit} handleClose={handleClose} />
      </div>
    </>
  );
};

export default NewHeader;

const LoginSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="26"
      viewBox="0 0 27 26"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.7647 10.0748C20.7647 11.6998 19.4647 13.0648 17.7747 13.0648C16.1497 13.0648 14.7847 11.7648 14.7847 10.0748C14.7847 8.4498 16.0847 7.0848 17.7747 7.0848C19.3997 7.0848 20.7647 8.4498 20.7647 10.0748ZM15.5647 10.0748C15.5647 11.3098 16.5397 12.2848 17.7747 12.2848C19.0097 12.2848 19.9847 11.3098 19.9847 10.0748C19.9847 8.8398 19.0097 7.8648 17.7747 7.8648C16.5397 7.8648 15.5647 8.8398 15.5647 10.0748ZM10.9497 15.7948L9.45475 15.1448C10.0397 14.9498 10.6897 14.9498 11.2747 15.1448C11.9247 15.4048 12.3797 15.8598 12.6397 16.5098C12.8997 17.1598 12.8997 17.8098 12.6397 18.4598C12.1197 19.7598 10.6247 20.3448 9.38975 19.8248C8.80475 19.5648 8.34975 19.1098 8.08975 18.5898L9.51975 19.1748C10.4297 19.5648 11.5347 19.1098 11.9247 18.1998C12.3147 17.2898 11.8597 16.1848 10.9497 15.7948ZM13.9397 24.6998C8.67475 24.6998 4.18975 21.1898 2.69475 16.4448L7.11475 18.2648C7.30975 19.2398 8.02475 20.1498 9.06475 20.5398C10.6897 21.2548 12.6397 20.4748 13.2897 18.7848C13.4847 18.3298 13.5497 17.8748 13.5497 17.4198L17.6447 14.4948H17.7747C20.2447 14.4948 22.1947 12.4798 22.1947 10.0098C22.1947 7.5398 20.1797 5.5898 17.7747 5.5898C15.3047 5.5898 13.3547 7.6048 13.3547 10.0098V10.0748L10.4297 14.2998C9.97475 14.2998 9.51975 14.3648 9.06475 14.5598C8.86975 14.6248 8.67475 14.7548 8.54475 14.8198L2.23975 12.2198C2.62975 6.1098 7.69975 1.2998 13.9397 1.2998C20.4397 1.2998 25.6397 6.5648 25.6397 12.9998C25.6397 19.4348 20.3747 24.6998 13.9397 24.6998Z"
        fill="#1E1E1E"
      />
    </svg>
  );
};

const DownArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M6 9L12 15L18 9"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const NotificationIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M18 8C18 6.4087 17.3679 4.88258 16.2426 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.88258 2.63214 7.75736 3.75736C6.63214 4.88258 6 6.4087 6 8C6 15 3 17 3 17H21C21 17 18 15 18 8Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.7295 21C13.5537 21.3031 13.3014 21.5547 12.9978 21.7295C12.6941 21.9044 12.3499 21.9965 11.9995 21.9965C11.6492 21.9965 11.3049 21.9044 11.0013 21.7295C10.6977 21.5547 10.4453 21.3031 10.2695 21"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
