import React from 'react';
import { Modal } from 'react-bootstrap';
import { propTypes } from 'react-bootstrap/esm/Image';
import bitcoin from '../../assets/images/deposit-png/b.png';
import litecoin from '../../assets/images/deposit-png/b.png';
import etherium from '../../assets/images/deposit-png/b.png';
import Check from '../../assets/images/deposit-png/b.png';

const AssetsPopup = ({
	show,
	handleClose,
	selectCurrency,
	selectedBlockchain,
}) => {
	return (
		<Modal
			centered
			className="login-popup assets-popup"
			show={show}
			onHide={handleClose}
		>
			<Modal.Header closeButton>
				<Modal.Title>Select Assets</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="assets-listing-section">
					<div className="assets-section" onClick={() => selectCurrency('BTC')}>
						<div className="asstes-left">
							<img src={bitcoin} alt="" />
							<div className="bitxoin-heading">
								<h6>Bitcoin</h6>
								<p>BTC</p>
							</div>
						</div>

						<div className="asstes-left assets-right">
							<div className="bitxoin-heading">
								<div>
									{/* <h6>0 BTC</h6>
                  <p>₹0.00</p> */}
								</div>
								<div>
									{' '}
									{selectedBlockchain === 'BTC' && (
										<img className="tick" src={Check} alt='ghsgdh' />
									)}
								</div>
							</div>
						</div>
					</div>
					<div className="assets-section" onClick={() => selectCurrency('LTC')}>
						<div className="asstes-left">
							<img src={litecoin} alt="" />
							<div className="bitxoin-heading">
								<h6>Litecoin</h6>
								<p>LTC</p>
							</div>
						</div>
						<div className="asstes-left assets-right">
							<div className="bitxoin-heading">
								<div>
									{/* <h6>0 LTC</h6>
                  <p>₹0.00</p> */}
								</div>
								<div>
									{' '}
									{selectedBlockchain === 'LTC' && (
										<img className="tick" src={Check} alt='hdgfh'/>
									)}
								</div>
							</div>
						</div>
					</div>
					<div className="assets-section" onClick={() => selectCurrency('ETH')}>
						<div className="asstes-left">
							<img src={etherium} alt="" />
							<div className="bitxoin-heading">
								<h6>Ethereum</h6>
								<p>ETH</p>
							</div>
						</div>
						<div className="asstes-left assets-right">
							<div className="bitxoin-heading">
								<div>
									{/* <h6>0 ETH</h6>
                  <p>₹0.00</p> */}
								</div>
								<div>
									{' '}
									{selectedBlockchain === 'ETH' && (
										<img className="tick" src={Check} alt='gc'/>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};
AssetsPopup.propTypes = {
	show: propTypes.boolean,
	handleClose: propTypes.function,
	selectCurrency: propTypes.function,
	selectedBlockchain: propTypes.string,
};

export default AssetsPopup;
