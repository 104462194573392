import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { getClientSeed } from "../../utils/useFunc";
import { socket } from "../../config/socket";
import { useTranslation } from "react-i18next";

import {
  getGameResult,
  addToInventory,
  getLoginUser,
  updateUserWallet,
  // incrementWalletAmount,
  getBoxesByProductId,
  updateWinItem,
  sellUpdateWallet,
  getDistinctInventoryProducts,
} from "../../reducers/userReducer";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { handleBoxPrice, includeToFixed } from "../../utils/useFunc";
import { toast } from "react-toastify";
import RollButton from "./rollButton";
import OneRoulette from "./oneRouletteGame";
import TwoRoulette from "./twoRouletteGame";
import ThreeRoulette from "./threeRouletteGame";
import FourRoulette from "./fourRouletteGame";
import Winner from "./winnerItem";
import GameType from "./gameType";
import HidePlaceHolder from "./hidePlaceHolder";
import ShowPlaceHolder from "./showPlaceHolder";
import Play from "../../assets/auido/boxispin.mp3";
import winPlay from "../../assets/auido/win_cash.mp3";
import bigwinPlay from "../../assets/auido/big_win_sound.mp3";
import MultiWinner from "./multiWinnerItem";
import ProductDetailsPopup from "./productdetailspopup";
import Login from "../login/login";
import Register from "../register/register";
import DepositPopup from "../PopUp/depositPopup";
import "./game.css";
import "./stripeDetails.css";

const Roulette = ({ boxDetails, setShow, checkStatus }) => {
  const dispatch = useDispatch();
  const result = useSelector((state) => state.user);
  const { winItem, userDetails, toTalearnMoney, productBoxes } = result || {};
  //console.log('WinITEM is here =>',winItem  );
  const [preloadBox, setPreloadBox] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [playSound, setPlaySound] = useState(false);
  const { _id, image, profitMargin, freeBox, boxItems } = boxDetails || {};
  const [sellCat, setSellCat] = useState(0);
  const [totalAmount, setTotalAmount] = useState(toTalearnMoney);
  const [proshow, setProShow] = useState(false);
  const [particularProduct, setParticularProduct] = useState({});
  const [winplaySound, setWinPlaySound] = useState(false);
  const [spinvalue, setSpinValue] = useState(0);
  const [spintime, setSpinTime] = useState(0);
  const [winner, setWinner] = useState(false);
  const [winner2, setWinner2] = useState(false);
  const [drop, setDrop] = useState(false);
  const [active, setactive] = useState("1");
  const [opacity, setOpacity] = useState(true);
  const [showplaceholder, setShowPlaceholder] = useState(true);
  const [smallPlaceholder, setSmallPlaceholder] = useState(true);
  const [forDisablingButtons, setForDisablingButtons] = useState([]);
  const [toggleImg, settoggleImg] = useState(false);
  const [clientSeed, setClientSeed] = useState();
  const history = useHistory();
  const [deposit, setDeposit] = useState(false);
  const [requiredAmount, setRequiredAmount] = useState(0);
  const [checkSpace, setCheckSpace] = useState(false);
  const [checkstopGame, SetcheckStopGame] = useState(false);
  const [rollType, setRollType] = useState("");
  const [login, setLogin] = useState(false);
  const [signup, setSignup] = useState(false);
  const [activeId, setActiveId] = useState("");
  const [checkgameRes, setCheckgameRes] = useState(false);
  const [gameIds, setGameIds] = useState([]);
  const [winGameId, setWinGameId] = useState([]);
  const [isSelll, setisSelll] = useState(false);
  const filterBoxItems = boxDetails?.boxItems?.filter(
    (el) => el?.pid && el?.pid?._id
  );
  const boxPrice = boxDetails?.boxItems
    ? handleBoxPrice(boxDetails?.boxItems, profitMargin)
    : 0;

  useEffect(() => {
    const arr = [];
    let startIndex = 0;
    if (filterBoxItems && filterBoxItems.length > 0) {
      for (let i = 0; i < 210; i++) {
        if (filterBoxItems.length === startIndex) {
          startIndex = 0;
        }
        arr.push(filterBoxItems[startIndex]);
        startIndex++;
      }
    }

    setPreloadBox(() => [...arr]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boxDetails, spinvalue]);

  useEffect(() => {
    setTotalAmount(includeToFixed(toTalearnMoney));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [winItem]);

  const handleCloseDeposit = () => {
    setDeposit(false);
  };

  const handleActive = (e) => {
    setactive(e);
    setOpacity(true);
    setSmallPlaceholder(true);
    setShowPlaceholder(true);
    setWinner2(false);
  };
  const { t } = useTranslation();

  const handleClose = (type) => {
    // console.log('hwiiiiii')
    setDrop(false);
    setOpacity(true);
    setWinner(false);
    setWinner2(false);
    setShowPlaceholder(true);
    setSmallPlaceholder(true);
    setSpinTime(0);
    setSpinValue(0);
    SetcheckStopGame(false);
    if (type) settoggleImg(true);
    dispatch(getDistinctInventoryProducts());

    // if (type) handleAddInventory(type);
    setCheckgameRes(false);
    if (gameIds.length > 0 && !openModal) {
      setTimeout(() => {
        dispatch(updateWinItem(""));
        if (type) settoggleImg(false);
      }, 500);

      toast.success(t("toast.inventrysuccess"), {
        toastId: "addToInventory",
      });
      socket.emit("opendInventory", userDetails?._id);
    }
  };

  useEffect(() => {
    if (!winner || !winner2) {
      socket.emit("updateLiveDrop", {});
    }
  }, [winner, winner2]);

  const handleLogin = (change) => {
    setLogin(!login);
    setSignup(false);
    if (change) {
      dispatch(getLoginUser());
    }
    if (!login) {
      localStorage.setItem("islogin", "yes");
    } else {
      localStorage.removeItem("islogin");
    }
  };

  const handleSignup = (change) => {
    setSignup(!signup);
    setLogin(false);
    if (change) {
      setLogin(!login);
    }
  };

  const handlespinvalue = async (boxId, forRollType) => {
    SetcheckStopGame(false);
    setCheckSpace(false);
    setRollType(forRollType);
    setForDisablingButtons([]);
    setPlaySound(true);
    setShow(false);
    setWinner2(false);
    setCheckgameRes(false);
    if (!localStorage.getItem("inboxi#@user")) {
      if (localStorage.getItem("islogin") !== "yes") {
        return handleLogin();
      } else {
        return;
      }
    }
    const actualBoxPrice = parseFloat(boxPrice) * parseInt(active, 10);
    //console.log('actualBoxPrice===>', {actualBoxPrice, boxPrice,active} )
    if (
      forRollType === "paid" &&
      (actualBoxPrice > userDetails?.walletAmount || !userDetails?.walletAmount)
    ) {
      if (userDetails?.email) {
        toast.error(t("toast.walleterror"), {
          toastId: "oasssss",
        });
        const reqAmount = actualBoxPrice - (userDetails?.walletAmount || 0);
        setRequiredAmount(reqAmount);
        setDeposit(true);
        return;
      }
    } else {
      let element = document.getElementById("Play");
      const payload = {
        userId: userDetails?._id,
        amount: actualBoxPrice,
        rollType: active,
        boxId: boxDetails?._id,
        debitCreditType: "debit",
        paymentType: "wallet",
        description: "Box",
        descriptionMessage: "Deduct From Your wallet ",
      };
      if (forRollType === "paid") {
        dispatch(updateUserWallet(payload));
      }
      setSpinTime(8);
      setCheckgameRes(true);
      setOpacity(false);
      setShowPlaceholder(false);
      setSmallPlaceholder(false);
      setSpinValue(50);
      if (element) {
        element.play();
      }
      setTimeout(() => {
        setSpinTime(0);
        setTimeout(() => {
          SetcheckStopGame(true);
        }, 6500);
      }, 2000);

      const result = await handleGameResult(boxId, actualBoxPrice, forRollType);
      const { code } = result || {};
      if (code === 200) {
        if (element) {
          const windata = result?.winResult;
          // element.play();
          const type = active === "1" ? "single" : "multi";
          handleAddInventory(type, windata);
        }
      }
    }
  };

  const handleFreeSpinValue = (boxId, forRollType) => {
    SetcheckStopGame(false);
    setCheckSpace(false);
    setRollType(forRollType);
    setForDisablingButtons([]);
    setPlaySound(true);
    setShow(false);
    setWinner2(false);
    setCheckgameRes(false);
    let element = document.getElementById("Play");
    setSpinTime(8);
    setCheckgameRes(true);
    setOpacity(false);
    setShowPlaceholder(false);
    setSmallPlaceholder(false);
    setSpinValue(50);
    if (element) {
      element.play();
    }
    setTimeout(() => {
      setSpinTime(0);
      setTimeout(() => {
        SetcheckStopGame(true);
      }, 6500);
    }, 2000);
  };

  const handleGameResult = async (boxId, actualBoxPrice, forRollType) => {
    try {
      const playData = {
        playCount: 10,
        type: "user",
        boxId: boxId,
        randomNumber: clientSeed,
        boxPrice: actualBoxPrice,
        rollType: active,
        forRollType: forRollType,
        email: userDetails?.email,
        userName: userDetails?.username,
      };
      const res = await dispatch(getGameResult(playData));
      return res?.payload?.resultData?.data;
    } catch (err) {
      console.log("err");
    }
  };

  useEffect(() => {
    if (checkstopGame && !checkSpace) {
      // console.log("checkstopGame =========================>", { checkstopGame, checkSpace, checkgameRes });
      if (active === "1" && rollType !== "freeTry") {
        setWinner(true);
        setDrop(true);
      }
      if (active !== "1" && rollType !== "freeTry") {
        setWinner2(true);
        setDrop(true);
      }
      setPlaySound(false);
      if (rollType !== "freeTry") {
        setWinPlaySound(true);
        let element2 = document.getElementById("winPlay");
        if (element2) {
          element2.play();
        }
      }

      if (rollType === "freeTry") {
        setTimeout(() => {
          setWinner(false);
          setWinner2(false);
          setDrop(false);
          setOpacity(true);
          setShowPlaceholder(true);
          setSmallPlaceholder(true);
          setShow(true);
          setSpinValue(0)
        }, 5000);
      }
      if (rollType !== "freeTry") {
        // setSpinValue(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkstopGame, rollType, active, winplaySound, setShow]);

  const handleAddInventory = async (type, windata) => {
    // settoggleImg(true);
    const gameData = windata[0];
    const multiGameData = windata.map((el) => ({
      gameId: el.gameId,
      productId: el?.pid?._id,
      price: el?.pid?.price,
      name: el?.pid?.name,
      rarityLevel: el?.pid?.rarityLevel?.name,
    }));
    const copyArray = [...multiGameData];
    const filterData = copyArray.filter(
      (el, index) => !forDisablingButtons.includes(index)
    );
    const payload = {
      boxId: boxDetails?._id,
      gameId: gameData?.gameId,
      productId: gameData?.pid?._id,
      userId: userDetails?._id,
      type,
      multiGameData: filterData,
      price: gameData?.pid?.price,
      name: gameData?.pid?.name,
      rarityLevel: gameData?.pid?.rarityLevel?.name,
    };
    const res = await dispatch(addToInventory(payload));
    const { code, gameIds } = res?.payload?.resultData?.data;
    if (code === 200) {
      //console.log('gameIds==>', gameIds)
      setGameIds(gameIds);
    }
    // console.log("Response check===123",res?.payload?.resultData?.data)
    if (checkStatus) {
      history.push("/");
    }
  };

  const handleParticularSell = (id) => {
    const newTT = forDisablingButtons.slice();
    newTT.push(id);
    setForDisablingButtons(newTT);
  };

  const handleQuickSellPercentage = (particularPrice, gameId, index) => {
    setisSelll(false);
    // console.log('gameId',gameIds)

    if (gameId) {
      setWinGameId([gameId]);
      // setGameIds([])
      // console.log("inventoryID for Sell ")
    } else {
      const gameFind = gameIds.map((el) => el);
      setWinGameId(gameFind);
      // setGameIds([])
    }
    setSellCat(particularPrice);
    setOpenModal(true);
    //handleParticularSell(id)
    setActiveId(index);
  };

  const handleConfirm = async () => {
    console.log("handlelel conformnmmm");
    setisSelll(true);
    // console.log("InventoryId===>",gameIds)
    const payload = {
      amount: sellCat,
      userId: userDetails?._id,
      type: "deposit",
      boxId: boxDetails?._id,
      debitCreditType: "credit",
      paymentType: "wallet",
      description: "Sell Item",
      descriptionMessage: "Add amount in the Wallet",
      // gameIds:gameIds,
      allAmounts: [sellCat],
      gameId: winGameId,
    };

    const res = await dispatch(sellUpdateWallet(payload));

    if (res?.payload?.resultData?.data) {
      // console.log('winGameid',winGameId,gameIds)
      const namesToDeleteSet = new Set(winGameId);
      const newArr = gameIds.filter((name) => {
        return !namesToDeleteSet.has(name);
      });
      // console.log('filter',newArr)
      setGameIds(newArr);
    }
    // console.log("RESPONSE  FOR SELL ===>",res)
    // console.log("RESPONSE  FOR SELL ORIGINAL DATA===>",res?.payload?.resultData?.data)
    // const{code}=res?.payload?.resultData?.Data
    // if(code===200){
    //   setWinGameId([])
    // }
    // console.log("WinGameId===>",winGameId)
    //  if(code===200 && gameIds.length===1){
    //    setGameIds('')
    //  }else if(code===200 && gameIds.length>1){
    //   setGameIds([])
    //  }
    // dispatch(getLoginUser());
    handleParticularSell(activeId);
    let finalAmount = parseFloat(totalAmount) - parseFloat(sellCat);
    finalAmount = includeToFixed(finalAmount);
    setTotalAmount(finalAmount);
    setWinGameId("");
    // setGameIds([])
    onCloseModal();
    if (parseFloat(finalAmount) === 0) {
      handleClose();
    }
    if (checkStatus) {
      history.push("/");
    }
  };

  const onCloseModal = () => {
    setOpenModal(false);
    setWinGameId([]);
    //setGameIds([])
  };

  const handleAllQuickSell = () => {};

  const handleMute = () => {
    setPlaySound(!playSound);
  };

  const handleProduct = async (ee) => {
    setParticularProduct(ee);
    setProShow(true);
    const id = ee?.pid?._id;
    dispatch(getBoxesByProductId(id));
  };

  const handleCloseProduct = () => {
    setProShow(false);
  };

  const getPaySrc = (boxPrice, winItem) => {
    let playSrc = winPlay;
    if (boxPrice && winItem[0]?.pid) {
      const winItemsPrice = winItem.reduce(function (acc, obj) {
        return acc + obj.pid?.price;
      }, 0);
      const { rarityLevel } = winItem[0]?.pid;
      const { name } = rarityLevel || {};
      let newName = name?.toLowerCase();
      const rarityText = "rare,epic,legendary,exotic";
      let newBoxPrice = parseFloat(boxPrice) * 1.5;
      if (
        rarityText.includes(newName) &&
        parseFloat(winItemsPrice) > newBoxPrice
      ) {
        playSrc = bigwinPlay;
      }
    }
    return playSrc;
  };

  useEffect(() => {
    const userSeed = getClientSeed();
    setClientSeed(userSeed);
  }, []);

  const genarateNewSeed = () => {
    const userSeed = getClientSeed();
    setClientSeed(userSeed);
  };

  const handleNewSeed = (val) => {
    setClientSeed(val);
  };

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Enter") {
        if (spinvalue <= 0 && !winner && !winner2) {
          handlespinvalue(_id, "paid");
        }
      } else if (event.code === "Space" || event.keyCode === 32) {
        event.preventDefault();
        // console.log("testtt ==>", { spinvalue, winner2, winner, checkgameRes });
        if (checkgameRes && spinvalue > 0 && !winner && !winner2) {
          setCheckSpace(true);
          setSpinTime(0);
          setPlaySound(false);
          setSpinValue("0");
          setTimeout(() => {
            setSpinValue(50);
          }, 10);
          setDrop(true);
          setWinPlaySound(true);
          let element2 = document.getElementById("winPlay");
          if (element2) {
            element2.play();
          }
          if (active === "1") {
            setWinner(true);
          }
          if (active !== "1") {
            setWinner2(true);
          }
        }
      }
    };
    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    _id,
    spinvalue,
    winner,
    winner2,
    active,
    checkSpace,
    checkgameRes,
    userDetails,
  ]);

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        if (winner || winner2) {
          handleClose("close");
        }
      }
      if (
        e.keyCode === 97 ||
        e.keyCode === 98 ||
        e.keyCode === 99 ||
        e.keyCode === 100
      ) {
        if (spinvalue <= 0 && !winner && !winner2) {
          let newActive =
            e.keyCode === 98
              ? "2"
              : e.keyCode === 99
              ? "3"
              : e.keyCode === 100
              ? "4"
              : "1";
          handleActive(newActive);
        }
      }
    };
    window.addEventListener("keydown", close);
    return () => {
      window.removeEventListener("keydown", close);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id, spinvalue, winner, winner2, userDetails, active, checkSpace]);

  useEffect(() => {
    socket.on("updateRealTimeUserData", (data) => {
      dispatch(getLoginUser());
    });
  }, [dispatch]);

  return (
    <div className="roulette-wrapper">
      <Helmet>
        <body
          className={`${drop ? `body-fade` : ``} ${
            opacity ? `` : `roulette-section_hide`
          }`}
        />
      </Helmet>
      <div className="container">
        {active === "1" ? (
          <div className={`roulette_section ${opacity ? `hide` : ``}`}>
            <OneRoulette
              winItem={winItem}
              spinvalue={spinvalue}
              spintime={spintime}
              preloadBox={preloadBox}
              active={active}
            />
          </div>
        ) : (
          ""
        )}

        {active === "2" ? (
          <div className="added-flip-cion">
            <div className={`roulette_section roulette-bg-horizontal`}>
              <TwoRoulette
                smallPlaceholder={smallPlaceholder}
                spinvalue={spinvalue}
                spintime={spintime}
                preloadBox={preloadBox}
                winItem={winItem}
                activeBoxImg={image}
                winner2={winner2}
                active={active}
              />
            </div>
            {/* <Coinflip /> */}
          </div>
        ) : (
          ""
        )}
        {active === "3" ? (
          <div className={`roulette_section roulette-bg-horizontal`}>
            <ThreeRoulette
              smallPlaceholder={smallPlaceholder}
              spinvalue={spinvalue}
              spintime={spintime}
              activeBoxImg={image}
              preloadBox={preloadBox}
              winItem={winItem}
              winner2={winner2}
              active={active}
            />
          </div>
        ) : (
          ""
        )}

        {active === "4" ? (
          <div className={`roulette_section roulette-bg-horizontal`}>
            <FourRoulette
              smallPlaceholder={smallPlaceholder}
              spinvalue={spinvalue}
              spintime={spintime}
              activeBoxImg={image}
              preloadBox={preloadBox}
              winItem={winItem}
              winner2={winner2}
              active={active}
            />
          </div>
        ) : (
          ""
        )}

        {showplaceholder && active === "1" ? (
          <ShowPlaceHolder displayProduct={image} />
        ) : (
          <HidePlaceHolder displayProduct={image} />
        )}

        <GameType
          spinvalue={spinvalue}
          active={active}
          handleActive={handleActive}
          handleMute={handleMute}
          playSound={playSound}
          opacity={opacity}
        />

        {!localStorage.getItem("inboxi#@user") && (
          <Login
            login={login}
            handleLogin={handleLogin}
            handleSignup={handleSignup}
          />
        )}
        {!localStorage.getItem("inboxi#@user") && (
          <Register
            signup={signup}
            handleSignup={handleSignup}
            handleLogin={handleLogin}
            refUserId=""
          />
        )}

        <RollButton
          handlespinvalue={handlespinvalue}
          handleFreeSpinValue={handleFreeSpinValue}
          spinvalue={spinvalue}
          boxId={_id}
          boxPrice={boxPrice}
          active={active}
          winner2={winner2}
          toTalearnMoney={totalAmount}
          handleAllQuickSell={handleAllQuickSell}
          handleMute={handleMute}
          playSound={playSound}
          setShow={setShow}
          freeBox={freeBox}
          boxItems={boxItems}
          clientSeed={clientSeed}
          genarateNewSeed={genarateNewSeed}
          handleNewSeed={handleNewSeed}
          opacity={opacity}
        />
      </div>
      {/* {console.log('HttingAgain',winItem)} */}
      {winItem?.length > 0 &&
        winItem?.map((el) => (
          <div
            className={`animate-inventory ${toggleImg ? "toggle-cart" : ""}`}>
            <div id="show" className={`fly-cart`} on>
              {/* {console.log('toggleImage',toggleImg)}   */}
              <img className="img-fluid" src={el?.pid?.bannerImage} alt="" />
            </div>
          </div>
        ))}
      {winner && active === "1" ? (
        <div className="winproduct">
          <Winner
            handleClose={handleClose}
            winItem={winItem}
            handleAddInventory={handleAddInventory}
            handleQuickSellPercentage={handleQuickSellPercentage}
            onCloseModal={onCloseModal}
            handleConfirm={handleConfirm}
            openModal={openModal}
            handleProduct={handleProduct}
            toggleImg={toggleImg}
            isSelll={isSelll}
          />
        </div>
      ) : (
        ""
      )}

      <DepositPopup
        show={deposit}
        requiredAmount={requiredAmount}
        handleClose={handleCloseDeposit}
      />

      {winner2 && active !== "1" ? (
        <div className="winproduct">
          <MultiWinner
            handleClose={handleClose}
            winItem={winItem}
            handleAddInventory={handleAddInventory}
            handleQuickSellPercentage={handleQuickSellPercentage}
            onCloseModal={onCloseModal}
            handleConfirm={handleConfirm}
            openModal={openModal}
            forDisablingButtons={forDisablingButtons}
            winner2={winner2}
            active={active}
            toTalearnMoney={totalAmount}
            handleProduct={handleProduct}
            isSelll={isSelll}
          />
        </div>
      ) : (
        ""
      )}
      <audio id="Play" muted={!playSound} src={Play}></audio>
      <audio
        id="winPlay"
        muted={!winplaySound}
        src={getPaySrc(boxPrice, winItem)}></audio>
      <div className={`abc ${proshow ? "show-popup" : "hide-popup"}`}>
        {particularProduct?.pid?.name && (
          <ProductDetailsPopup
            handleClose={handleCloseProduct}
            item={particularProduct}
            productBoxes={productBoxes}
          />
        )}
      </div>
    </div>
  );
};
export default Roulette;
