import React, { useState } from "react";
// import { Button } from "react-bootstrap";
import "./profile-styles.css";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Profile = ({
  userData,
  errors,
  handleEdit,
  // edit,
  handleChange,
  handleUpdate,
  addAddress,
  setAddAddress,
  handleEditChange,
  userAddress,
  handleShipAddressValues,
  handlePasswordFocus,
}) => {
  const governoratesData = [
    "Ariana",
    "Béja",
    "Ben Arous",
    "Bizerte",
    "Gabès",
    "Gafsa",
    "Jendouba",
    "Kairouan",
    "Kasserine",
    "Kebili",
    "Kef",
    "Mahdia",
    "Manouba",
    "Medenine",
    "Monastir",
    "Nabeul",
    "Sfax",
    "Sidi Bouzid",
    "Siliana",
    "Sousse",
    "Tataouine",
    "Tozeur",
    "Tunis",
    "Zaghouan",
  ];

  // const shipIndex = {
  //   indexValue:-1
  // }

  // const history =  useHistory();

  // const result  =  useSelector((state)=>state.user);
  // const {userDetails} = result;
  const { t } = useTranslation();
  // const dispatch = useDispatch()
  // const {from} = queryString();

  // const [errors,setErrors] = useState()
  const [passwordShow, setPasswordShow] = useState([false, false]);

  // const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(-1);
  const handleEditPro = (index) => () => {
    handleEdit();
    // shipIndex.value = -1;
    setActiveIndex(index);
    // console.log('hdfsahgfhsagdash',index)
  };

  const handlePasswordShow = (index) => {
    const prev = [...passwordShow];
    prev[index] = !prev[index];
    setPasswordShow(prev);
  };

  // const handleAddChange = (e) =>{
  //   console.log('hello')
  //   const {name,value} = e.target;
  //   setAddData({...addData,[name]:value})
  // }
  // let randomStr = (
  //   Math.floor(Math.random() * 9 + 1) +
  //   Date.now() +
  //   (Math.random() + 1).toString(36).substring(7)
  // ).toString()

  // const handleAddShippingAddress = async () => {
  //   addData.uid = randomStr
  //   console.log(addData)
  //   const { error, isValid } = shoppingValidation(addData)
  //   console.log('error',error)

  //   setErrors(error)
  //   if (!isValid) {
  //     return
  //   }
  //   console.log('addData',addData)
  //   // if(userAddress.length === 0){
  //   //   console.log('addressLengthZero')
  //   //   addData.status = true;
  //   // }
  //   const response = await userInstance().put(
  //     '/api/user/updateShippingAddress',
  //     addData
  //   )
  //   console.log('response',response)
  //   const { code, msg } = response.data
  //   if (code === 200) {

  //     // setAddAddress(false)
  //     setAddData(initialAddress);
  //     // userAddress.push(addData)
  //     dispatch(getLoginUser())
  //     handleEdit(false)
  //     console.log('sdsgjhdg')
  //     if(from==='checkout'){
  //       return history.push('/checkOut')
  //      }
  //   }
  // }
  // const handleShippingUpdate = async (index) => {
  //   const activeAddress = userAddress[index]
  //   const response = await userInstance().put(
  //     '/api/user/editShippingAddress',
  //     activeAddress
  //   )
  //   const { code, msg } = response.data
  //   if (code) {
  //     dispatch(getLoginUser())
  //     setAddAddress(false)
  //     handleEdit(false)
  //   }
  // }

  // useEffect(()=>{

  // },[userDetails?.shippingAddress])

  // console.log('userDetails?.shippingAddress',shipIndex.value)

  const handleProfileUpdate = async () => {
    const resu = await handleUpdate(activeIndex);
    //  console.log('resu',resu)
    if (resu === "success") {
      setActiveIndex(-1);
    }
  };

  return (
    <>
      <div>
        <div className="user-info-card">
          <div className="user-info-card__header">
            <h3 className="user-info-card__title">{t("profile.prDetails")}</h3>
            {activeIndex !== 0 ? (
              <button type="button" onClick={handleEditPro(0)}>
                <i className="far fa-edit"></i> {t("profile.editBtn")}
              </button>
            ) : (
              <button type="button" onClick={handleProfileUpdate}>
                <i class="fa fa-check" aria-hidden="true"></i> Update
              </button>
            )}
          </div>
          <ul className="user-info-card__list">
            <li>
              <span className="caption">{t("profile.lableTxt1")}</span>
              <span className="value">
                <input
                  type="text"
                  name="firstname"
                  onChange={handleChange}
                  onBlur={handleShipAddressValues}
                  disabled={activeIndex !== 0}
                  value={userData?.firstname}
                />
                {errors?.firstname && (
                  <p className="error-msg">{errors.firstname}</p>
                )}
              </span>
            </li>
            <li>
              <span className="caption">{t("profile.lableTxt2")}</span>
              <span className="value">
                <input
                  type="text"
                  name="lastname"
                  onChange={handleChange}
                  onBlur={handleShipAddressValues}
                  disabled={activeIndex !== 0}
                  value={userData?.lastname}
                />
                {errors?.lastname && (
                  <p className="error-msg">{errors.lastname}</p>
                )}
              </span>
            </li>
            <li>
              <span className="caption">{t("profile.lableTxt3")}</span>
              <span className="value">
                {/* <input
                  type="date"
                  name="dateOfBirth"
                  onChange={handleChange}
                  onBlur={handleShipAddressValues}
                  disabled={activeIndex !== 0}
                  value={userData?.dateOfBirth?.substr(0, 10)}
                /> */}
                <div className="global-date-picker">
                  <DatePicker
                    selected={
                      userData?.dateOfBirth
                        ? new Date(userData?.dateOfBirth)
                        : new Date()
                    }
                    onChange={(e) => handleChange(e, "dateOfBirth")}
                    //onChange={handleChange("dateOfBirth")}
                    //onBlur={handleShipAddressValues}
                    maxDate={new Date()}
                    placeholderText="JJ/MM/YYYY"
                    dateFormat="d/MM/yyyy"
                    showYearDropdown={true}
                    dropdownMode="select"
                  />
                  {errors?.dateOfBirth && (
                    <p className="error-msg">{errors.dateOfBirth}</p>
                  )}
                </div>
              </span>
            </li>
          </ul>
        </div>

        <div className="user-info-card">
          <div className="user-info-card__header">
            <h3 className="user-info-card__title">{t("profile.shipTxt")}</h3>
            {activeIndex !== 4 ? (
              <button type="button" onClick={handleEditPro(4)}>
                <i className="far fa-edit"></i> {t("profile.editBtn")}
              </button>
            ) : (
              <button type="button" onClick={handleProfileUpdate}>
                <i class="fa fa-check" aria-hidden="true"></i> Update
              </button>
            )}
          </div>
          {userData?.shippingAddress?.map((el, index) => (
            <div className="user-info-card__list">
              <div className="shipping-card-body">
                <div className="row">
                  <div className="col-md-6">
                    <input
                      type="text"
                      placeholder="Shipping Address"
                      name="address"
                      onChange={(e) => handleEditChange(e, index, el)}
                      onBlur={handleShipAddressValues}
                      value={el?.address}
                      disabled={activeIndex !== 4}
                    />
                    {errors?.address && (
                      <p className="error-msg">{errors.address}</p>
                    )}
                  </div>
                  <div className="col-md-6">
                    <input
                      type="text"
                      placeholder="Zip Code"
                      onChange={(e) => handleEditChange(e, index, el)}
                      onBlur={handleShipAddressValues}
                      name="zipCode"
                      value={el?.zipCode}
                      disabled={activeIndex !== 4}
                    />
                    {errors?.zipCode && (
                      <p className="error-msg">{errors.zipCode}</p>
                    )}
                  </div>
                  <div className="col-md-4">
                    <select
                      name="country"
                      // onChange={(e) => handleCountryChange(e.target.value)}
                      disabled
                      value={el?.country}
                    >
                      <option value="Tunisia">Tunisia</option>
                    </select>
                    {errors?.country && (
                      <p className="error-msg">{errors.country}</p>
                    )}
                  </div>
                  <div className="col-md-4 ship-col-padding-0">
                    <select
                      name="state"
                      disabled={activeIndex !== 4}
                      onChange={(e) => handleEditChange(e, index, el)}
                      onBlur={handleShipAddressValues}
                      value={el?.state}
                    >
                      <option value="">Choose Governorate </option>
                      {governoratesData.map((el) => (
                        <option value={el}>{el}</option>
                      ))}
                    </select>
                    {errors?.state && (
                      <p className="error-msg">{errors.state}</p>
                    )}
                  </div>
                  <div className="col-md-4">
                    <input
                      disabled={activeIndex !== 4}
                      type="text"
                      placeholder="Enter City"
                      name="city"
                      onChange={(e) => handleEditChange(e, index, el)}
                      onBlur={handleShipAddressValues}
                      value={el?.city}
                    />
                    {errors?.city && <p className="error-msg">{errors.city}</p>}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="user-info-card">
          <div className="user-info-card__header">
            <h3 className="user-info-card__title">{t("profile.lableTxt4")}</h3>
            {activeIndex !== 1 ? (
              <button type="button" onClick={handleEditPro(1)}>
                <i className="far fa-edit"></i> {t("profile.editBtn")}
              </button>
            ) : (
              <button type="button" onClick={handleProfileUpdate}>
                <i class="fa fa-check" aria-hidden="true"></i> Update
              </button>
            )}
          </div>
          <ul className="user-info-card__list">
            <li>
              <span className="caption">{t("profile.lableTxt4")}</span>
              <span className="value">
                <input
                  type="text"
                  name="mobile"
                  onChange={handleChange}
                  onBlur={handleShipAddressValues}
                  disabled={activeIndex !== 1}
                  value={userData?.mobile}
                />
                {errors?.mobile && <p className="error-msg">{errors.mobile}</p>}
              </span>
            </li>
          </ul>
        </div>

        <div className="user-info-card">
          <div className="user-info-card__header">
            <h3 className="user-info-card__title">{t("profile.lableTxt5")}</h3>
            {activeIndex !== 2 ? (
              <button type="button" onClick={handleEditPro(2)}>
                <i className="far fa-edit"></i> {t("profile.editBtn")}
              </button>
            ) : (
              <button type="button" onClick={handleProfileUpdate}>
                <i class="fa fa-check" aria-hidden="true"></i> Update
              </button>
            )}
          </div>
          <ul className="user-info-card__list">
            <li>
              <span className="caption">{t("profile.lableTxt5")}</span>
              <span className="value">
                <input
                  type="text"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleShipAddressValues}
                  disabled={activeIndex !== 2}
                  value={userData?.email}
                />
                {errors?.email && <p className="error-msg">{errors.email}</p>}
              </span>
            </li>
          </ul>
        </div>

        {activeIndex !== 3 ? (
          <div className="user-info-card">
            <div className="user-info-card__header">
              <h3 className="user-info-card__title">
                {t("home.serviceHeading2")}
              </h3>
              <button type="button" onClick={handleEditPro(3)}>
                <i className="far fa-edit"></i> {t("profile.editBtn")}
              </button>
            </div>
            <ul className="user-info-card__list">
              <li>
                <span className="caption">{t("loginForm.lableTxt2")}</span>
                <span className="value show-password">
                  <input
                    type="password"
                    name="email"
                    // onChange={}
                    // disabled={!edit}
                    disabled
                    value="***********"
                  />
                  <i class="fa fa-eye-slash"></i>
                </span>
              </li>
            </ul>
          </div>
        ) : null}

        {activeIndex === 3 ? (
          <div className="user-info-card">
            <div className="user-info-card__header">
              <h3 className="user-info-card__title">Change Password</h3>
              <button type="button" onClick={handleProfileUpdate}>
                <i class="fa fa-check" aria-hidden="true"></i> Update
              </button>
            </div>
            <ul className="user-info-card__list shipping-card-body">
              <li>
                <span className="caption">Current Password</span>
                <span className="value show-password">
                  <input
                    type={passwordShow[0] ? "text" : "password"}
                    name="currentPassword"
                    onChange={handleChange}
                    onBlur={handlePasswordFocus}
                    disabled={activeIndex !== 3}
                    value={userData?.currentPassword}
                  />
                  <span
                    className="eye-icon"
                    onClick={() => handlePasswordShow(0)}
                  >
                    {passwordShow[0] ? (
                      <i class="fa fa-eye" aria-hidden="true"></i>
                    ) : (
                      <i class="fa fa-eye-slash" aria-hidden="true"></i>
                    )}
                  </span>

                  {errors?.currentPassword && (
                    <p className="error-msg">{errors.currentPassword}</p>
                  )}
                </span>
              </li>
              <li>
                <span className="caption">New Password</span>
                <span className="value show-password">
                  <input
                    type={passwordShow[1] ? "text" : "password"}
                    name="newPassword"
                    onChange={handleChange}
                    onBlur={handlePasswordFocus}
                    disabled={activeIndex !== 3}
                    value={userData?.newPassword}
                  />
                  <span
                    className="eye-icon"
                    onClick={() => handlePasswordShow(1)}
                  >
                    {passwordShow[1] ? (
                      <i class="fa fa-eye" aria-hidden="true"></i>
                    ) : (
                      <i class="fa fa-eye-slash" aria-hidden="true"></i>
                    )}
                  </span>
                  {errors?.newPassword && (
                    <p className="error-msg">{errors.newPassword}</p>
                  )}
                </span>
              </li>
            </ul>
          </div>
        ) : null}

        {/* <div className="text-left upd_btn mt-4">
          <Button className="profile-update btn-style" onClick={handleProfileUpdate}>
            <span>
              {" "}
              <i className="fa fa-pencil"></i> {t("profile.btnTxt")}
            </span>
          </Button>
        </div> */}
      </div>
    </>
  );
};

export default Profile;
