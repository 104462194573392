/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Range, getTrackBackground } from "react-range";
// import InputRange from 'react-input-range';
// import "react-input-range/lib/css/index.css"
import "react-tabs/style/react-tabs.css";
import "./unboxing.css";
import Boxes from "./Boxes";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { getAllBoxesByCat } from "../../reducers/userReducer";
import { handleBoxPrice } from "../../utils/useFunc";
import Loader from "../Loader/loader";
// import { t } from "i18next";
const STEP = 0.1;
const FilterComponent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const serchInitialObj = {
    title: "",
    min: "",
    max: "",
    category: "",
    rarity: "",
    skip: 0,
    limit: 12,
    sort: { createdAt: -1 },
    // sortType: t("openBoxes.sortTxt1"),
    sortType: t("openBoxes.sortTxt1"),
    specialTag: "",
    range: "",
    isload: true,
  };
  const result = useSelector((state) => state.user);
  const {
    boxCategories,
    boxListByCategory,
    boxSpecialtags,
    loading,
    minVal,
    maxVal,
  } = result;
  const [niceselect, setniceselect] = useState(true);
  const niceselecthandle = () => setniceselect(!niceselect);
  const [orderdropdown, setorderdropdown] = useState(true);
  const orderdropdownhandle = () => setorderdropdown(!orderdropdown);
  const wrapperRef = useRef(null);
  const wrapperRef1 = useRef(null);
  const [searchPayload, setSeachPayload] = useState(serchInitialObj);
  const [copyPayload, setCopyPayload] = useState([]);
  const [range1, setRange1] = useState([minVal || 0, maxVal || 1]);
  const [copyPayload2, setCopyPayload2] = useState([]);
  useOutsideAlerter(wrapperRef);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setorderdropdown(true);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter1(wrapperRef1);
  function useOutsideAlerter1(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setniceselect(true);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const sortBySelection = (nValue) => {
    const copyy = JSON.stringify(boxListByCategory);
    const copy = JSON.parse(copyy);
    copy.forEach(
      (el) => (el.boxPrice = handleBoxPrice(el.boxItems, el.profitMargin))
    );
    if (nValue === "Price (Low to high)") {
      const filterBoxes = copy?.sort(
        (a, b) => parseFloat(a?.boxPrice) - parseFloat(b?.boxPrice)
      );
      setCopyPayload(filterBoxes);
      setCopyPayload2(filterBoxes);
    } else {
      const filterBoxes = copy?.sort(
        (a, b) => parseFloat(b?.boxPrice) - parseFloat(a?.boxPrice)
      );
      setCopyPayload(filterBoxes);
      setCopyPayload2(filterBoxes);
    }
  };

  const sortByRange = (valu) => {
    const res = valu?.split("-");
    const val = res[0];
    const rang = res[1];
    const copyy = JSON.stringify(boxListByCategory);
    const copy = JSON.parse(copyy);
    copy.forEach(
      (el) => (el.boxPrice = handleBoxPrice(el.boxItems, el.profitMargin))
    );
    if (val) {
      const rangeFilter = copy.filter(
        (el) =>
          parseFloat(el?.boxPrice) >= val && parseFloat(el?.boxPrice) <= rang
      );
      setCopyPayload(rangeFilter);
    } else {
      setCopyPayload(copy);
    }
  };

  const handleSearchBox = async (name, value, nextValue) => {
    const copySearchPayLoad =
      name === "category" ? { ...serchInitialObj } : { ...searchPayload };
    if (name === "sort" && nextValue) {
      copySearchPayLoad["sortType"] = nextValue;
    }
    copySearchPayLoad.isload = false;
    copySearchPayLoad[name] = value;
    setSeachPayload(copySearchPayLoad);
    if (name !== "range" && nextValue?.includes("Price")) {
      sortBySelection(nextValue);
    } else if (name === "range") {
      sortByRange(value);
    } else {
      copySearchPayLoad.searchName = name;
      dispatch(getAllBoxesByCat(copySearchPayLoad));
    }
  };
  useEffect(() => {
    dispatch(getAllBoxesByCat(searchPayload));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setRange1([minVal || 0, maxVal || 1]);
    setCopyPayload(boxListByCategory);
    setCopyPayload2(boxListByCategory);
  }, [boxListByCategory]);

  const handleRange = (value) => {
    //console.log('valuueee=>', value[0], value[1])
    // const { min, max} = value || {}
    let copyFilterBox = [...copyPayload2];
    // console.log('copyFilterBox==>', copyFilterBox)
    const rangeFilter = copyFilterBox.filter(
      (el) =>
        parseFloat(el?.boxPrice) >= parseFloat(value[0]) &&
        parseFloat(el?.boxPrice) <= parseFloat(value[1])
    );
    //console.log('rangeFilter=>', rangeFilter)
    setCopyPayload(rangeFilter);
    setRange1(value);
  };
  return (
    <>
      <div className="filter-tabs-section">
     
        <Tabs className="filter-tabs">
           <div className="filter-tabs-section-filter">
          <TabList className="filter-tablist">
            {boxCategories &&
              boxCategories.length > 0 &&
              boxCategories.map((el) => (
                <Tab onClick={() => handleSearchBox("category", el._id)}>
                  {el.name}
                </Tab>
              ))}
          </TabList>
</div>
          <div className="row contest-filter-wrapper">
            <div className="col-lg-3 col-sm-6 mb-30">
              <div
                className={`${
                  orderdropdown
                    ? "order-dropdown-list"
                    : "order-dropdown-list open"
                }`}
                onClick={orderdropdownhandle}
                ref={wrapperRef}
              >
                <span className="current">{searchPayload?.sortType}</span>
                <ul className="list">
                  <li
                    data-value="Filter option"
                    className="option"
                    onClick={() =>
                      handleSearchBox(
                        "sort",
                        { openingCount: -1 },
                        `${t("openBoxes.sortTxt2")}`
                      )
                    }
                  >
                    {t("openBoxes.sortTxt2")}
                  </li>
                  <li
                    data-value="Filter option"
                    className="option"
                    onClick={() =>
                      handleSearchBox(
                        "sort",
                        { openingCount: 1 },
                        `${t("openBoxes.sortTxt3")}`
                      )
                    }
                  >
                    {t("openBoxes.sortTxt3")}
                  </li>
                  <li
                    data-value="Filter option"
                    className="option"
                    onClick={() =>
                      handleSearchBox(
                        "sort",
                        { createdAt: -1 },
                        `${t("openBoxes.sortTxt6")}`
                      )
                    }
                  >
                    {t("openBoxes.sortTxt6")}
                  </li>
                  <li
                    data-value="Filter option"
                    className="option"
                    onClick={() =>
                      handleSearchBox(
                        "sort",
                        { createdAt: 1 },
                        `${t("openBoxes.sortTxt7")}`
                      )
                    }
                  >
                    {t("openBoxes.sortTxt7")}
                  </li>
                  <li
                    data-value="Filter option"
                    className="option"
                    onClick={() =>
                      handleSearchBox(
                        "sort",
                        { price: 1 },
                        `${t("openBoxes.sortTxt4")}`
                      )
                    }
                  >
                    {t("openBoxes.sortTxt4")}
                  </li>
                  <li
                    data-value="Filter option"
                    className="option"
                    onClick={() =>
                      handleSearchBox(
                        "sort",
                        { price: -1 },
                        `${t("openBoxes.sortTxt5")}`
                      )
                    }
                  >
                    {t("openBoxes.sortTxt5")}
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6 mb-30">
              <div
                className={`${niceselect ? "nice-select" : "nice-select open"}`}
                onClick={niceselecthandle}
                ref={wrapperRef1}
              >
                <span className="current">
                  {searchPayload?.specialTag
                    ? searchPayload?.specialTag
                    : t("openBoxes.sortTxt8")}
                </span>
                <ul className="list">
                  <li
                    data-value="Filter  option"
                    className="option"
                    onClick={() => handleSearchBox("specialTag", "")}
                  >
                    All
                  </li>
                  {boxSpecialtags?.length > 0 &&
                    boxSpecialtags.map(
                      (el) =>
                        el && (
                          <li
                            data-value="Filter  option"
                            className="option"
                            onClick={() => handleSearchBox("specialTag", el)}
                          >
                            {el}
                          </li>
                        )
                    )}
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6 mb-30">
              <div className="inputRange-slider">
                {/* <InputRange
                    maxValue={maxVal}
                    minValue={minVal}
                    value={range1}
                    onChange={(val)=>handleRange(val)} />  */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    width: "94%",
                  }}
                >
                  <Range
                    values={range1}
                    step={STEP}
                    min={minVal}
                    max={maxVal === 0 ? 1 : maxVal}
                    rtl={false}
                    onChange={(val) => handleRange(val)}
                    renderTrack={({ props, children }) => (
                      <div
                        onMouseDown={props.onMouseDown}
                        onTouchStart={props.onTouchStart}
                        style={{
                          ...props.style,
                          height: "36px",
                          display: "flex",
                          width: "100%",
                        }}
                      >
                        <div
                          ref={props.ref}
                          style={{
                            height: "5px",
                            width: "100%",
                            borderRadius: "4px",
                            background: getTrackBackground({
                              values: range1,
                              colors: ["#ccc", "#e89c31", "#ccc"],
                              min: minVal,
                              max: maxVal,
                              rtl: false,
                            }),
                            alignSelf: "center",
                          }}
                        >
                          {children}
                        </div>
                      </div>
                    )}
                    renderThumb={({ index, props, isDragged }) => (
                      <div {...props} className="slider-label">
                        <div
                          style={{
                            position: "absolute",
                            top: "-28px",
                            color: "#fff",
                            fontWeight: "bold",
                            fontSize: "12px",
                            fontFamily:
                              "Arial,Helvetica Neue,Helvetica,sans-serif",
                            padding: "4px",
                            borderRadius: "4px",
                            backgroundColor: "#e89c31",
                            left: "-10px",
                          }}
                        >
                          {range1[index]?.toFixed(1)}
                        </div>
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mb-30">
              <form
                className="contest-search-form"
                onSubmit={(e) => e.preventDefault()}
              >
                <input
                  type="text"
                  name="title"
                  onChange={(e) =>
                    handleSearchBox(e.target.name, e.target.value)
                  }
                  placeholder={t("openBoxes.searchTxt")}
                />
                <button>
                  <i className="fa fa-search"></i>
                </button>
              </form>
            </div>
          </div>
          {boxCategories &&
            boxCategories.length > 0 &&
            boxCategories.map((el) => (
              <TabPanel>
                <Boxes boxes={copyPayload} />
              </TabPanel>
            ))}
        </Tabs>
      </div>
      {loading && <Loader />}
    </>
  );
};
export default FilterComponent;
