import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "../login/login.css";
//import { validatePassWord } from '../../utils/index'

const BattlePassword = ({
  show,
  handleBattlePassOpen,
  handleChangePass,
  battleObj,
  setShow,
  from,
  onSubmitHandler,
  setErrors,
  errors,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      onHide={() => handleBattlePassOpen("change")}
      centered
      className="modal fade login-popup wrapper"
      id="loginModal"
      tabindex="1"
      role="dialog"
      aria-hidden="true"
    >
      {/* <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable"> */}
      <div class="modal-content">
        <div class="modal-body">
          <div class="account-form-area">
            {from !== "battle" && (
              <button
                onClick={() => handleBattlePassOpen("change")}
                type="button"
                class="close-btn"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i class="las la-times"></i>
              </button>
            )}
            <h3 class="ar-login-title title">{t("form.roomPswrd")}</h3>
            <div class="account-form-wrapper">
              <form onSubmit={onSubmitHandler}>
                <div class="form-group">
                  <label>
                    {t("loginForm.lableTxt2")} <sup>*</sup>
                  </label>
                  <input
                    className="form-control"
                    placeholder={t("loginForm.lableTxt2")}
                    type="password"
                    name="battlePassword"
                    onChange={handleChangePass}
                  />
                  <p className="text-danger">{errors?.password}</p>
                </div>
                {!from && from !== "battle" && from !== "battlelist" && (
                  <div class="form-group">
                    <label>
                      {t("form.cnfrmPswrd")} <sup>*</sup>
                    </label>
                    <input
                      className="form-control"
                      placeholder={t("loginForm.lableTxt2")}
                      type="password"
                      name="battleConfirmPass"
                      onChange={handleChangePass}
                    />
                    <p className="text-danger">{errors?.confirmPassword}</p>
                  </div>
                )}

                <div class="form-group text-center mt-5">
                  <button type="submit" class="cmn-btn btn-style">
                    <span> {t("form.saveTxt")}</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default BattlePassword;
