import { withoutAuth } from '../config/axios'
import * as Yup from 'yup'

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const passwordRegExp =
  /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
const emailRegExp = /^[A-Za-z0-9.]{2,}@[A-Za-z0-9]{3,}[.]{1}[A-Za-z]{2,6}$/
const nameRegExp = /^[A-Z a-z]{3,}$/

//for adding Custom method in yup
Yup.addMethod(Yup.string, 'checkUsernameExits', function (errorMessage) {
  return this.test(`test-user-`, errorMessage, async function (value) {
    // console.log('this===>>', this, value)
    const { path, createError } = this
    // call api 
    const payload = {
      type: path,
      value: value,
    }
    const res = await withoutAuth().post('/api/auth/checkUserExists', payload)
    // console.log('ressssss', res)
    const { code } = res.data

    return code === 200 || createError({ path, message: errorMessage })
  })
})

const nameValidationYup = () => {
  let result = Yup.string()
    .required('This field is required')
    .min(3, 'character length must be greater than 2')
    .max(25, 'character length must be less than 16')
    .matches(nameRegExp, 'Only Character Allow')

  return result
}

const emailValidation = () => {
  let result = Yup.string()
    .required('This field is required')
    .matches(emailRegExp, `Email is not valid`)
    .checkUsernameExits('Email already exists.')
  return result
}
const passwordValidation = () => {
  let result = Yup.string()
    .required('This field is required')
    .matches(
      passwordRegExp,
      'Password must be at least 8 characters containing 1 Upper Case, 1 Lower Case, 1 Special Character & 1 Number'
    )
  return result
}

const numberValidation = () => {
  let result = Yup.string()
    .required('This field is required')
    .matches(phoneRegExp, 'Phone number is not valid')
    .min(8, 'Phone number must contain 8 digits only')
    .max(8, 'Phone number must contain 8 digits only')
    .checkUsernameExits('Phone number already exists.')
  return result
}

const fieldValidation = () => {
  let result = Yup.string().required('This field is required')
  return result
}

const confirmPassword = () => {
  let result = Yup.string()
    .oneOf([Yup.ref('password'), null], 'Password does not match')
    .required('This field is required')
  return result
}

const userNameValidation = () => {
  let result = Yup.string()
    .required('This field is required')
    .min(6, 'Username is 6-20 characters long')
    .max(20, 'Username is too long')
    .checkUsernameExits('Username already exists.')
  return result
}

export const yupRegValidation = () => {
  const schema = Yup.object().shape({
    firstname: nameValidationYup(),
    lastname: nameValidationYup(),
    username: userNameValidation(),
    email: emailValidation(),
    password: passwordValidation(),
    mobile: numberValidation(),
    confirmPassword: confirmPassword(),
    governorate: fieldValidation(),
  })
  return schema
}

export const yupLoginValidation = () => {
  const schema = Yup.object().shape({
    email: fieldValidation(),
    password: fieldValidation(),
  })
  return schema
}

export const yupFgetPassValidation = () => {
  const schema = Yup.object().shape({
    email: fieldValidation(),
  })
  return schema
}

export const yupRsetPassValidation = () => {
  const schema = Yup.object().shape({
    password: passwordValidation(),
    confirmPassword: confirmPassword(),
  })
  return schema
}

export const profileValidate = () => {
  const schema = Yup.object().shape({
    firstname: fieldValidation(),
    lastname: fieldValidation(),
    email: fieldValidation(),
    // mobile:numberValidation(),
  })

  return schema
}

export const addressValidation = () => {
  const schema = Yup.object().shape({
    address: fieldValidation(),
    zipCode: fieldValidation(),
    city: fieldValidation(),
    country: fieldValidation(),
    state: fieldValidation(),
  })
  return schema
}

export const validatePassWord = (values) => {
  // console.log('vvava=>', values)
  let errors = {};
  let isValid = true;
  if (!values.password) {
    errors.password = 'This field is required';
    isValid = false;
  } else if (values.password.length < 5) {
    errors.password = 'Password should be greater than 5 digist';
    isValid = false;
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = 'This field is required';
    isValid = false;
  } else if (values.password !== values.confirmPassword) {
    errors.confirmPassword = 'Confirm password not matched';
    isValid = false;
  }
  // console.log('errors=>', errors)
  return { isValid: isValid, errors: errors };
};