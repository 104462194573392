import React, { useState, useEffect } from "react";
import Layout from "../layout/layout";
import { toast } from "react-toastify";
import FourRoulette from "../unboxing/fourRouletteGame";
import { queryString } from "../../utils/useFunc";
import ThreeRoulette from "../unboxing/threeRouletteGame";
import TwoRoulette from "../unboxing/twoRouletteGame";
import { useDispatch, useSelector } from "react-redux";
import {
  getPlayBattleData,
  updateJoinGameData,
  currentRollBoxItemData,
  updateUserData,
} from "../../reducers/userReducer";
import TwoRouletteResult from "./twoRouletteResult";
import ThreeRouletteResult from "./threeRouletteResult";
import FourRouletteResult from "./fourRouletteResult";
import { socket } from "../../config/socket";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Play from "../../assets/auido/boxispin.mp3";
import winPlay from "../../assets/auido/win_cash.mp3";
import { useTranslation } from "react-i18next";
import BattleCanclePopup from "../PopUp/battleCanclePopup";
import BattlePassword from "./battlePassword";
import { Helmet } from "react-helmet";
const WatchBattle = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = queryString();
  const result = useSelector((state) => state.user);
  const { activBattleData, currentBoxItems } = result || {};
  const { userDetails } = result || {};
  const { _id: userId, walletAmount } = userDetails || {};
  const {
    players,
    joinUser,
    totalbattleCost,
    round,
    status,
    _id: battleId,
  } = activBattleData || {};
  const testArr =
    joinUser && joinUser.slice().sort((a, b) => a.position - b.position);
  const [openModal, setOpenModal] = useState(false);
  const [winner2, setWinner2] = useState(false);
  const [spinvalue, setSpinValue] = useState(0);
  const [spintime, setSpinTime] = useState(0);
  const [active] = useState("3");
  const [smallPlaceholder, setSmallPlaceholder] = useState(true);
  const [winItem, setWinItem] = useState([]);
  const [winplaySound, setWinPlaySound] = useState(false);
  const [playSound, setPlaySound] = useState(false);
  const [currentRoll, setCurrentRoll] = useState("");
  const [activeBattleObj, setActiveBattleObj] = useState({});
  const [show, setShow] = useState(false);
  const [drowTime, setDrowTime] = useState(0);
  const [drowStatus, setDrowStatus] = useState(false);
  const [joinBot, setJoinBot] = useState(false);
  const [showRemider, setShowReminder] = useState(false);
  const [counter, setCounter] = useState(null);
  useEffect(() => {
    const audioElement = document.getElementsByClassName("spinAudio")[0];
    if (joinBot) {
      audioElement.play();
    }
  }, [joinBot]);
  useEffect(() => {
    setShow(false);
    if (id) {
      //console.log('pppppppp')
      socket.emit("gamePage", {
        gameId: id,
        userId,
      });
      //socket.emit('testResult', {});
      //dispatch(getPlayBattleData(id));
    }
  }, [id, userId]);
  useEffect(() => {
    if (id) {
      socket.on("noBattle", (data) => {
        return history.push("/battles");
      });
      socket.on("updatedRoom", (data) => {
        //console.log('datadatadatadatadata=>', data)
        dispatch(updateJoinGameData(data));
      });
      socket.on("onPlayAction", (data) => {
        // console.log('game statetreddd');
      });
      socket.on("updateCurrentRoll", (data) => {
        setCurrentRoll(data);
      });
      socket.on("updateJoinUserData", (data) => {
        // console.log('updateJoinUserData=>', updateUserData)
        dispatch(updateUserData(data));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const handleBattlePlayEvent = (type, loginuserid, batId, pos) => {
    const eventPayload = {
      type,
      loginuserid,
      batId,
      pos,
      totalbattleCost,
      roomId: id,
    };
    if (type === "login") {
      history.push("/?modal=Login ");
      return;
    }
    if (type === "cancle") {
      setOpenModal(true);
      setActiveBattleObj(eventPayload);
      return;
    }
    if (parseFloat(totalbattleCost) > parseFloat(walletAmount)) {
      return toast.error(t("toast.walleterror"), {
        toastId: "checkBlance",
      });
    }
    socket.emit("updateJoinRoomEvent", eventPayload); // after all user join play auto game
    //dispatch(battlePlayEvent(eventPayload));
  };

  useEffect(() => {
    let timer;
    const handleBotPlayReminder = (id, num) => {
      let intervalTimer = num;
      setShowReminder(true);
      setCounter(intervalTimer);
      if (timer) clearInterval(timer);
      timer = setInterval(() => {
        intervalTimer -= 1;
        if (intervalTimer === 1) {
          setJoinBot(true);
        }
        if (intervalTimer < 0) {
          clearInterval(timer);
          setShowReminder(false);
          setJoinBot(false);
        }
        setCounter(intervalTimer);
      }, 1000);
    };
    socket.on("botPlayReminder", handleBotPlayReminder);

    return () => {
      clearInterval(timer);
      setJoinBot(false);
      socket.off("botPlayReminder", handleBotPlayReminder);
    };
  }, []);

  useEffect(() => {
    socket.on("getBoxItemData", (data) => {
      dispatch(currentRollBoxItemData(data));
    });
    socket.on("callBattlePlayAction", (data) => {
      setSmallPlaceholder(false);
      setWinItem(data);
      handlespinvalue(data);
    });
    socket.on("stopGame", (data) => {
      // console.log('stop  game ===========>', id)
      dispatch(getPlayBattleData(id));
      setTimeout(() => {
        setSmallPlaceholder(true);
      }, 1000);
    });
    socket.on("updateCancled", (data) => {
      //console.log('updateCancled=>', data)
      // dispatch(updateUserData(data));
      toast.success(t("toast.battlemsg"), { toastId: "updatecancled" });
      if (data === "cancled") {
        history.push("/battles");
      }
    });
    socket.on("drowGame", (data) => {
      setDrowStatus(true);
      // console.log('drowGame ===========>', data)
    });
    socket.on("coinflipTime", (data) => {
      setDrowTime(data);
      // console.log('coinflipTime ===========>', data)
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const handlespinvalue = async (boxId) => {
    setWinner2(false);
    setPlaySound(true);
    let element = document.getElementById("Play");
    if (element) {
      element.play();
    }
    setSpinValue(50);
    setSpinTime(8);
    setTimeout(() => {
      setSpinTime(0);
      setTimeout(() => {
        setPlaySound(false);
        setSpinValue(0);
        setWinPlaySound(true);
        let element2 = document.getElementById("winPlay");
        if (element2) {
          element2.play();
        }
      }, 6500);
    }, 1000);
  };
  const handleConfirm = () => {
    // cancel battle
    socket.emit("updateJoinRoomEvent", activeBattleObj);
    //dispatch(battlePlayEvent(activeBattleObj));
  };
  const onCloseModal = () => {
    setOpenModal(false);
  };
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Watch Battles | Cilycase" />
        <title>Watch Battles | Cilycase</title>
      </Helmet>
      <Layout>
        <div className="watch-battle-page">
          <div className="container">
            <section className="position-relative">
              <div className="battles-section">
                <div className="row battles-top-header">
                  <div className="col-lg-12">
                    {showRemider ? (
                      <h1 class="section-title">
                        <span class="section-title-arrow-left"></span>
                        <span class="section-title-text">
                          Bot Joining -
                          <span className="bot-counter">{counter}</span>
                        </span>
                        <span class="section-title-arrow-right"></span>
                      </h1>
                    ) : (
                      <h1 class="section-title">
                        <span class="section-title-arrow-left"></span>
                        <span class="section-title-text">
                          {t("openBoxes.battleOpening")}
                        </span>
                        <span class="section-title-arrow-right"></span>
                      </h1>
                    )}
                  </div>
                  <div className="col-md-12">
                    <div className="no-of-plays-section">
                      {!currentRoll && status === "opened" && (
                        <div className="no-of-plays next-plays">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            class=""
                            viewBox="0 1 448 515">
                            <path d="M223.554,8.667 7.048,133.667 7.048,383.667 223.554,508.667 440.061,383.667 440.061,133.667z"></path>
                          </svg>
                        </div>
                      )}

                      {round &&
                        [...Array(parseInt(round))].map(
                          (elementInArray, index) => {
                            return (
                              <>
                                {currentRoll &&
                                parseInt(currentRoll) === parseInt(index + 1) &&
                                status !== "finshied" ? (
                                  <div className="no-of-plays next-plays">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      version="1.1"
                                      class=""
                                      viewBox="0 1 448 515">
                                      <path d="M223.554,8.667 7.048,133.667 7.048,383.667 223.554,508.667 440.061,383.667 440.061,133.667z"></path>
                                    </svg>
                                    <span>{currentRoll}</span>
                                  </div>
                                ) : (
                                  <div className="no-of-plays">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      version="1.1"
                                      class=""
                                      viewBox="0 1 448 515">
                                      <path d="M223.554,8.667 7.048,133.667 7.048,383.667 223.554,508.667 440.061,383.667 440.061,133.667z"></path>
                                    </svg>
                                    <span>{index + 1}</span>
                                  </div>
                                )}
                              </>
                            );
                          }
                        )}
                      {status === "finshied" && (
                        <div className="no-of-plays next-plays">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            class=""
                            viewBox="0 1 448 515">
                            <path d="M223.554,8.667 7.048,133.667 7.048,383.667 223.554,508.667 440.061,383.667 440.061,133.667z"></path>
                          </svg>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div className={`roulette_section roulette-bg-horizontal`}>
              {players === 4 ? (
                <FourRoulette
                  smallPlaceholder={smallPlaceholder}
                  spinvalue={spinvalue}
                  spintime={spintime}
                  activeBoxImg=""
                  preloadBox={currentBoxItems}
                  winItem={winItem}
                  winner2={winner2}
                  active={active}
                  pagetype="battle"
                  joinUser={activBattleData}
                  handleBattlePlayEvent={handleBattlePlayEvent}
                />
              ) : (
                ""
              )}
              {players === 3 ? (
                <ThreeRoulette
                  smallPlaceholder={smallPlaceholder}
                  spinvalue={spinvalue}
                  spintime={spintime}
                  activeBoxImg=""
                  preloadBox={currentBoxItems}
                  winItem={winItem}
                  winner2={winner2}
                  active={active}
                  pagetype="battle"
                  joinUser={activBattleData}
                  handleBattlePlayEvent={handleBattlePlayEvent}
                />
              ) : (
                ""
              )}
              {players === 2 ? (
                <TwoRoulette
                  smallPlaceholder={smallPlaceholder}
                  spinvalue={spinvalue}
                  spintime={spintime}
                  activeBoxImg=""
                  preloadBox={currentBoxItems}
                  winItem={winItem}
                  winner2={winner2}
                  active={active}
                  pagetype="battle"
                  joinUser={activBattleData}
                  handleBattlePlayEvent={handleBattlePlayEvent}
                  drowTime={drowTime}
                  drowStatus={drowStatus}
                />
              ) : (
                ""
              )}
            </div>
            {players === 4 ? (
              <FourRouletteResult joinUser={testArr} round={round} />
            ) : (
              ""
            )}
            {players === 3 ? (
              <ThreeRouletteResult joinUser={testArr} round={round} />
            ) : (
              ""
            )}
            {players === 2 ? (
              <TwoRouletteResult
                joinUser={testArr}
                round={round}
                handleBattlePlayEvent={handleBattlePlayEvent}
                battleId={battleId}
              />
            ) : (
              ""
            )}
            <BattleCanclePopup
              onCloseModal={onCloseModal}
              open={openModal}
              handleConfirm={handleConfirm}
            />
            <audio id="Play" muted={!playSound} src={Play}></audio>
            <audio id="winPlay" muted={!winplaySound} src={winPlay}></audio>
            <audio className="spinAudio" muted={!setJoinBot}>
              <source
                src="https://s3.us-east-2.amazonaws.com/versuz.gg/audio/bot_join.mp3"
                type="audio/mpeg"
              />
            </audio>
            <BattlePassword show={show} from="battle" />
          </div>
        </div>
      </Layout>
    </>
  );
};
export default WatchBattle;
