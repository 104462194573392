import { withoutAuth } from "../config/axios";
import moment from "moment";
const EmailRegex =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// const pass = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d][A-Za-z\d!@#$%^&*()_+]{5,25}$/
const pass = /\d/;

export const shoppingValidation = (value, t) => {
  // console.log('value=>', value)
  let error = {};
  let isValid = true;
  Object.keys(value).forEach((el) => {
    if (el !== "status" && !value[el]) {
      error[el] = t("errors.isRequired");
      isValid = false;
    }
  });
  return { error, isValid };
};

const ageValidation = (val, t) => {
  let error = "";
  // const selectedDate =  new Date(val).getDate()
  //    const currentDate = new Date().getDate();
  //  console.log('currentDate',currentDate,'selectedDate',selectedDate)
  //  const selectedYear =  new Date(val).getFullYear()
  //  const currentYear = new Date().getFullYear();
  //  const selectedMonth =  new Date(val).getMonth()
  //  const currentMonth = new Date().getMonth();
  const totalDays = moment(new Date()).diff(moment(new Date(val)), "days");
  if (parseInt(totalDays) >= 6570) {
    error = "";
  } else {
    error = t("toast.age18");
  }

  return error;
};

export const registrationValidation = async (val, key, t) => {
  console.log("key--", key, "value--", val);
  let error = {};
  const payload = {
    type: key,
    value: val,
  };
  if (!val) {
    error[key] = t("errors.isRequired");
  } else if (key === "username" && val) {
    const res = await withoutAuth().post("/api/auth/checkUserExists", payload);
    const { code, msg } = res.data;
    if (code === 403) {
      error[key] = msg;
    } else {
      error[key] = "";
    }
  } else if (key === "email" && val) {
    if (!EmailRegex.test(val)) {
      error[key] = t("errors.isInvalidEmail");
    } else {
      const res = await withoutAuth().post(
        "/api/auth/checkUserExists",
        payload
      );
      const { code, msg } = res.data;
      if (code === 403) {
        error[key] = msg;
      } else {
        error[key] = "";
      }
    }
  } else if (key === "password" && val && !pass.test(val)) {
    error[key] = t("errors.isInvalidEmail");
  } else if (key === "password" && val && val.length < 6 && pass.test(val)) {
    error[key] = t("errors.isInvalidEmail");
  } else if (key === "mobile" && val) {
    if (val.length < 8) {
      error[key] = t("toast.mobilemust8dig");
    } else {
      const res = await withoutAuth().post(
        "/api/auth/checkUserExists",
        payload
      );
      const { code } = res.data;
      if (code === 403) {
        error[key] = t("toast.mobileExist");
      } else {
        error[key] = "";
      }
    }
  } else if (key === "dateOfBirth" && val) {
    error[key] = ageValidation(val, t);
  } else {
    // console.log('sdgashdgsahgdhg')
    error[key] = "";
  }
  return error;
};

export const validateStepRegister = async (values, step, t) => {
  console.log("values--", values, "step--", step);
  let errors = {};
  let isValid = true;
  if (step === 1) {
    if (!values.username) {
      errors.username = t("errors.isRequired");
      errors.key = "isRequired";
      isValid = false;
    } else if (values.username) {
      const payload = {
        type: "username",
        value: values.username,
      };
      const res = await withoutAuth().post(
        "/api/auth/checkUserExists",
        payload
      );
      const { code, msg } = res.data;
      if (code === 403) {
        errors.username = msg;
        errors.key = "isUserExists";
      }
    }
    if (!values.mobile) {
      errors.mobile = t("errors.isRequired");
      isValid = false;
    } else if (values.mobile) {
      const payload = {
        type: "mobile",
        value: values.mobile,
      };
      if (values.mobile.length !== 12) {
        errors.mobile = t("toast.mobilemust8dig");
        isValid = false;
      } else {
        const res = await withoutAuth().post(
          "/api/auth/checkUserExists",
          payload
        );
        const { code, msg } = res.data;
        if (code === 403) {
          errors.mobile = msg;
          isValid = false;
        }
      }
    }
    if (!values.password) {
      errors.password = t("errors.isRequired");
      isValid = false;
    } else if (!pass.test(values.password)) {
      errors.password = t("errors.passMust6");
      isValid = false;
    } else if (values.password.length < 6 && pass.test(values.password)) {
      errors.password = t("errors.passMust6");
      isValid = false;
    }

    if (!values.cpassword) {
      errors.cpassword = "This field is required";
      isValid = false;
    } else if (values.password !== values.cpassword) {
      errors.cpassword = "Confirm password not matched";
      isValid = false;
    }
  }
  if (step === 2) {
    if (!values.firstname) {
      errors.firstname = t("errors.isRequired");
      isValid = false;
    }
    if (!values.lastname) {
      errors.lastname = t("errors.isRequired");
      isValid = false;
    }
    // if (!values.governorate) {
    //   errors.governorate = t("errors.isRequired");
    //   isValid = false;
    // }
    if (!values.dateOfBirth) {
      errors.dateOfBirth = t("errors.isRequired");
      isValid = false;
    } else {
      const comingError = ageValidation(values?.dateOfBirth, t);
      // console.log(';errors.dateOfBirth', errors.dateOfBirth)
      if (comingError) {
        errors.dateOfBirth = comingError;
        isValid = false;
      }
    }
  }
  if (step === 3) {
    if (!values.email) {
      errors.email = t("errors.isRequired");
      isValid = false;
    } else if (values.email) {
      const payload = {
        type: "email",
        value: values.email,
      };
      if (!EmailRegex.test(values.email)) {
        errors.email = t("errors.isInvalidEmail");
        isValid = false;
      } else {
        const res = await withoutAuth().post(
          "/api/auth/checkUserExists",
          payload
        );
        const { code, msg } = res.data;
        if (code === 403) {
          errors.email = msg;
          isValid = false;
        }
      }
    }
  }

  // console.log('sss===>>', isValid, errors)
  return { isValid: isValid, errors: errors };
};

export const generateOTP = () => {
  var digits = "0123456789";
  let OTP = "";
  for (let i = 0; i < 6; i++) {
    OTP += digits[Math.floor(Math.random() * 10)];
  }
  return OTP;
};

export const updatePassWordValidation = (name, values, t) => {
  const errors = {};
  let isValid = true;

  const namePass = name === "newPassword" || name === "password";
  const passVliad = pass.test(values);
  // console.log('passValid', passVliad, name)

  if (!values) {
    errors[name] = t("errors.isRequired");
    isValid = false;
  } else if (namePass && (!passVliad || values.length < 6)) {
    errors[name] = t("errors.passMust6");
    isValid = false;
  }
  return { isValid: isValid, errors: errors };
};

export const checkValidateField = (name, values, t) => {
  const errors = {};
  let isValid = true;

  if (!values) {
    errors[name] = t("errors.isRequired");
    isValid = false;
  }

  return { isValid: isValid, errors: errors };
};

export const submitAddressValidation = (values, t) => {
  const errors = {};
  let isValid = true;
  Object.keys(values).forEach((el) => {
    if (!values[el] && el !== "status") {
      isValid = false;
      errors[el] = t("errors.isRequired");
    }
    // else{
    //     let err=ageValidation(values.dateOfBirth);
    //     if(err)
    //     {
    //       errors['dateOfBirth'] =err;
    //       isValid=false;
    //     }
    //  }
  });
  return { isValid: isValid, errors: errors };
};

export const submitPassWordValidation = (values, t) => {
  const errors = {};
  let isValid = true;

  if (!values.currentPassword) {
    errors["currentPassword"] = t("errors.isRequired");
    isValid = false;
  }
  if (!values?.newPassword) {
    errors["newPassword"] = t("errors.isRequired");
    isValid = false;
  } else if (!pass.test(values?.newPassword)) {
    errors["newPassword"] = t("errors.isInvalidPassword");
    isValid = false;
  }
  return { isValid: isValid, errors: errors };
};

export const submitMobileValidation = async (val, key, t) => {
  let error = {};
  let isValid = true;

  const payload = {
    type: key,
    value: val,
  };

  if (!val) {
    error[key] = t("errors.isRequired");
  }

  if (key === "mobile" && val) {
    if (val.length < 8) {
      error[key] = t("toast.mobilemust8dig");
      isValid = false;
    } else {
      const res = await withoutAuth().post(
        "/api/auth/checkUserExists",
        payload
      );
      const { code, msg } = res.data;
      if (code === 403) {
        error[key] = msg;
        isValid = false;
      }
    }
  }
  return { isValid: isValid, errors: error };
};

export const submitEmailValidation = async (val, key, t) => {
  let error = {};
  let isValid = true;

  const payload = {
    type: key,
    value: val,
  };

  if (!val) {
    error[key] = t("errors.isRequired");
  }

  if (key === "email" && val) {
    if (!EmailRegex.test(val)) {
      error[key] = t("errors.isInvalidEmail");
    } else {
      const res = await withoutAuth().post(
        "/api/auth/checkUserExists",
        payload
      );
      const { code, msg } = res.data;
      if (code === 403) {
        error[key] = msg;
        isValid = false;
      }
    }
  }
  return { isValid: isValid, errors: error };
};

export const isEmailInValid = (email, t) => {
  let newErr = {};

  if (!email) {
    newErr.email = t("errors.isRequired");
  } else if (!EmailRegex.test(email)) {
    newErr.email = t("errors.isInvalidEmail");
  } else {
    return false;
  }

  return newErr;
};
