import React from "react";
// import { Link } from "react-router-dom";
import Layout from "../layout/layout";
import BattleHistoryTable from "./battleHistoryTable";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "./battles.css";

const BattleHistory = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const handleBackPage = () => {
    history.push("/battles");
  };
  return (
    <>
      <meta property="og:title" content="Battle History | Cilycase" />
      <title>Battle History | Cilycase</title>
      <Layout>
        <div className="battles-page">
          <div className="container ">
            <section className="position-relative pb-120">
              <div className="battles-section battle-history-page">
                <div className="row battles-top-header">
                  <div className="col-lg-12">
                    <h1 class="section-title">
                      <span class="section-title-arrow-left"></span>
                      <span class="section-title-text">
                        {t("openBoxes.battleHistoryBtn")}
                      </span>
                      <span class="section-title-arrow-right"></span>
                    </h1>
                  </div>

                  <div
                    className="col-lg-6 header-content"
                    onClick={handleBackPage}
                  >
                    <span>
                      <i class="las la-arrow-left"></i> Back
                    </span>
                  </div>
                </div>
              </div>
              <div className="history-table">
                <BattleHistoryTable />
              </div>
            </section>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default BattleHistory;
