import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import Deposit from "./deposit";
import WithDraw from "./withDraw";
import { useTranslation } from "react-i18next";
import Layout from "../layout/layout";
import "./withdraw.css";
import { Helmet } from "react-helmet";

const WithdrawDeposit = () => {
  const [key, setKey] = useState("deposit");
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <meta property="og:title" content="Withdraw Deposit | Cilycase" />
        <title>Withdraw Deposit | Cilycase</title>
      </Helmet>
      <Layout>
        <div className="container">
          <div className="widthdraw-deposit-page">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
            >
              <Tab eventKey="deposit" title={t("proTransactions.depoTxt")}>
                <Deposit />
              </Tab>
              <Tab eventKey="withdraw" title={t("proTransactions.withdTxt")}>
                <WithDraw />
              </Tab>
            </Tabs>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default WithdrawDeposit;
