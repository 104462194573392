/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const UserRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        let path =rest?.path !=='/profile' ?<Redirect to="/404" />:<Redirect to="/?modal=Login" />;
          if (localStorage.getItem('inboxi#@user')) {
              path = <Component {...props} {...rest} />;
            }
        return path;
      }}
    />
  );
};

export default UserRoute;
