import React from 'react'
import RollItems from './rollItems'
const OneRoulette = ({ spinvalue, spintime, preloadBox, winItem,active }) => {
  return (
    <div className={`roulette_container ${spinvalue > 0 ? `` : `disable`} `}>
      <div className='roulette-container-shadow--left'></div>
      <div className='roulette-indicator roulette-indicator--top'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          xlink='http://www.w3.org/1999/xlink'
          width='512'
          height='512'
          viewBox='0 0 512 512'>
          <path
            id='arrow'
            className='cls-1'
            d='M32.017,443.987l80.255-187.5L32.017,68.985,480,256.485Z'
            style={{ fill: '#ee0065' }}></path>
        </svg>
      </div>
      <div className='roulette_wrapper'>
        <div
          className='roulette'
          style={{
            transform: `translate3d(${-spinvalue}%, 0px, 0px) translateX(0)`,
            transitionDuration: `${spintime}s`,
          }}>
          <RollItems winItem={winItem[0]} itemsArr={preloadBox} active={active} />
        </div>
      </div>
      <div className='roulette-indicator roulette-indicator--bottom'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          xlink='http://www.w3.org/1999/xlink'
          width='512'
          height='512'
          viewBox='0 0 512 512'>
          <path
            id='arrow'
            className='cls-1'
            d='M32.017,443.987l80.255-187.5L32.017,68.985,480,256.485Z'
            style={{ fill: '#e79b31' }}></path>
        </svg>
      </div>
      <div className='roulette-container-shadow--right'></div>
    </div>
  )
}
export default OneRoulette
