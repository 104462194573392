// import React from 'react'
import { Modal } from "react-bootstrap";
import "./popup.css";
import { useTranslation } from "react-i18next";

const BattleCanclePopup = ({ onCloseModal, open, handleConfirm }) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={open}
      centered
      onHide={() => onCloseModal("change")}
      className={`modal fade login-popup battle-cancel`}
      id="loginModal"
      tabindex="1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-content modal_contents">
        <div class="modal-body">
          <div class="account-form-area">
            <button
              onClick={() => onCloseModal("change")}
              type="button"
              class="close-btn"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i class="las la-times"></i>
            </button>

            <div class="form-group text-center mt-5">
              <h3 class="ar-reg-title text-center pop_head">
                {t("form.cancelTxt")}
              </h3>
              <div className="popup__buttons">
                <button
                  type="submit"
                  class="cmn-btn pop_btn bor_btn"
                  onClick={() => onCloseModal("change")}
                >
                  {t("model.addressTxt6")}
                </button>
                <button
                  type="submit"
                  class="cmn-btn pop_btn pop_btns"
                  onClick={handleConfirm}
                >
                  {t("model.addressTxt7")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default BattleCanclePopup;
