import config from './config.json';

// console.log('node==>', process.env.REACT_APP_MODE)

export const server = config[process.env.REACT_APP_MODE];

const envData = config[process.env.REACT_APP_MODE];

export const { serverPath, clientPath } = envData;
// export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
