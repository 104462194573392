import React from 'react'
import { handleBoxPrice } from "../../utils/useFunc";
import { Link } from "react-router-dom";

const MysterBox = ({ boxesList, t }) => {
  return (
    <div className="mystery-box-section1 ">
    <div className="container">
      <div
        className="ar-boxes-row row wow fadeInUp "
        data-wow-duration="0.5s"
        data-wow-delay="0.3s">
        <div className="col-lg-12">
          <div className="row mb-none-30">
            {boxesList?.length > 0 &&
              boxesList?.slice(0, 8)?.map((el) => {
                const boxPrice = handleBoxPrice(
                  el.boxItems,
                  el?.profitMargin
                );
                return (
                  <>
                    {parseFloat(boxPrice) > 0 ? (
                      <>
                        <div className="col-xl-3 col-md-4 col-sm-6 contest-card-premium mb-30">
                          <div className="contest-premium">
                            {/* <div
                              className="contest-num"
                              style={{
                                background: `${el?.color}`,
                              }}>
                              <h4 className="number">
                                {el?.specialTag || ""}
                              </h4>
                            </div> */}
                            <div className="contest-premium-img">
                              <Link to={`/boxdetails?id=${el._id}`}>
                                <img src={el.image} alt="box" />
                              </Link>
                            </div>
                            <p className="premium-card__name">
                              {t(`${el.name}`)}
                            </p>
                            <div className="premium-card__footer">
                              <div className="premium-card-btn">
                                <a
                                  href={`/boxdetails?id=${el._id}`}
                                  className="cmn-btn details-btn style--three btn--sm btn-style">
                                  <span>Open Case for  <span className="contest-card__price1">
                                    $ {boxPrice}
                                  </span> </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                );
              })}
          </div>
        </div>
      </div>
    </div>
    {/* {boxesList?.length > 8 && (
      <div className=" d-flex align-items-center justify-content-center mt-5 show-all-grid">
        <Link
          to="/boxes"
          className="d-flex align-items-center justify-content-center">
          Show All
        </Link>
      </div>
    )} */}
  </div>
  )
}

export default MysterBox