import React from 'react'
import RollItems from './rollItems'
import SmallPlaceHolder from './smallPlaceHolder'
import shapecnew from '../../assets/images/game/shape/cnew.png'
import shapeinew from '../../assets/images/game/shape/inew.png'
import shapelnew from '../../assets/images/game/shape/lnew.png'
//import WinnerHorizontal from './winnerhorizontal'
const ThreeRoulette = ({ spinvalue, spintime, smallPlaceholder,activeBoxImg,preloadBox, winItem,winner2, active ,pagetype,joinUser, handleBattlePlayEvent}) => {
  return (
    <div className='three-roulette'>
      <div className='two-roulette-content'>
        <div className='roulette-indicator roulette-indicator--top'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            xlink='http://www.w3.org/1999/xlink'
            width='512'
            height='512'
            viewBox='0 0 512 512'>
            <path
              id='arrow'
              className='cls-1'
              d='M32.017,443.987l80.255-187.5L32.017,68.985,480,256.485Z'
              style={{ fill: '#ee0065' }}></path>
          </svg>
        </div>
        <div
          className={`roulette_container ${spinvalue > 0 ? `` : `disable`} `}>
          <div
            className={`roulette_wrapper ${
              smallPlaceholder ? `default-placeholder` : ``
            }`}>
            <SmallPlaceHolder displayProduct={shapecnew} pagetype={pagetype} joinUser={joinUser} position ='0' handleBattlePlayEvent ={handleBattlePlayEvent}/>
            {/* {winner2 && active === '3'? (
                <WinnerHorizontal winItem={winItem[0]} />
              ) : (
                ''
              )} */}
            <div
              className={`roulette ${winner2 ? `light` : ``}`}
              style={{
                transform: `translate3d(0px, ${-spinvalue}%, 0px) translateX(0)`,
                transitionDuration: `${spintime}s`,
              }}>
               <RollItems winItem={winItem[0]} itemsArr={preloadBox} />
            </div>
          </div>
        </div>

        <div className='roulette_marker1'></div>

        <div
          className={`roulette_container ${spinvalue > 0 ? `` : `disable`} `}>
          <div
            className={`roulette_wrapper ${
              smallPlaceholder ? `default-placeholder` : ``
            }`}>
            <SmallPlaceHolder displayProduct={shapeinew} pagetype={pagetype} joinUser={joinUser} position ='1' handleBattlePlayEvent ={handleBattlePlayEvent}/>
            {/* {winner2 && active === '3'? (
                <WinnerHorizontal winItem={winItem[1]} />
              ) : (
                ''
              )} */}
            <div
             className={`roulette ${winner2 ? `light` : ``}`}
              style={{
                transform: `translate3d(0px, ${-spinvalue}%, 0px) translateX(0)`,
                transitionDuration: `${spintime}s`,
              }}>
              <RollItems winItem={winItem[1]} itemsArr={preloadBox} />
            </div>
          </div>
        </div>

        <div className='roulette_marker2'></div>

        <div
          className={`roulette_container ${spinvalue > 0 ? `` : `disable`} `}>
          <div
            className={`roulette_wrapper ${
              smallPlaceholder ? `default-placeholder` : ``
            }`}>
            <SmallPlaceHolder displayProduct={shapelnew} pagetype={pagetype} joinUser={joinUser} position ='2' handleBattlePlayEvent ={handleBattlePlayEvent}/>
            {/* {winner2 && active === '3'? (
                <WinnerHorizontal winItem={winItem[2]} />
              ) : (
                ''
              )} */}
            <div
              className={`roulette ${winner2 ? `light` : ``}`}
              style={{
                transform: `translate3d(0px, ${-spinvalue}%, 0px) translateX(0)`,
                transitionDuration: `${spintime}s`,
              }}>
               <RollItems winItem={winItem[2]} itemsArr={preloadBox} />
            </div>
          </div>
        </div>

        <div className='roulette-indicator roulette-indicator--bottom'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            xlink='http://www.w3.org/1999/xlink'
            width='512'
            height='512'
            viewBox='0 0 512 512'>
            <path
              id='arrow'
              className='cls-1'
              d='M32.017,443.987l80.255-187.5L32.017,68.985,480,256.485Z'
              style={{ fill: '#ee0065' }}></path>
          </svg>
        </div>
      </div>
    </div>
  )
}
export default ThreeRoulette
