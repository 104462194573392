/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Layout from "../layout/layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./productdetails.css";
import { Link } from "react-router-dom";
import { queryString } from "./../../utils/useFunc";
import { useSelector, useDispatch } from "react-redux";
import {
  getProductById,
  addToCart,
  getBoxesByProductId,
} from "../../reducers/userReducer";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";
import { clientPath } from "../../config/keys";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import cart from "../../assets/images/cart.png";
import Draggable from "react-draggable";
import { Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
// import Loader from "../Loader/loader";
import { handleBoxPrice } from "../../utils/useFunc";
import { t } from "i18next";

const Productdetails = () => {
  const { id } = queryString();
  const history = useHistory();
  const producturl = `${clientPath}/?id=${id}`;
  const dispatch = useDispatch();
  const result = useSelector((state) => state.user);
  const { productDetails, allCartsProducts, productBoxes } = result || {};
  const [productImages, setProductImage] = useState([]);
  const [selectQuantity, setSelectQuantity] = useState(1);
  const [showAddCart, setShowAddCart] = useState(false);
  const [objC, setObj] = useState({});
  const [activeDrags, setActiveDrags] = useState(0);
  const [addQuantitynow, setaddQuantitynow] = useState(0);
  const token = localStorage.getItem("inboxi#@user");
  const cartData = token
    ? allCartsProducts
    : JSON.parse(localStorage.getItem("addtoCarts"));
  const [cartState, setcartState] = useState([]);
  const [activeTab, setActiveTab] = useState("details");
  useEffect(() => {
    setcartState(cartData);
  }, [allCartsProducts]);
  useEffect(() => {
    dispatch(getBoxesByProductId(id));
  }, [id]);

  // const handleChange = (e) =>{
  //   setSelectQuantity(e.target.value)
  // }
  // const actualimage = productDetails?.image
  // console.log('actualImage',actualimage)

  // console.log('productImages',productImages)
  // const settings = {
  //     customPaging: function (i) {
  //       return (
  //         <div>

  //           <img
  //             src={Car}
  //             alt=""
  //             style={{
  //               height: "50px",
  //               width: "50px",
  //               objectFit: "cover",
  //               borderRadius: "10px"
  //             }}
  //           />
  //         </div>
  //       );
  //     },

  //     dots: true,
  //     cssEase: "linear",
  //     dotsClass: "slick-dots slick-thumb",
  //     infinite: true,
  //     speed: 500,
  //     slidesToShow: 1,
  //     slidesToScroll: 1
  //   };

  useEffect(() => {
    dispatch(getProductById(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    let productI = [productDetails.bannerImage];
    if (productDetails?.image?.length > 0) {
      productI = [...productI, ...productDetails?.image];
    }
    setProductImage(productI);
    const copy = { ...productDetails };
    setObj(copy);
  }, [productDetails]);

  const handleQuantity = (type, enable) => {
    if (
      (type === "minus" && selectQuantity > 1) ||
      (!enable && type === "minus" && selectQuantity > 1)
    ) {
      setSelectQuantity(selectQuantity - 1);
    } else if (type === "plus") {
      if (enable) {
        if (objC?.totalProduct > selectQuantity) {
          setSelectQuantity(selectQuantity + 1);
        }
      } else {
        console.log("noooo");
        setSelectQuantity(selectQuantity + 1);
      }
    }

    // else if (type === "plus" && objC?.totalProduct >= selectQuantity) {

    // } else if (type === "plus" && objC?.totalProduct === 0 && selectQuantity) {
    //   setSelectQuantity(selectQuantity + 1);
    // }
  };

  // const [s,setS] = useState(false);

  const handleAddToCart = async (e, type, isenable, total) => {
    e.preventDefault();
    // addQuantitynow, setaddQuantitynow
    //  setisCart(true); setSelectQuantity  setSelectQuantity(selectQuantity + 1);
    //console.log('addQuantitynow===>', addQuantitynow, selectQuantity);
    const newCount = addQuantitynow + selectQuantity;
    setaddQuantitynow(newCount);
    if (isenable && newCount > total) {
      return;
    }
    setShowAddCart(true);
    //console.log('eeee ========>', {isenable, total, newCount});
    const payload = {
      productId: objC._id,
      productQuantity: selectQuantity,
    };
    payload.type = type;
    if (!localStorage.getItem("inboxi#@user")) {
      const copy = { ...payload };
      const newPay = {
        bannerImage: objC.bannerImage,
        name: objC.name,
        price: objC.price,
        _id: objC._id,
      };
      copy.productId = newPay;
      const getItem = JSON.parse(localStorage.getItem("addtoCarts"));
      if (getItem) {
        // console.log("getItems", getItem[0].productId._id, copy?.productId);
        const findIndex = getItem?.findIndex(
          (el) => el?.productId._id === copy?.productId?._id
        );
        // console.log("fIndex", findIndex, getItem);
        if (findIndex >= 0) {
          getItem[findIndex].productQuantity += copy?.productQuantity;
        } else {
          getItem.push(copy);
        }
        setcartState(getItem);
        return localStorage.setItem("addtoCarts", JSON.stringify(getItem));
      } else {
        let arr = [];
        arr.push(copy);
        setcartState(arr);
        return localStorage.setItem("addtoCarts", JSON.stringify(arr));
      }
    }

    const res = await dispatch(addToCart(payload));
    const { code } = res?.payload?.resultData?.data;
    if (code === 200 && !type) {
      toast.success(t("toast.addtocart"), { toastId: "cart" });
      setTimeout(() => {
        setShowAddCart(false);
      }, 1000);
    }
    if (type) {
      history.push("/checkout");
    }
  };

  // const handleCheckOut = async(e) =>{
  //   // e.preventDefault();
  //   handleAddToCart(e,'checkout')

  // }

  // useEffect(() =>{
  //   if(s){
  //     console.log('jhhh',s)
  //     setS(false);
  //     dispatch(getCartProducts())

  //   }
  // },[s])

  const settings = {
    customPaging: function (i) {
      return (
        <Link to="#">
          {}
          <img src={productImages[i]} alt="" />
        </Link>
      );
    },
    dots: false,
    cssEase: "linear",
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const totalCartItems = () => {
    if (cartState?.length > 0) {
      const result = cartState.reduce(
        (pre, next) => pre + next?.productQuantity,
        0
      );
      // console.log("result", result);
      return result;
    }
  };
  const onStart = () => {
    setActiveDrags(activeDrags + 1);
  };

  const onStop = () => {
    setActiveDrags(activeDrags - 1);
  };

  const handleCart = () => {
    history.push("/cart");
  };
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      {/* {loading && <Loader />} */}
      <Helmet>
        <meta property="og:title" content="Product Details | Cilycase" />
        <title>Product Details | Cilycase</title>
      </Helmet>
      <Layout>
        <div className="product-details-page">
          <div className="inner-hero-section product-hero-section">
            <div className="container">
              {/* <div className='row'>
                <div className='col-lg-6'>
                  <ul className='page-list'>
                    <li>
                      <a href='index.html'>Home</a>
                    </li>
                    <li>
                      <a href='#0'>Lottery</a>
                    </li>
                    <li className='active'>Contest No: B2T</li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>

          <section className="pb-120 mt-minus-300">
            <div className="container">
              <div className="product-box row justify-content-center">
                <div className="col-lg-6">
                  {/* <div className='clock-wrapper'>
                  <p className='mb-2'>This competition ends in:</p>
                  <div className='clock' data-clock='2020/12/10'></div>
                </div> */}
                </div>
                <div className="col-lg-12 add_to_cart">
                  <img
                    src={objC?.bannerImage}
                    alt="image1"
                    className={`fly-img ${showAddCart ? "fly-to-cart" : ""}`}
                  />
                  <div className="contest-cart">
                    <div className="contest-cart__left">
                      <Slider {...settings}>
                        {/* <div>
                        {console.log('objC',objC?.bannerImage)}
                        <div class='single-slide'>
                          <img src={objC?.bannerImage} alt='image1' />
                        </div>
                      </div> */}
                        {/* <div>
                        <div class='single-slide'>
                          <img src={Car} alt='image2' />
                        </div>
                      </div> */}
                        {productImages?.map((ele) => (
                          <div>
                            <div class="single-slide">
                              <img src={ele} alt="image3" />
                            </div>
                          </div>
                        ))}
                        {/* <div>
                        <div class='single-slide'>
                          <img src={Car} alt='image4' />
                        </div>
                      </div>
                      <div>
                        <div class='single-slide'>
                          <img src={Car} alt='image5' />
                        </div>
                      </div>
                      <div>
                        <div class='single-slide'>
                          <img src={Car} alt='image6' />
                        </div>
                      </div> */}
                      </Slider>
                    </div>
                    {/* contest-cart__left end */}
                    <div className="contest-cart__right">
                      {/* <h4 className='subtitle'>Enter now for a chance to win</h4> */}
                      <h3 className="contest-name">{objC?.name}</h3>
                      <p>{objC?.description}</p>
                      <div className="contest-num">
                        <span>{objC?.brandId?.name}</span>
                      </div>
                      {/* <h4>Tickets Sold</h4> */}
                      {/* <div class='ticket-amount'>
                      <span className='left'>0</span>
                      <span className='right'>29994</span>
                      <div className='progressbar' data-perc='70%'>
                        <div className='bar'></div>
                      </div>
                      <p>Only {objC?.totalProduct} remaining!</p>
                    </div> */}
                      <div className="ticket-price">
                        <span className="amount">$ {objC?.price} </span>
                        {/* <small>Per ticket</small> */}
                      </div>
                      <div className="product-btn">
                        <div className="select-quantity">
                          <span className="caption">
                            {t("boxDetails.orderSumTxt2")}
                          </span>
                          <div className="quantity">
                            <input
                              type="number"
                              min="0"
                              max="100"
                              step="1"
                              value={selectQuantity}
                              // onChange={handleChange}
                              // readonly
                            />
                            <div class="quantity-nav">
                              <div
                                class="quantity-button quantity-down"
                                onClick={() =>
                                  handleQuantity("minus", objC?.enableProduct)
                                }
                              >
                                <i class="las la-minus"></i>
                              </div>
                              <div
                                class="quantity-button quantity-up"
                                onClick={() =>
                                  handleQuantity("plus", objC?.enableProduct)
                                }
                              >
                                <i class="las la-plus"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* select-quantity end */}
                        <div className="mt-sm-0 mt-3 buy_add_btn">
                          {/* <button class='cmn-btn style--three' onClick={handleCheckOut} >
                          Check out
                        </button> */}
                          {objC?.enableProduct && objC?.totalProduct === 0 ? (
                            <p className="error-msg">Out Of Stock!</p>
                          ) : (
                            <button
                              onClick={(e) =>
                                handleAddToCart(
                                  e,
                                  "",
                                  objC?.enableProduct,
                                  objC?.totalProduct
                                )
                              }
                              class="cmn-btn style--three btn-style"
                            >
                              <span>{t("boxDetails.addToCartTxt")}</span>
                            </button>
                          )}
                          {/* {((objC?.enableProduct && objC?.totalProduct > 0) || objC?.enableProduct=== false)?
                          <button
                            onClick={(e)=>handleAddToCart(e,'', objC?.enableProduct,objC?.totalProduct)}
                            class="cmn-btn style--three btn-style"
                          >
                            <span> Add to Cart</span>
                          </button>
                          :
                          
                          } */}
                        </div>
                        {/* <div className='mt-sm-0 mt-3'>
                       
                      </div> */}
                      </div>
                      <ul className="social-links align-items-center">
                        <li>{t("boxDetails.shareTxt")} :</li>
                        <FacebookShareButton
                          url={producturl}
                          quote="Join Cilycase, click on below link"
                          className="fb-share"
                        >
                          <li>
                            <a href="#0">
                              <i class="fab fa-facebook-f"></i>
                            </a>
                          </li>
                        </FacebookShareButton>

                        <TwitterShareButton
                          url={producturl}
                          title={"test"}
                          hashtags={["hashtag1", "hashtag2"]}
                        >
                          <li>
                            <a href="#0">
                              <i class="fab fa-twitter"></i>
                            </a>
                          </li>
                        </TwitterShareButton>

                        <LinkedinShareButton
                          title="Title of the shared page"
                          summary="Product Detail Page"
                          url={producturl}
                        >
                          <li>
                            <a href="#0">
                              <i class="fab fa-linkedin-in"></i>
                            </a>
                          </li>
                        </LinkedinShareButton>
                      </ul>
                    </div>
                    {/* contest-cart__right end */}
                  </div>
                  {/* contest-cart end */}
                </div>
                {/* col-lg-12 end */}
                <div className="col-lg-12">
                  <div className="contest-description desc-contest">
                    <ul
                      className="nav nav-tabs justify-content-center mb-30 border-0"
                      id="myTab"
                      role="tablist"
                    >
                      {/* <li className='nav-item' role='presentation' onClick={()=>handleTabClick('description')}>
                      <a
                        className='cmn-btn active btn-style'
                        id='description-tab'
                        data-toggle='tab'
                        href='#description'
                        role='tab'
                        aria-controls='description'
                        aria-selected='true'>
                        <span>description</span>
                      </a>
                    </li> */}
                      <li
                        className="nav-item boxbottom-text"
                        role="presentation"
                        onClick={() => handleTabClick("details")}
                      >
                        <span className="con-in-box">
                          {t("boxDetails.containedTxt")}
                        </span>
                      </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      {/* <div
                      className={`tab-pane fade ${activeTab === 'description'?'show active':''}`}
                      id='description'
                      role='tabpanel'
                      aria-labelledby='description-tab'>
                      <div className='content-block'>
                        <h3 className='title'>Product Overview</h3>
                        <p>{objC?.description}</p>
                      </div> */}
                      {/* content-block end */}
                      {/* <div className='content-block'>
                        <h3 className='title'>Specifications</h3>
                        <div className='row mb-none-30'>
                          <div className='col-lg-4 col-sm-6 mb-30'>
                            <div className='icon-item'>
                              <div className='icon-item__thumb'>
                                <img
                                  src='assets/images/icon/specification/1.png'
                                  alt='image7'
                                />
                              </div>
                              <div className='icon-item__content'>
                                <p>0-62mph</p>
                                <span>4.0 secs</span>
                              </div>
                            </div>
                          
                          </div>
                          <div className='col-lg-4 col-sm-6 mb-30'>
                            <div className='icon-item'>
                              <div className='icon-item__thumb'>
                                <img
                                  src='assets/images/icon/specification/2.png'
                                  alt='image77'
                                />
                              </div>
                              <div className='icon-item__content'>
                                <p>Top Speed</p>
                                <span>181 mph</span>
                              </div>
                            </div>
                          
                          </div>
                          <div className='col-lg-4 col-sm-6 mb-30'>
                            <div className='icon-item'>
                              <div className='icon-item__thumb'>
                                <img
                                  src='assets/images/icon/specification/3.png'
                                  alt='image65'
                                />
                              </div>
                              <div className='icon-item__content'>
                                <p>Power</p>
                                <span>542 bhp</span>
                              </div>
                            </div>
                      
                          </div>
                          <div className='col-lg-4 col-sm-6 mb-30'>
                            <div className='icon-item'>
                              <div className='icon-item__thumb'>
                                <img
                                  src='assets/images/icon/specification/4.png'
                                  alt='image45'
                                />
                              </div>
                              <div className='icon-item__content'>
                                <p>Displacement</p>
                                <span>4.0ltr</span>
                              </div>
                            </div>
                           
                          </div>
                          <div className='col-lg-4 col-sm-6 mb-30'>
                            <div className='icon-item'>
                              <div className='icon-item__thumb'>
                                <img
                                  src='assets/images/icon/specification/5.png'
                                  alt='image34'
                                />
                              </div>
                              <div className='icon-item__content'>
                                <p>bhp</p>
                                <span>691</span>
                              </div>
                            </div>
                          
                          </div>
                          <div className='col-lg-4 col-sm-6 mb-30'>
                            <div className='icon-item'>
                              <div className='icon-item__thumb'>
                                <img
                                  src='assets/images/icon/specification/6.png'
                                  alt='image23'
                                />
                              </div>
                              <div className='icon-item__content'>
                                <p>Year</p>
                                <span>2019</span>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div> */}
                      {/* </div> */}
                      <div
                        className={`tab-pane fade ${
                          activeTab === "details" ? "show active" : ""
                        }`}
                        id="details"
                        role="tabpanel"
                        aria-labelledby="details-tab"
                      >
                        <div className="content-block">
                          <div className="row mb-none-30">
                            {productBoxes.length > 0 &&
                              productBoxes.map((el) => (
                                <div className="col-lg-4 col-sm-6 mb-30">
                                  <Link to={`/boxdetails?id=${el?._id}`}>
                                    <div className="icon-item">
                                      <div className="icon-item__thumb">
                                        <img src={el.image} alt="image7" />
                                      </div>
                                      <div className="icon-item__content">
                                        <p>{el.name}</p>
                                        <span>
                                          ${" "}
                                          {handleBoxPrice(
                                            el?.boxItems,
                                            el?.profitMargin
                                          )}{" "}
                                        </span>
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              ))}
                            {productBoxes.length === 0 && (
                              <div className="col-lg-12 col-sm-12 mb-30 no-product">
                                <h5>{t("boxDetails.unavailableTxt")}</h5>{" "}
                              </div>
                            )}
                          </div>
                        </div>
                        {/* content-block end */}
                      </div>
                    </div>
                    {/* tab-content end */}
                  </div>
                  {/* contest-description end  */}
                </div>
              </div>
            </div>
          </section>
        </div>
        <Draggable onStart={onStart} onStop={onStop}>
          <div className="cart-box">
            <div className="wow animate__animated animate__fadeInDownBig animate__delay-0.5s">
              <div className="cart">
                <img src={cart} alt="cart" />
                <span>{totalCartItems() || 0}</span>
              </div>
              <Button onClick={handleCart}>Go to Cart</Button>
            </div>
          </div>
        </Draggable>
      </Layout>
    </>
  );
};

export default Productdetails;
