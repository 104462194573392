import moment from "moment";

export const queryString = () => {
  let query = window.location.search.substr(1);
  let resultval = {};
  query.split("&").forEach((part) => {
    let item = part.split("=");
    resultval[item[0]] = decodeURIComponent(item[1]);
  });
  return resultval;
};

export const handleBoxPrice = (daata, boxProfit) => {
  // console.log('boxProfit123==>', daata)
  let sum = 0;
  // const profit = 1.5
  const profit = parseFloat(boxProfit);
  daata.forEach((el) => {
    // console.log('EL CHANCE12', el.chance)
    const prodPrice = el?.pid?.price;
    if (prodPrice && el.chance) {
      const finalProdPrice = el?.addonBlance
        ? parseFloat(prodPrice) + parseFloat(el?.addonBlance)
        : parseFloat(prodPrice);
      const res = finalProdPrice * (el?.chance / 100) * profit;
      const finalRes = res.toFixed(2);
      // console.log('finalRes',finalRes)
      sum += parseFloat(finalRes);
    }
  });
  const newSum = includeToFixedCheck(sum);
  return newSum;
};

export const getClientSeed = () => {
  const rand = () => Math.random(0).toString(36).substr(2);
  const token = (length) =>
    (rand() + rand() + rand() + rand()).substr(0, length);
  return token(32);
};

export const getShuffleArray = (array) => {
  let currentIndex = array.length,
    randomIndex;
  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }
  return array;
};

export const timeDifference = (current, previous) => {
  let msPerMinute = 60 * 1000;
  let msPerHour = msPerMinute * 60;
  let msPerDay = msPerHour * 24;
  let msPerMonth = msPerDay * 30;
  let msPerYear = msPerDay * 365;
  let elapsed = current - previous;
  if (elapsed < msPerMinute) {
    const res = Math.round(elapsed / 1000);
    return `${res} ${res > 1 ? "s" : "sec"} ago`;
  } else if (elapsed < msPerHour) {
    const res = Math.round(elapsed / msPerMinute);
    return `${res} ${res > 1 ? "mins" : "min"} ago`;
  } else if (elapsed < msPerDay) {
    const res = Math.round(elapsed / msPerHour);
    return `${res} ${res > 1 ? "hrs" : "hr"} ago`;
  } else if (elapsed < msPerMonth) {
    const res = Math.round(elapsed / msPerDay);
    return `${res} ${res > 1 ? "days" : "day"} ago`;
  } else if (elapsed < msPerYear) {
    const res = Math.round(elapsed / msPerMonth);
    return `${res} ${res > 1 ? "months" : "month"} ago`;
  } else {
    const res = Math.round(elapsed / msPerYear);
    return `${res} ${res > 1 ? "years" : "years"} ago`;
  }
};
export const includeToFixed = (price) => {
  let newPrice = 0;
  if (price) {
    let value = price.toString();
    newPrice = value.includes(".") ? parseFloat(value)?.toFixed(2) : value;
  }
  return newPrice;
};

const includeToFixedCheck = (price) => {
  let newPrice = "";
  if (price) {
    let value = price.toString();
    newPrice = value.includes(".") ? parseFloat(value)?.toFixed(2) : value;
  }
  return newPrice;
};

export const getCurrentOS = () => {
  let Name = "Unknown OS";
  if (navigator.userAgent.indexOf("Win") !== -1) Name = "Windows OS";
  if (navigator.userAgent.indexOf("Mac") !== -1) Name = "Macintosh";
  if (navigator.userAgent.indexOf("Linux") !== -1) Name = "Linux OS";
  if (navigator.userAgent.indexOf("Android") !== -1) Name = "Android OS";
  if (navigator.userAgent.indexOf("like Mac") !== -1) Name = "iOS";

  return Name;
};

export const getReffreeBonus = (commissionType, bonus, fixedAmount) => {
  if (commissionType && bonus && fixedAmount) {
    let value =
      commissionType === "percentage"
        ? (fixedAmount * Number(bonus)) / 100
        : Number(bonus);
    return value;
  } else {
    return "";
  }
};

export const chekUserDepositeStatus = (bonusRule, user, xDepositBonusCount) => {
  if (user?.referalBy && bonusRule) {
    return bonusRule === "firstDeposit" && user.totalDeposit > 0
      ? false
      : bonusRule === "xDeposit" && user.XDeposit > xDepositBonusCount
      ? false
      : true;
  } else {
    return false;
  }
};

export const checkCreateTime = (createDate, status) => {
  let isbot = false;
  if (status === "opened") {
    // Get the current date and time
    const currentDateTime = moment(createDate);
    // Add 1 second
    let newDateTime = currentDateTime.add(1, "seconds");
    if (new Date() > new Date(newDateTime.format())) {
      isbot = true;
    }
  }
  return isbot;
};
