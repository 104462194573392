import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
// import { Form} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
// import { userDepositSchema } from "../../utils/validation";
//import { requsetMony } from '../../reducers/userReducer';
// import barcode from '../../assets/images/home/barcode.jpg';
// import bitcoin from "../../assets/images/deposit-png/b.png";
// import litecoin from "../../assets/images/deposit-png/b.png";
// import etherium from "../../assets/images/deposit-png/b.png";
// import arrow from "../../assets/images/deposit-png/b.png";
//import { useEffect } from 'react';
// import CopyToClipboard from 'react-copy-to-clipboard';
//import Check from '../../assets/images/deposit-png/b.png';
//import { Spinner } from 'react-bootstrap';
import InfoPopup from "./infoPopup";
import { useTranslation } from "react-i18next";

import AssetsPopup from "./assetsPopup";
import {
  incrementWalletAmount,
  getLoginUser,
} from "../../reducers/userReducer";

const Deposit = () => {
  // const [selectedBlockchain, setSelectedBlockchain] = useState("BTC");
  const dispatch = useDispatch();
  const result = useSelector((state) => state.user);
  const { t } = useTranslation();
  const { userDetails } = result || {};
  const [show, setShow] = useState();
  const [deposit, setDeposit] = useState("");
  const [errors, setErrors] = useState("");

  const [Currencyshow, setCurrencyshow] = useState();

  const [selectedBlockchain, setSelectedBlockchain] = useState("BTC");
  //const [copyClips, setCopyClips] = useState(false);
  //const [loading, setLoading] = useState(true);

  // const userBlockChainDetail = useSelector(
  // 	(state) => state.user.cryptoWallet[selectedBlockchain.toLowerCase()]
  // );

  // const copyClip = () => {
  // 	setCopyClips(!copyClips);
  // 	setTimeout(function () {
  // 		setCopyClips(false);
  // 	}, 1500);
  // };

  const handleOpen = () => {
    setShow(!show);
  };
  //   const handleOpenCurrency = () => {
  //     setCurrencyshow(!Currencyshow);
  //   };

  const handleClose = () => {
    setCurrencyshow(false);
  };

  // useEffect(async () => {
  // 	const res = await dispatch(requsetMony({ blockchain: selectedBlockchain }));
  // 	//
  // 	if (res) {
  // 		setLoading(false);
  // 	}
  // }, [selectedBlockchain]);

  const selectCurrency = (currency) => {
    setSelectedBlockchain(currency);
    setCurrencyshow(false);
  };

  const handleInputChange = (e) => {
    setDeposit(e.target.value);
  };

  const handleDeposit = () => {
    if (!deposit) {
      setErrors("Please Enter Deposit Amount");
      return;
    }
    setErrors("");

    const payload = {
      amount: deposit,
      userId: userDetails?._id,
      // boxId:boxDetails?._id,
      type: "deposit",
      debitCreditType: "credit",
      paymentType: "Bank Account",
      description: "deposit",
      descriptionMessage: "Add Funds in the Wallet",
    };

    dispatch(incrementWalletAmount(payload));
    dispatch(getLoginUser());
  };

  //   const currencyImg = () => {
  //     if (selectedBlockchain === "BTC") {
  //       return bitcoin;
  //     } else if (selectedBlockchain === "LTC") {
  //       return litecoin;
  //     } else {
  //       return etherium;
  //     }
  //   };

  return (
    <>
      <div className="user-deposit">
        <div className="user-deposit-box">
          <div className="withdraw-list">
            <div className="d-flex justify-content-between mb-1">
              <span>{t("proTransactions.thTxt4")}</span>
              <span>
                {" "}
                {/* {!values.amount ||
                  (parseFloat(values.amount) > 0 && (
                    <>
                      ${(values.amount * userBlockChainDetail.price).toFixed(2)}
                    </>
                  ))} */}
                {}
              </span>
            </div>

            <div className="withdraw-field">
              <Form.Control
                type="number"
                value={deposit}
                name="deposit"
                placeholder={t("proTransactions.depoAmtTxt")}
                onChange={(e) => handleInputChange(e)}
                // onBlur={handleOnblur}
                // onKeyUp={handleKeyUp}
              />
              {errors && <span className="text-danger">{errors}</span>}
            </div>
            <div className="deposit-btn">
              <Button
                className="save-btn btn-style"
                type="submit"
                onClick={handleDeposit}
              >
                <span>{t("proTransactions.depoTxt")}</span>
              </Button>
            </div>
          </div>
          {/* <div className="bar-code-secion"> */}
          {/* <img src={barcode} alt="barcode" /> */}
          {/* <span className="scan">Or Scan</span> */}
          {/* <img
              src={`https://chart.googleapis.com/chart?chs=250x250&cht=qr&chl=${}`} */}
          {/* // alt="img" */}
          {/* // userBlockChainDetail?.warning?.tooltip?.title ?? */}
          {/* // selectedBlockchain */}
          {/* // /> */}
          {/* </div>
          <div> */}
          {/* <> */}
          {/* <div
                className="assets-section select-blockchain"
                onClick={handleOpenCurrency}
              >
                <div className="asstes-left">
                  <img src={currencyImg()} alt="" />
                  <div className="bitxoin-heading ">
                    <h6>Assets</h6>
                    <p>
                      {userBlockChainDetail?.warning?.tooltip?.title ??
														selectedBlockchain}
                    </p>
                  </div>
                </div>
                <img src={arrow} alt="arrow" />
              </div> */}
          {/* <div className="assets-section btc-address-section">
                <div className="asstes-left"> */}
          {/* <div className="bitxoin-heading">
                    <h6>
                      {userBlockChainDetail?.warning?.tooltip?.title ??
														selectedBlockchain}{' '}
                      Address
                    </h6>
                    <p>fff</p>
                  </div> */}
          {/* </div> */}
          {/* <CopyToClipboard text={userBlockChainDetail?.address}>
											{copyClips ? (
												<img className="tick" src={Check} />
											) : (
												<i onClick={copyClip} className="fa fa-copy"></i>
											)}
										</CopyToClipboard> */}
          {/* </div>
            </>
          </div> */}
        </div>

        {/* <div className="bitcoin-info">
          <p onClick={handleOpen}>
            <i className="fa fa-info-circle" aria-hidden="true"></i>
            <span>`Only send to this address`</span>{" "}
          </p>
        </div> */}

        <div className="bitcoin-balance">
          <p>
            $ {t("proTransactions.balTxt")} : &nbsp;{" "}
            {userDetails?.walletAmount?.toFixed(2)}
          </p>
          {/* <p>$ Price : $ 50</p> */}
        </div>
      </div>
      <AssetsPopup
        show={Currencyshow}
        handleClose={handleClose}
        selectCurrency={selectCurrency}
        selectedBlockchain={selectedBlockchain}
      />
      <InfoPopup
        show={show}
        handleClose={handleOpen}
        selectedBlockchain={selectedBlockchain}
        // subtitle={userBlockChainDetail?.warning?.tooltip?.subtitle ?? null}
      />
    </>
  );
};
export default Deposit;
