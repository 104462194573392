import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import close from "../../assets/images/game/close.png";

const AddressDetails = ({
  show,
  handleClose,
  shippingAddress,
  handleSelectAddress,
  selectedAddress,
}) => {
  const createAddress = (data) => {
    let address = "";
    if (data) {
      Object.keys(data).forEach((el) => {
        if (data[el]) {
          address = `${address ? `${address},` : address} ${data[el]}`;
        }
      });
    }
    return address;
  };
  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className={`login-popup order-details-popup address-details-popup`}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("model.addressTxt")}</Modal.Title>
        <div className="close-img">
          <img src={close} onClick={handleClose} alt="close" />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="adress-section">
          {shippingAddress?.map((el, index) => (
            <div
              className={`address-list ${
                el?.uid === selectedAddress?.uid ? "selected-address" : ""
              }`}
              onClick={() => handleSelectAddress(el, index)}
            >
              <h6>
                {t("proShipDetails.placeTxt4")} {index + 1}
              </h6>
              <div className="address-details">
                <i class="las la-home"></i>
                {createAddress(el)}
              </div>
              <i class="las la-check"></i>
            </div>
          ))}
          {/* <div className="address-list">
            <h6>Address 2</h6>
            <div className="address-details">
              <i class="las la-home"></i>
              make, test, fdg, New South Wales, Australia, teh
            </div>
            <i class="las la-check"></i>
          </div>
          <div className="address-list">
            <h6>Address 3</h6>
            <div className="address-details">
              <i class="las la-home"></i>
              make, test, fdg, New South Wales, Australia, teh
            </div>
            <i class="las la-check"></i>
          </div>
          <div className="address-list">
            <h6>Address 3</h6>
            <div className="address-details">
              <i class="las la-home"></i>
              make, test, fdg, New South Wales, Australia, teh
            </div>
            <i class="las la-check"></i>
          </div>
          <div className="address-list">
            <h6>Address 3</h6>
            <div className="address-details">
              <i class="las la-home"></i>
              make, test, fdg, New South Wales, Australia, teh
            </div>
            <i class="las la-check"></i>
          </div> */}
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default AddressDetails;
