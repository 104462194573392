import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import AssetsPopup from "./assetsPopup";
import { getLoginUser, withDrawAmount } from "../../reducers/userReducer";
// eslint-disable-next-line react/prop-types
const Withdraw = ({ inputRef }) => {
  const dispatch = useDispatch();
  const result = useSelector((state) => state.user);
  const { userDetails } = result || {};

  const { t } = useTranslation();
  const [withDraw, setWithDraw] = useState("");

  const [selectedBlockchainWallet, setSelectedBlockchainWallet] =
    useState("BTC");
  const [show, setShow] = useState();
  const [errors, setErrors] = useState("");

  useEffect(() => {
    // setValues((prev) => {
    //   return { ...prev, amount: '' };
    // });
  }, [selectedBlockchainWallet]);

  const handleInputChange = (e) => {
    const { value } = e.target;

    setWithDraw(value);
  };

  const handleWithDrawAmount = () => {
    if (!withDraw) {
      setErrors("Please Insert Withdraw Amount");
      return;
    }
    setErrors("");

    const payload = {
      amount: withDraw,
      userId: userDetails?._id,
      debitCreditType: "debit",
      paymentType: "wallet",
      type: "withdraw",
      description: "Withdraw",
      descriptionMessage: "Withdraw Amount from Wallet",
    };

    dispatch(withDrawAmount(payload));
    dispatch(getLoginUser());
  };

  const handleClose = () => {
    setShow(false);
  };

  const selectCurrency = (currency) => {
    setSelectedBlockchainWallet(currency);
    setShow(false);
  };

  return (
    <>
      <div className="user-withdraw-page">
        {/* <div className='withdraw-balance'>
          <div className='withdraw-amount'>
            <input
              ref={inputRef}
              type='Number'
              placeholder='0'
              name='amount'
              maxLength={7}
              value={values.amount}
              onChange={(e) => handleInputChange(e)}
              onBlur={handleOnblur}
              onKeyUp={handleKeyUp}
            />

            {selectedBlockchainWallet}
            <p>
              {errors && errors.amount && (
                <span className='text-danger'>{errors.amount}</span>
              )}
            </p>
          </div>
          <p>
          </p>
        </div> */}
        <div className="withdraw-details">
          <div className="withdraw-list">
            <div className="d-flex justify-content-between mb-1">
              <span>{t("proTransactions.withdTxt")} : </span>
              <span>
                {" "}
                {/* {!values.amount ||
                  (parseFloat(values.amount) > 0 && (
                    <>
                      ${(values.amount * userBlockChainDetail.price).toFixed(2)}
                    </>
                  ))} */}
                {}
              </span>
            </div>

            <div className="withdraw-field">
              <Form.Control
                type="number"
                value={withDraw}
                name="withDraw"
                placeholder={t("proTransactions.withdAmtTxt")}
                onChange={(e) => handleInputChange(e)}
                // onBlur={handleOnblur}
                // onKeyUp={handleKeyUp}
              />
              {errors && <span className="text-danger">{errors}</span>}
            </div>
          </div>

          {/* <div className='withdraw-list pay-with'>
            <p> Pay with</p>
            <div
              className='withdraw-field select-blockchain'
              onClick={handleOpen}>
              <div className=''>
                <img src={currencyImg()} alt='arrow' />{' '}
                {selectedBlockchainWallet}
              </div>
              <img src={arrow} alt='arrow' />
            </div>
          </div> */}
        </div>
        <div className="deposit-btn">
          <Button
            className="save-btn btn-style"
            type="submit"
            onClick={handleWithDrawAmount}
          >
            <span>{t("proTransactions.continueTxt")}</span>
          </Button>
        </div>
        <div className="bitcoin-balance">
          <p>
            {t("proTransactions.balTxt")} : &nbsp;{" "}
            {userDetails?.walletAmount?.toFixed(2)}
          </p>
          {/* <p>
            30 */}
          {/* {selectedBlockchainWallet} : ${userBlockChainDetail.price} */}
          {/* </p> */}
        </div>
      </div>
      <AssetsPopup
        show={show}
        handleClose={handleClose}
        selectCurrency={selectCurrency}
        selectedBlockchain={selectedBlockchainWallet}
      />
    </>
  );
};
export default Withdraw;
